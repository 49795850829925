<template>
  <div ref="mapControls" class="absolute bottom-5 right-5">
    <div
      class="flex flex-col bg-white toggleBarBottomRight standard-elevation-2"
    >
      <!-- zoom in-->
      <ButtonEl
        icon="add"
        color="color2"
        data-test="zoom-in"
        class="rounded-b-none h-10 rounded-[8px]"
        @click="zoom"
      />
      <!-- zoom out-->
      <ButtonEl
        icon="remove"
        color="color2"
        class="rounded-none h-10"
        @click="unzoom"
      />

      <!-- show only when click on control option icon-->
      <div class="extended-controls" :class="{ visible: showExtendedControls }">
        <ButtonEl
          :text="pitchText"
          color="color2"
          class="rounded-none h-10 w-10 p-0"
          @click="pitch"
        />
        <!-- compass to north-->
        <ButtonEl
          icon="explore"
          class="rounded-none h-10"
          color="color2"
          @click="compass"
        />
        <ButtonEl
          icon="satellite_alt"
          class="rounded-none h-10"
          :color="satelliteIsActive ? 'color1' : 'color2'"
          @click="toggleSatellite"
        />
      </div>
      <ButtonEl
        class="rounded-t-none standard-elevation-2 h-10 rounded-[8px]"
        :color="showExtendedControls ? 'color1' : 'color2'"
        icon="map"
        @click="showExtendedControls = !showExtendedControls"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { compassMap, setPitch, unzoomMap, zoomMap } from '@/mapbox/main';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { useElementSize } from '@vueuse/core';

const props = defineProps({
  mapStore: {
    type: Object,
    required: true,
  },
});

const mapControls = ref(null);
const { height } = useElementSize(mapControls);

watch(height, (newHeight) => {
  props.mapStore.setMapControlsHeight(newHeight);
});

const pitchText = ref('3D');
const showExtendedControls = ref(false);

const satelliteIsActive = computed(() => props.mapStore.satelliteIsActive);

function pitch() {
  pitchText.value = setPitch(pitchText.value);
}

function zoom() {
  zoomMap();
}

function unzoom() {
  unzoomMap();
}

function compass() {
  compassMap();
}

function toggleSatellite() {
  props.mapStore.toggleSatellite();
}
</script>

<style lang="scss" scoped>
.toggleBarBottomRight {
  width: 40px;
  background: #ffffff;
  border-radius: 8px;
}

.extended-controls {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-out;
}

.visible {
  max-height: 200px;
}
</style>
