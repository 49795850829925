import {
  LAYER_KEY__PV_PROJECT,
  LAYER_KEY__PV_PROJECT_CONNECTION,
  LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
  LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
  LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
} from '@/apps/usecase-2/map/layer-config/potential-analysis';
import {
  LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION,
  LAYER_KEY__CLUSTER_PV_PROJECT,
  LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT,
  LAYER_KEY__CLUSTER_EXTERNAL_PROJECT,
} from '@/apps/usecase-2/map/layer-config/cluster';
import { getMap } from '@/mapbox/main';
import axios from '@/utils/axiosHelper';

const pvProjectLayers = [
  LAYER_KEY__PV_PROJECT_CONNECTION,
  LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
  LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
  LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
  LAYER_KEY__PV_PROJECT,
];

const clusterLayers = [
  LAYER_KEY__CLUSTER_PV_PROJECT,
  LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT,
  LAYER_KEY__CLUSTER_EXTERNAL_PROJECT,
  LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION,
];

export const highlightProjectConfig = {
  [LAYER_KEY__PV_PROJECT]: {
    paintProperty: ['fill-opacity'],
    property: 'id',
    opacity: 0.2,
  },
  [LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT]: {
    paintProperty: ['circle-opacity', 'circle-stroke-opacity'],
    property: 'project_id',
    opacity: 0.05,
  },
  [LAYER_KEY__PV_PROJECT_SUBSTATION_POINT]: {
    paintProperty: ['circle-opacity', 'circle-stroke-opacity'],
    property: 'project_id',
    opacity: 0.05,
  },
  [LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT]: {
    paintProperty: ['circle-opacity', 'circle-stroke-opacity'],
    property: 'project_id',
    opacity: 0.05,
  },
  [LAYER_KEY__PV_PROJECT_CONNECTION]: {
    paintProperty: ['line-opacity'],
    property: 'project_id',
    opacity: 0.1,
  },
};

export function highlightFeaturesProject(projectId) {
  for (const [key, value] of Object.entries(highlightProjectConfig)) {
    value.paintProperty.forEach((p) => {
      getMap().setPaintProperty(key, p, [
        'case',
        ['==', ['get', value.property], projectId],
        1,
        value.opacity,
      ]);
    });
  }
}

export function highlightPvProject(feature) {
  if (feature && feature.source === LAYER_KEY__PV_PROJECT) {
    highlightFeaturesProject(feature.properties.id);
  } else {
    for (const [key, value] of Object.entries(highlightProjectConfig)) {
      value.paintProperty.forEach((p) => {
        getMap().setPaintProperty(key, p, 1);
      });
    }
  }
}

export const highlightClusterConfig = {
  [LAYER_KEY__CLUSTER_PV_PROJECT]: {
    paintProperty: ['fill-opacity'],
    property: 'cluster_id',
    opacity: 0.2,
  },
  [LAYER_KEY__CLUSTER_PV_PROJECT_HV_CONNECTION_POINT]: {
    paintProperty: ['circle-opacity', 'circle-stroke-opacity'],
    property: 'cluster_id',
    opacity: 0.05,
  },
  [LAYER_KEY__CLUSTER_EXTERNAL_PROJECT]: {
    paintProperty: ['circle-opacity', 'circle-stroke-opacity'],
    property: 'cluster_id',
    opacity: 0.05,
  },
  [LAYER_KEY__CLUSTER_PV_PROJECT_CONNECTION]: {
    paintProperty: ['line-opacity'],
    property: 'cluster_id',
    opacity: 0.1,
  },
};

export function highlightFeaturesCluster(clusterId) {
  for (const [key, value] of Object.entries(highlightClusterConfig)) {
    value.paintProperty.forEach((p) => {
      getMap().setPaintProperty(key, p, [
        'case',
        ['==', ['get', value.property], clusterId],
        1,
        value.opacity,
      ]);
    });
  }
}

export function highlightCluster(feature) {
  if (feature && feature.source === LAYER_KEY__CLUSTER_PV_PROJECT) {
    highlightFeaturesCluster(feature.properties.cluster_id);
  } else {
    for (const [key, value] of Object.entries(highlightClusterConfig)) {
      value.paintProperty.forEach((p) => {
        getMap().setPaintProperty(key, p, 1);
      });
    }
  }
}

export function highlightProject(feature) {
  highlightPvProject(feature);
  highlightCluster(feature);
}

export function removeProjectFilterOnMap(mapStore) {
  mapStore.removeFilter(pvProjectLayers, 'projectFilter');
}

export async function applyProjectFilterOnMap(filterOptions, mapStore) {
  const response = await axios({
    url: `/api/usecase-2/pv-project/`,
    method: 'GET',
    params: {
      ...filterOptions,
      no_pagination: true,
    },
  });
  const ids = response.data.results.map((e) => e.id);
  mapStore.addFilter(
    [
      LAYER_KEY__PV_PROJECT_CONNECTION,
      LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
      LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
      LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
    ],
    ['in', ['get', 'project_id'], ['literal', ids]],
    'projectFilter',
  );
  mapStore.addFilter(
    [LAYER_KEY__PV_PROJECT],
    ['in', ['get', 'id'], ['literal', ids]],
    'projectFilter',
  );
}

export function removeClusterFilterOnMap(mapStore) {
  mapStore.removeFilter(clusterLayers, 'clusterFilter');
}

export async function applyClusterFilterOnMap(filterOptions, mapStore) {
  const response = await axios({
    url: `/api/usecase-2/cluster/`,
    method: 'GET',
    params: {
      ...filterOptions,
      no_pagination: true,
    },
  });
  const ids = response.data.results.map((e) => e.id);
  mapStore.addFilter(
    clusterLayers,
    ['in', ['get', 'cluster_id'], ['literal', ids]],
    'clusterFilter',
  );
}

export function showClusterLayers(mapStore) {
  mapStore.changeLayerVisibility({
    onLayers: clusterLayers,
    unselect: pvProjectLayers,
    active: true,
  });
}
