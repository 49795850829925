import LoginPage from '@/apps/auth/LoginPage.vue';
import { useUsers } from '@/apps/auth/users-store';
import LandingPage from '@/apps/landing-page/LandingPage.vue';
import AssumptionsGeneralPage from '@/apps/usecase-1/assumptions-general/AssumptionsGeneralPage.vue';
import AssumptionsScenarioPage from '@/apps/usecase-1/assumptions-scenario/AssumptionsScenarioPage.vue';
import MapView1 from '@/apps/usecase-1/map/MapView1.vue';
import ProjectSelectDialog from '@/apps/usecase-1/map/ProjectSelectDialog.vue';
import ProjectListWrapper from '@/apps/usecase-1/projects/ProjectListWrapper.vue';
import ProjectCreateWrapper from '@/apps/usecase-1/projects/project-create/ProjectCreateWrapper.vue';
import AreaEvaluation from '@/apps/usecase-1/projects/project-result-detail/AreaEvaluation.vue';
import ProjectDetailsWrapper from '@/apps/usecase-1/projects/project-result-detail/ProjectDetailsWrapper.vue';
import ProjectOverview from '@/apps/usecase-1/projects/project-result-detail/ProjectOverview.vue';
import ProjectRenewableGeneration from '@/apps/usecase-1/projects/project-result-detail/ProjectRenewableGeneration.vue';
import ProjectReports from '@/apps/usecase-1/projects/project-result-detail/ProjectReports.vue';
import ScenarioComparison from '@/apps/usecase-1/projects/project-result-detail/ScenarioComparison.vue';
import ScenarioDemand from '@/apps/usecase-1/projects/project-result-detail/ScenarioDemand.vue';
import ScenarioGridPlanning from '@/apps/usecase-1/projects/project-result-detail/ScenarioGridPlanning.vue';
import ScenarioPowerBalance from '@/apps/usecase-1/projects/project-result-detail/ScenarioPowerBalance.vue';
import UseCase1StartPage from '@/apps/usecase-1/start-page/UseCase1StartPage.vue';
import MapView2 from '@/apps/usecase-2/map/MapView2.vue';
import PotentialAnalysisPage from '@/apps/usecase-2/potential-analysis/PotentialAnalysisPage.vue';
import SettingsPage from '@/apps/usecase-2/settings/SettingsPage.vue';
import UseCase2StartPage from '@/apps/usecase-2/start-page/UseCase2StartPage.vue';
import UseCase4StartPage from '@/apps/usecase-4/start-page/UseCase4StartPage.vue';
import { usePageTransitionStore } from '@/composables/usePageTransitionStore';
import { createRouter, createWebHistory } from 'vue-router';
import { useBaseStore } from '@/apps/basemodule/base-store';
import ResultOverviewPageWrapper from '@/apps/usecase-2/result-evaluation/ResultOverviewPageWrapper.vue';
import SalesRegionsPage from '@/apps/usecase-4/sales-regions/SalesRegionsPage.vue';
import CreateSalesRegionsPage from '@/apps/usecase-4/sales-regions/CreateSalesRegionsPage.vue';
import AssumptionsPage from '@/apps/usecase-4/assumptions/AssumptionsPage.vue';
import EvaluationWrapper from '@/apps/usecase-4/evaluation/EvaluationWrapper.vue';
import EvaluationOverviewTab from '@/apps/usecase-4/evaluation/EvaluationOverviewTab.vue';
import EvaluationExportTab from '@/apps/usecase-4/evaluation/EvaluationExportTab.vue';
import EvaluationFilterTab from '@/apps/usecase-4/evaluation/EvaluationFilterTab.vue';
import MapView4 from '@/apps/usecase-4/map/MapView4.vue';
import SalesRegionsSelectDialog from '@/apps/usecase-4/map/SalesRegionSelectDialog.vue';

const ProjectDetailsWrapper2 = () =>
  import(
    '@/apps/usecase-2/result-evaluation/project-detail-page/ProjectDetailsWrapper.vue'
  );
const ClusterDetailsWrapper2 = () =>
  import(
    '@/apps/usecase-2/result-evaluation/project-detail-page/ClusterDetailsWrapper.vue'
  );
const ClusterOverview2 = () =>
  import(
    '@/apps/usecase-2/result-evaluation/project-detail-page/ClusterOverview.vue'
  );
const ProjectOverview2 = () =>
  import(
    '@/apps/usecase-2/result-evaluation/project-detail-page/ProjectOverview.vue'
  );
const ProjectResult2 = () =>
  import(
    '@/apps/usecase-2/result-evaluation/project-detail-page/ProjectResult.vue'
  );
const ProjectWeighing2 = () =>
  import(
    '@/apps/usecase-2/result-evaluation/project-detail-page/ProjectWeighing.vue'
  );
const ProjectExport2 = () =>
  import(
    '@/apps/usecase-2/result-evaluation/project-detail-page/ProjectExport.vue'
  );

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
      meta: {
        layout: 'Auth',
        title: 'Login',
        transitionName: 'fade',
      },
    },
    {
      path: '/uc1',
      name: 'UseCase1',
      component: UseCase1StartPage,
      meta: {
        requiresAuth: true,
        requiresPerm: {
          app: 'usecase_1',
          permission: 'view_usecase_1',
        },
        title: 'Energiewendepartner',
      },
      children: [
        {
          path: 'projects',
          name: 'UseCase1ProjectPage',
          meta: {
            requiresAuth: true,
            title: 'Projekte',
          },
          children: [
            {
              path: '',
              name: 'UseCase1ProjectList',
              component: ProjectListWrapper,
              props: true,
              meta: {
                requiresAuth: true,
                transitionName: 'fade',
              },
            },
            {
              path: 'create',
              name: 'UseCase1ProjectCreate',
              component: ProjectCreateWrapper,
              meta: {
                requiresAuth: true,
                title: 'Projekt Erstellen',
                transitionName: 'fade',
                requiresPerm: {
                  app: 'usecase_1',
                  permission: 'add_project',
                },
              },
            },
            {
              path: 'project-result-detail-:projectId',
              name: 'UseCase1ProjectResultDetailPage',
              component: ProjectDetailsWrapper,
              props: true,
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: 'overview-:scenarioId',
                  name: 'UseCase1ProjectResultOverview',
                  component: ProjectOverview,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Übersicht Projektgebiet',
                  },
                },
                {
                  path: 'demand-generation-:scenarioId',
                  name: 'UseCase1ProjectScenarioDemand',
                  component: ScenarioDemand,
                  props: true,
                  meta: {
                    title:
                      'Projekt Auswertung: Bestandsanalyse und Nachfrageentwicklung',
                  },
                },
                {
                  path: 'renewable-generation-:scenarioId',
                  name: 'UseCase1ProjectProjectRenewableGeneration',
                  component: ProjectRenewableGeneration,
                  props: true,
                  meta: {
                    title: 'EE-Erzeugung',
                  },
                },
                {
                  path: 'power-balance-:scenarioId',
                  name: 'UseCase1ProjectScenarioPowerBalance',
                  component: ScenarioPowerBalance,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Mengen- und Leistungsbilanz',
                  },
                },
                {
                  path: 'grid-planning-:scenarioId',
                  name: 'UseCase1ProjectScenarioGridPlanning',
                  component: ScenarioGridPlanning,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Netzplanung',
                  },
                },
                {
                  path: 'area-evaluation-:scenarioId',
                  name: 'UseCase1AreaEvaluation',
                  component: AreaEvaluation,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Gebietsauswertung',
                  },
                },
                {
                  path: 'scenario-comparison-:scenarioId',
                  name: 'UseCase1ScenarioComparison',
                  component: ScenarioComparison,
                  props: true,
                  meta: {
                    title: 'Projekt Auswertung: Szenarienvergleich',
                  },
                },
                {
                  path: 'project-reports-:scenarioId',
                  name: 'UseCase1ProjectReports',
                  component: ProjectReports,
                  props: true,
                  meta: {
                    requiresPerm: {
                      app: 'usecase_1',
                      permission: 'export_data_usecase_1',
                    },
                    title: 'Projekt Auswertung: Berichte',
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'assumptions-scenario',
          name: 'AssumptionsScenario',
          component: AssumptionsScenarioPage,
          meta: {
            requiresAuth: true,
            title: 'Nachfrage modellieren',
            transitionName: 'fade',
          },
        },
        {
          path: 'assumptions-general',
          name: 'AssumptionsGeneral',
          component: AssumptionsGeneralPage,
          meta: {
            requiresAuth: true,
            title: 'Allgemeine Annahmen',
            transitionName: 'fade',
          },
        },
        {
          path: 'map',
          name: 'mapProjectSelection',
          component: ProjectSelectDialog,
          meta: {
            requiresAuth: true,
            layout: 'Map',
            hide_footer: 1,
            title: 'Kartenprojekt auswählen',
            transitionName: 'fade',
          },
        },
        {
          path: 'map/:projectId/:scenarioId',
          name: 'mapView1',
          component: MapView1,
          meta: {
            requiresAuth: true,
            layout: 'Map',
            hide_footer: 1,
            title: 'Kartenansicht',
            transitionName: 'fade',
          },
        },
      ],
    },
    {
      path: '/uc2',
      name: 'UseCase2',
      component: UseCase2StartPage,
      meta: {
        requiresAuth: true,
        title: 'Optimierte Geoallokation',
        requiresPerm: {
          app: 'usecase_2',
          permission: 'view_usecase_2',
        },
      },
      children: [
        {
          path: 'potential-analysis',
          name: 'UseCase2PotentialPage',
          component: PotentialAnalysisPage,
          meta: {
            requiresAuth: true,
            title: 'Potenzialanalyse',
            transitionName: 'fade',
          },
        },
        {
          path: 'result-evaluation',
          name: 'UseCase2ResultsPage',
          component: ResultOverviewPageWrapper,
          meta: {
            requiresAuth: true,
            title: 'Auswertung',
            transitionName: 'fade',
          },
        },
        {
          path: 'project-result-detail-:projectId',
          name: 'UseCase2ProjectResultDetailPage',
          component: ProjectDetailsWrapper2,
          props: true,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: 'overview',
              name: 'UseCase2ProjectResultOverview',
              component: ProjectOverview2,
              props: true,
              meta: {
                title: 'Projekt Auswertung: Übersicht Fläche',
              },
            },
            {
              path: 'result',
              name: 'UseCase2ProjectResult',
              component: ProjectResult2,
              props: true,
              meta: {
                title: 'Projekt Auswertung: Ergebnisse',
              },
            },
            {
              path: 'weighting',
              name: 'UseCase2ProjectResultWeighting',
              component: ProjectWeighing2,
              props: true,
              meta: {
                title: 'Projekt Auswertung: Werthaltigkeit',
              },
            },
            {
              path: 'export',
              name: 'UseCase2ProjectResultExport',
              component: ProjectExport2,
              props: true,
              meta: {
                requiresPerm: {
                  app: 'usecase_2',
                  permission: 'export_data_usecase_2',
                },
                title: 'Projekt Auswertung: Exporte',
              },
            },
          ],
        },
        {
          path: 'cluster-result-detail-:clusterId',
          name: 'UseCase2ClusterResultDetailPage',
          component: ClusterDetailsWrapper2,
          props: true,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: 'cluster',
              name: 'UseCase2ClusterOverview',
              component: ClusterOverview2,
              props: true,
              meta: {
                title: 'Cluster Auswertung: Übersicht',
              },
            },
            {
              path: 'overview',
              name: 'UseCase2ClusterResultOverview',
              component: ProjectOverview2,
              props: true,
              meta: {
                title: 'Cluster Auswertung: Übersicht Fläche',
              },
            },
            {
              path: 'result',
              name: 'UseCase2ClusterResult',
              component: ProjectResult2,
              props: true,
              meta: {
                title: 'Cluster Auswertung: Ergebnisse',
              },
            },
            {
              path: 'weighting',
              name: 'UseCase2ClusterResultWeighting',
              component: ProjectWeighing2,
              props: true,
              meta: {
                title: 'Cluster Auswertung: Werthaltigkeit',
              },
            },
            {
              path: 'export',
              name: 'UseCase2ClusterResultExport',
              component: ProjectExport2,
              props: true,
              meta: {
                requiresPerm: {
                  app: 'usecase_2',
                  permission: 'export_data_usecase_2',
                },
                title: 'Cluster Auswertung: Exporte',
              },
            },
          ],
        },
        {
          path: 'settings',
          name: 'UseCase2SettingsPage',
          component: SettingsPage,
          meta: {
            requiresAuth: true,
            title: 'Einstellungen',
            transitionName: 'fade',
          },
        },
        {
          path: 'map/:analysisId?',
          name: 'mapView2',
          component: MapView2,
          meta: {
            requiresAuth: true,
            layout: 'Map',
            hide_footer: 1,
            title: 'Kartenansicht',
            transitionName: 'fade',
          },
        },
      ],
    },
    {
      path: '/uc4',
      name: 'UseCase4',
      component: UseCase4StartPage,
      meta: {
        requiresAuth: true,
        title: 'Waermepumpen',
        requiresPerm: {
          app: 'usecase_4',
          permission: 'view_usecase_4',
        },
      },
      children: [
        {
          path: 'sales-regions',
          name: 'SalesRegions',
          props: true,
          meta: {
            title: 'Vertriebsgebiete',
            transitionName: 'fade',
          },
          children: [
            {
              path: '',
              name: 'SalesRegionsList',
              component: SalesRegionsPage,
              props: true,
              meta: {
                transitionName: 'fade',
              },
            },
            {
              path: 'create',
              name: 'CreateSalesRegions',
              component: CreateSalesRegionsPage,
              meta: {
                title: 'Vertriebsgebiet erstellen',
                transitionName: 'fade',
                requiresPerm: {
                  app: 'usecase_4',
                  permission: 'add_salesregion',
                },
              },
            },
          ],
        },
        {
          path: 'assumptions',
          name: 'Assumptions',
          component: AssumptionsPage,
          meta: {
            title: 'Allgemeine Annahmen',
            transitionName: 'fade',
          },
        },
        {
          path: 'evaluation-:salesRegionId',
          name: 'Usecase4SalesRegionEvaluation',
          component: EvaluationWrapper,
          props: true,
          meta: {
            title: 'Auswertung',
            transitionName: 'fade',
          },
          children: [
            {
              path: 'overview',
              name: 'Usecase4SalesRegionEvaluationOverview',
              component: EvaluationOverviewTab,
              meta: {
                title: 'Übersicht',
                transitionName: 'fade',
              },
            },
            {
              path: 'filter',
              name: 'Usecase4SalesRegionEvaluationFilter',
              component: EvaluationFilterTab,
              meta: {
                title: 'Filter',
                transitionName: 'fade',
              },
            },
            {
              path: 'export',
              name: 'Usecase4SalesRegionEvaluationExport',
              component: EvaluationExportTab,
              meta: {
                title: 'Export',
                transitionName: 'fade',
              },
            },
          ],
        },
        {
          path: 'map',
          name: 'mapSalesRegionSelection',
          component: SalesRegionsSelectDialog,
          meta: {
            requiresAuth: true,
            layout: 'Map',
            hide_footer: 1,
            title: 'Vertriebsgebiet auswählen',
            transitionName: 'fade',
          },
        },
        {
          path: 'map/:salesRegionId/:leadListId?',
          name: 'mapView4',
          component: MapView4,
          meta: {
            requiresAuth: true,
            layout: 'Map',
            hide_footer: 1,
            title: 'Kartenansicht',
            transitionName: 'fade',
          },
        },
      ],
    },
    {
      path: '/forgot-password',
      name: 'ForgotPage',
      component: () => import('@/apps/auth/ForgotPage.vue'),
      meta: {
        layout: 'Auth',
        title: 'Passwort vergessen',
        transitionName: 'fade',
      },
    },
    {
      path: '/reset-password',
      name: 'ResetPage',
      component: () => import('@/apps/auth/ResetPage.vue'),
      meta: {
        layout: 'Auth',
        title: 'Passwort zurücksetzen',
        transitionName: 'fade',
      },
    },
    {
      path: '/',
      name: 'landingURL',
      component: LandingPage,
      meta: {
        requiresAuth: true,
        title: 'Startseite',
      },
    },
    {
      path: '/registration/:token',
      name: 'dataMask',
      component: () => import('@/apps/auth/UserRegistration.vue'),
      meta: {
        layout: 'Auth',
        title: 'Registrierung',
        transitionName: 'fade',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/apps/profile/ProfilePage.vue'),
      meta: {
        requiresAuth: true,
        title: 'Mein Profil',
        transitionName: 'fade',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      component: () => import('@/apps/NotFoundPage.vue'),
      meta: {
        hide_footer: 1,
        layout: 'Plain',
        title: 'Seite nicht gefunden',
        transitionName: 'fade',
      },
    },
  ],
});
/**
 * Before each route updated
 */

router.beforeEach(async (to, from, next) => {
  const userStore = useUsers();
  const transitionStore = usePageTransitionStore();

  // Set page transition
  transitionStore.setTransitionName(to, from);

  // Fetch client hash
  await userStore.getClientHash();

  // Check authentication requirement
  if (to.meta.requiresAuth) {
    // Fetch user profile
    try {
      await userStore.getProfile();
    } catch {
      // Redirect to log in if an error occurs during profile fetching
      return next({ name: 'Login', query: { from: to.fullPath } });
    }

    // If the user is authenticated
    if (userStore.profile && userStore.profile.id) {
      // lazy fetch municipal data if empty
      useBaseStore().fetchSimplifiedMuniData();

      // Example forbidden logic (currently always false)
      let forbidden = false;
      if (
        to.meta.requiresPerm &&
        !userStore.hasPermission(
          to.meta.requiresPerm.app,
          to.meta.requiresPerm.permission,
        )
      ) {
        forbidden = true;
      }
      if (!forbidden) {
        // save last name to enable renavigation to last url
        if (from.name) to.meta.lastVisitedRouteName = from.name;
        return next();
      }
      return next({ name: 'landingURL' });
    }

    // Redirect to log in if not authenticated
    return next({ name: 'Login', query: { from: to.fullPath } });
  }

  // If no authentication is required, proceed
  next();
});

export default router;
