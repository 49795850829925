import MapboxDraw from '@mapbox/mapbox-gl-draw';
const DrawPolygon = MapboxDraw.modes.draw_polygon;
import area from '@turf/area';

const measureArea = Object.assign({}, DrawPolygon);

measureArea.onSetup = function (opts) {
  const onSetup = DrawPolygon.onSetup.bind(this);
  const state = onSetup();
  state.drawCreateCallback = opts.drawCreateCallback;
  return state;
};

measureArea.clickAnywhere = function (state, e) {
  const clickAnywhere = DrawPolygon.clickAnywhere.bind(this, state, e);
  clickAnywhere();
  const areaSquareMeters = area(state.polygon.toGeoJSON());
  if (typeof state.drawCreateCallback === 'function') {
    state.drawCreateCallback(areaSquareMeters);
  }
};

export default measureArea;
