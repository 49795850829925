<template>
  <ToolTip open-on-click clean-content prevent-close-on-hover>
    <IconWrapper
      :size="32"
      type="round"
      fill="text-core-color1"
      hover="hover:text-button-primary-hover-color1"
      icon="file_download"
      class="cursor-pointer"
      @click="openDownloadPanel(leadList)"
    />
    <template #text>
      <div class="rounded-[4px] standard-elevation-0-dark bg-white">
        <div
          v-for="option in initializeDownloadFormats(leadList.id)"
          :key="option.label"
          class="body-2 text-core-dark p-2.5 cursor-pointer hover:bg-active-area whitespace-nowrap flex justify-between items-center gap-2.5"
          :class="{
            'opacity-50 pointer-events-none':
              option.isLoading ||
              (option.value !== 'pptx' &&
                initializeDownloadFormats(leadList.id).some(
                  (f) => f.isLoading && f.value !== option.value,
                )),
          }"
          @click="handleItemClick(leadList, option)"
        >
          <div class="mt-0.5">
            {{ option.label }}
          </div>
          <div class="flex items-center">
            <div
              v-if="option.isLoading"
              class="flex items-center justify-center w-6 h-6"
            >
              <VProgressCircular
                indeterminate
                color="color1"
                size="20"
                :width="3"
              />
            </div>
            <IconWrapper
              v-else-if="option.isDownloadReady"
              icon="download_done"
              class="cursor-pointer"
            />
            <IconWrapper v-else icon="download" />
          </div>
        </div>
      </div>
    </template>
  </ToolTip>
</template>

<script setup lang="ts">
import { ref, onUnmounted, onMounted, computed } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import axios from '@/utils/axiosHelper';
import { useToastStore } from '@/stores/toast-store';

interface LeadList {
  id: number;
  title: string;
  sales_region: number;
  downloadable_file?: {
    state: string;
    file: string;
  };
}

interface DownloadFormat {
  label: string;
  value: string;
  alternateFileEndings: string[];
  isLoading: boolean;
  isDownloadReady: boolean;
  downloadUrl: string | null;
}

const props = defineProps<{
  leadList: LeadList;
}>();

const { showToast } = useToastStore();
let pollInterval: number | undefined;
const downloadFormatsMap = ref<Map<number, DownloadFormat[]>>(new Map());

const leadListFileReadyFormat = computed<string | undefined>(() =>
  props.leadList.downloadable_file?.file?.split('.').pop()?.toLowerCase(),
);

/**
 * Initializes or returns the download format options for a specific leadlist
 * @returns Array of download format options with their states
 */
function initializeDownloadFormats(leadListId: number): DownloadFormat[] {
  if (!downloadFormatsMap.value.has(leadListId)) {
    downloadFormatsMap.value.set(leadListId, [
      {
        label: 'CSV',
        value: 'csv',
        alternateFileEndings: [],
        isLoading: false,
        isDownloadReady: false,
        downloadUrl: null,
      },
      {
        label: 'GeoJSON (Projektion EPSG:4326)',
        value: 'geojson',
        alternateFileEndings: [],
        isLoading: false,
        isDownloadReady: false,
        downloadUrl: null,
      },
      {
        label: 'ESRI Shapefile (Projektion EPSG:4326)',
        value: 'shp',
        alternateFileEndings: ['zip'],
        isLoading: false,
        isDownloadReady: false,
        downloadUrl: null,
      },
      {
        label: 'Powerpoint',
        value: 'pptx',
        alternateFileEndings: [],
        isLoading: false,
        isDownloadReady: false,
        downloadUrl: null,
      },
    ]);
  }
  return downloadFormatsMap.value.get(leadListId) || [];
}

/**
 * Downloads a file that is already prepared (state: READY)
 * @param url - The URL of the file to download
 * @param option - The download format option being processed
 */
function downloadReadyFile(url: string, option: DownloadFormat): void {
  option.isLoading = true;

  axios({
    method: 'GET',
    url,
    responseType: 'blob',
  })
    .then((res) => {
      const urlFilename = url.split('/').pop();
      const regExpFilename = /filename\*=utf-8''(?<filename>.*)/;
      const filename = decodeURIComponent(
        regExpFilename.exec(res.headers['content-disposition'])?.groups
          ?.filename ??
          urlFilename ??
          '',
      );

      const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((e) => {
      console.error('Download failed:', e);
      showToast({
        color: 'error',
        message: 'Download fehlgeschlagen',
      });
    })
    .finally(() => {
      option.isLoading = false;
    });
}

/**
 * Initiates a new download or handles direct PPTX download
 * @param format - The requested format (csv, geojson, shp, pptx)
 */
async function downloadReport(
  leadList: LeadList,
  format: string,
): Promise<void> {
  const formats = initializeDownloadFormats(leadList.id);
  const formatObj = formats.find((f) => f.value === format);
  if (!formatObj) return;

  formatObj.isLoading = true;

  if (format === 'pptx') {
    showToast({
      color: 'info',
      message: 'PowerPoint wird generiert...',
    });

    try {
      const res = await axios({
        method: 'GET',
        url: `/api/ppt-gen/heat_pump_sales/${leadList.id}/`,
        responseType: 'blob',
      });

      const regExpFilename = /filename\*=utf-8''(?<filename>.*)/;
      const originalFilename = decodeURIComponent(
        regExpFilename.exec(res.headers['content-disposition'])?.groups
          ?.filename ?? 'Report.pptx',
      );
      const filename = `${leadList.title}_${originalFilename}`;
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      showToast({
        color: 'success',
        message: 'PowerPoint wurde erfolgreich generiert',
      });
    } catch (e) {
      console.error('Download failed:', e);
      showToast({
        color: 'error',
        message: 'PowerPoint konnte nicht generiert werden',
      });
    } finally {
      formatObj.isLoading = false;
    }
  } else {
    formats.forEach((f) => {
      if (f.value !== format) f.isDownloadReady = false;
    });

    try {
      await axios({
        method: 'GET',
        url: `/api/usecase-4/export/create-download-file/${leadList.id}/${format}/`,
      });

      startPollingForLeadList(leadList, format);
    } catch (error) {
      console.error('Error initiating download:', error);
      formatObj.isLoading = false;
    }
  }
}

/**
 * Starts polling for a leadlist's download status
 * @param format - The format being downloaded
 */
function startPollingForLeadList(leadList: LeadList, format: string): void {
  const formats = initializeDownloadFormats(leadList.id);
  const formatObj = formats.find((f) => f.value === format);
  if (!formatObj) return;

  formatObj.isLoading = true;

  if (pollInterval) {
    clearInterval(pollInterval);
  }

  pollInterval = setInterval(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `/api/usecase-4/export/${leadList.sales_region}/leadlists/`,
      });

      const currentLeadList = data.results.find(
        (lead: LeadList) => lead.id === leadList.id,
      );

      if (currentLeadList?.downloadable_file?.state === 'READY') {
        formatObj.downloadUrl = currentLeadList.downloadable_file.file;
        formatObj.isLoading = false;
        formatObj.isDownloadReady = true;

        formats.forEach((f) => {
          if (f.value !== format) {
            f.isLoading = false;
            f.isDownloadReady = false;
          }
        });

        clearInterval(pollInterval);
        pollInterval = undefined;
      }
    } catch (error) {
      console.error('Error polling status:', error);
    }
  }, 3000);
}

/**
 * Handles click events on download options
 * @param option - The clicked download option
 */
function handleItemClick(leadList: LeadList, option: DownloadFormat): void {
  if (option.isDownloadReady && option.downloadUrl) {
    downloadReadyFile(option.downloadUrl, option);
  } else {
    downloadReport(leadList, option.value);
  }
}

/**
 * Opens the download panel and initializes/checks download states
 */
function openDownloadPanel(leadList: LeadList): void {
  const currentFormats = downloadFormatsMap.value.get(leadList.id);
  const hasOngoingDownload = currentFormats?.some((f) => f.isLoading);
  const hasReadyDownload = currentFormats?.some((f) => f.isDownloadReady);

  if (hasOngoingDownload || hasReadyDownload) {
    if (!currentFormats) {
      initializeDownloadFormats(leadList.id);
    }
  } else {
    downloadFormatsMap.value.delete(leadList.id);
    initializeDownloadFormats(leadList.id);
  }

  // Check for pending downloads
  const format = leadListFileReadyFormat.value;
  if (leadList.downloadable_file?.state === 'PENDING' && format) {
    const formats = downloadFormatsMap.value.get(leadList.id);
    const formatObj = formats?.find(
      (f) => f.value === format || f.alternateFileEndings.includes(format),
    );
    if (formatObj && !formatObj.isLoading) {
      startPollingForLeadList(leadList, format);
    }
  }
}

/**
 * Checks for ready or pending downloads when component mounts
 */
function checkForReadyDownloads(leadList: LeadList): void {
  const format = leadListFileReadyFormat.value;
  if (!format) return;

  if (leadList.downloadable_file?.state === 'READY') {
    const formats = initializeDownloadFormats(leadList.id);
    const formatObj = formats.find(
      (f) => f.value === format || f.alternateFileEndings.includes(format),
    );

    if (formatObj) {
      formatObj.isDownloadReady = true;
      formatObj.downloadUrl = leadList.downloadable_file.file;
      formatObj.isLoading = false;
    }
  } else if (leadList.downloadable_file?.state === 'PENDING') {
    const formats = initializeDownloadFormats(leadList.id);
    const formatObj = formats.find(
      (f) => f.value === format || f.alternateFileEndings.includes(format),
    );

    if (formatObj && !formatObj.isLoading) {
      startPollingForLeadList(leadList, format);
    }
  }
}

onMounted(() => {
  checkForReadyDownloads(props.leadList);
});

onUnmounted(() => {
  if (pollInterval) {
    clearInterval(pollInterval);
  }
});
</script>
