<template>
  <ButtonEl
    icon="add"
    icon-fill="text-inverted-color1"
    text="Neuen Szenariorahmen anlegen"
    @click="showNewFrameworkDialog = true"
  />
  <VuetifyDialog
    v-model="showNewFrameworkDialog"
    title="Neuen Szenariorahmen anlegen"
    :persistent="true"
    :overflow-visible="true"
    :activator-full-width="false"
  >
    <template #content>
      <div class="flex flex-col gap-5">
        <div class="whitespace-pre-line">
          {{
            `Geben Sie dem neuen Szenariorahmen einen Namen und wählen Sie eine
            Basis aus, um vordefinierte Werte zu übernehmen.`
          }}
        </div>
        <FormValidator v-model="dialogInputIsValid" class="gap-2">
          <InputEl
            v-model="name"
            label="Name Szenariorahmen"
            placeholder="Bitte eingeben"
            :rules="{ required: true }"
            :unique-check-values="scenarioFrames"
          />
          <DropDown
            v-model="basedOnFrameId"
            :items-data="
              scenarioFrames.map((e) => ({
                id: e.id,
                name: e.title,
              }))
            "
            items-data-key="name"
            value="id"
            :initial-selection="true"
            :rules="{ required: true }"
            label="Der Szenariorahmen basiert auf:"
          />
        </FormValidator>
      </div>
    </template>
    <template #actions>
      <ButtonEl
        text="Leeren Szenariorahmen anlegen"
        class="mr-auto"
        color="color2"
        variant="secondary"
        @click="emit('createInstance', name)"
      />
      <ButtonEl
        text="Abbrechen"
        color="color2"
        @click="showNewFrameworkDialog = false"
      />
      <ButtonEl
        text="Anlegen"
        :disabled="!dialogInputIsValid"
        @click="emit('copyInstance', { id: basedOnFrameId, title: name })"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { ref } from 'vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import InputEl from '@/components/input/InputEl.vue';
import DropDown from '@/components/DropDown/DropDown.vue';

const emit = defineEmits(['copyInstance', 'createInstance']);
const props = defineProps({ scenarioFrames: Array });

const dialogInputIsValid = ref(false);
const showNewFrameworkDialog = ref(false);
const basedOnFrameId = ref(null);
const name = ref(null);
</script>
