export const SECTOR_LEGENDS_ORDERED = [
  'Private Haushalte',
  'Gewerbe, Handel,\nDienstleistungen',
  'Industrie',
  'Öffentlich',
  'Mobilität',
  'Sonstige',
];
export const SECTOR_NO_MOBILITY_LEGENDS_ORDERED = [
  'Private Haushalte',
  'Gewerbe, Handel,\nDienstleistungen',
  'Industrie',
  'Öffentlich',
  'Sonstige',
];
export const RENEWABLE_GENERATION_LEGENDS_ORDERED = [
  'PV-Aufdach',
  'PV-Freifläche',
  'Wind',
  'Biomasse',
  'Wasserkraft',
];

export function formatChartTooltip(value, unit = null) {
  const options =
    value >= 10000
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 1, maximumFractionDigits: 1 };

  const formattedValue = value.toLocaleString('de-DE', options);

  const finalValue = formattedValue.endsWith(',0')
    ? formattedValue.slice(0, -2)
    : formattedValue;

  return `${finalValue}${unit ? ` ${unit}` : ''}`;
}

export function formatChartAxisLabel(value) {
  return value.toLocaleString('de-DE');
}

export function formatTooltip(params, props) {
  if (!props.toolTipShowTotals) {
    const param = params; // For single item case
    return `
      <div style='display: flex; align-items: center;'>
        <span style='display:inline-block; border-radius: 0; width:12px; height:12px; background-color:${param.color}; margin-right:5px;'></span>
        <span>${param.seriesName}: ${formatChartTooltip(param.value, props.chartUnit)}</span>
      </div>`;
  }

  // For the case where toolTipShowTotals is true
  let tooltipContent = '';
  let totalValue = 0;

  const validParams = params
    .filter((param) => param.value && !isNaN(param.value) && param.value !== 0)
    .reverse();

  if (validParams.length === 0) {
    return;
  }

  validParams.forEach((param) => {
    tooltipContent += `
      <div style='display: flex; align-items: center;'>
        <span style='display: inline-block; border-radius: 0; width: 12px; height: 12px; background-color: ${param.color}; margin-right: 5px;'></span>
        <div style='display: flex; justify-content: space-between; width: 100%; gap: 10px'>
          <span>${param.seriesName}:</span>
          <span style='margin-left: auto'>${formatChartTooltip(param.value, props.chartUnit)}</span>
        </div>
      </div>`;
    totalValue += param.value;
  });

  if (validParams.length > 1) {
    tooltipContent += `
      <div style='display: flex; justify-content: space-between; border-top: 1px solid #C3BCB6; margin-top: 5px; padding-top: 5px;'>
        <span>Gesamt:</span>
        <span style='margin-left: auto'>${formatChartTooltip(totalValue, props.chartUnit)}</span>
      </div>`;
  }

  return `<div>${tooltipContent}</div>`;
}
