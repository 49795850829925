export const contentStructureAssumptionsGeneral = [
  {
    tab: {
      title: 'Heizlastermittlung',
      icon: 'text_snippet',
    },
    blocks: [
      {
        flow: 'horizontal',
        gap: 10,
        width: '33%',
        children: [
          {
            subTitle: 'Volllaststunden Wärme je Sektor\n(vor Gleichzeitigkeit)',
            subTitleTag: 'h4',
            subTitleColor: 'text-color1',
            gap: 5,
            fields: [
              'full_load_hours_heat_private_h_a',
              'full_load_hours_heat_ghd_other_h_a',
              'full_load_hours_heat_industrial_h_a',
              'full_load_hours_heat_communal_h_a',
              'full_load_hours_heat_other_h_a',
            ],
          },
        ],
      },
    ],
  },
  {
    tab: {
      title: 'Commodity-Potenzial',
      icon: 'text_snippet',
    },
    blocks: [
      {
        flow: 'horizontal',
        gap: 10,
        width: '33%',
        children: [
          {
            gap: 5,
            fields: [
              'annual_performance_factor',
              'specific_contribution_margin_eur_kw',
            ],
          },
        ],
      },
    ],
  },
];
