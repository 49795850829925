<template>
  <div class="space-y-5">
    <InputSearch
      class="w-[565px]"
      placeholder="Nach Vertriebsgebiet oder Ersteller:in suchen"
      :search-keys="['title', 'created_by']"
      :items="leadLists"
      @update:search="filteredLeadLists = $event"
    />
    <VuetifyDataTable
      :item-data="filteredLeadLists"
      :headers="headers"
      expanded-background="bg-subtle"
    >
      <template #title="{ item }">
        <p class="subtitle-2">
          {{ item.title }}
        </p>
      </template>
      <template #state="{ item }">
        <div v-if="showToolTip" class="flex justify-center items-center">
          <ToolTip :tooltip-text="getListProgressIcon(item.state).label">
            <div
              class="rounded p-0.5"
              :class="getListProgressIcon(item.state).fill"
            >
              <IconWrapper :icon="getListProgressIcon(item.state).icon" />
            </div>
          </ToolTip>
        </div>
      </template>
      <template #functions="{ item }">
        <div class="flex justify-start items-center gap-4">
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="tune"
            class="cursor-pointer"
            type="round"
            @click="toggleFilterOverview(item)"
          />
          <DeleteDialog
            :id="item.id"
            :name-deleted-item="item.title"
            :url="LeadListDto.BASE_URL"
            @instance-removed="deletedLeadList"
          >
            <IconWrapper
              v-if="userStore.uc4CanDeleteLeadList"
              :size="32"
              fill="text-core-color1"
              hover="hover:text-button-primary-hover-color1"
              icon="delete_forever"
              class="cursor-pointer"
              type="round"
            />
          </DeleteDialog>
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="map"
            class="cursor-pointer"
            type="round"
            @click="navigateToMap(router, salesRegionId, item.id)"
          />

          <EvaluationExportDownload
            v-if="showToolTip && userStore.uc4CanExportData"
            :lead-list="item"
          />
        </div>
      </template>
      <template #expanded-row="{ item }">
        <EvaluationExportExpanded
          :lead-list="item"
          @update:lead-list="updateLeadList"
        />
      </template>
    </VuetifyDataTable>
    <EvaluationExportFilterSettings v-model="filterOverviewOpen" :filter-set />
  </div>
</template>

<script setup>
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import { onMounted, ref } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import EvaluationExportExpanded from '@/apps/usecase-4/evaluation/EvaluationExportExpanded.vue';
import EvaluationExportFilterSettings from '@/apps/usecase-4/evaluation/components/EvaluationExportFilterSettings.vue';
import { restFetch } from '@/utils/rest-utils';
import { EvaluationFiltersetDto } from './components/evaluation-filterset-dto';
import axios from '@/utils/axiosHelper';
import { getListProgressIcon } from '@/apps/usecase-4/utils';
import { navigateToMap } from '@/apps/usecase-4/utils';
import { useRouter } from 'vue-router';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import { LeadListDto } from '@/apps/usecase-4/evaluation/components/lead-list-dto';
import { useToastStore } from '@/stores/toast-store';
import { useUsers } from '@/apps/auth/users-store';
import EvaluationExportDownload from '@/apps/usecase-4/evaluation/components/EvaluationExportDownload.vue';

const userStore = useUsers();
const { showToast } = useToastStore();

const router = useRouter();

const props = defineProps({
  salesRegionId: {
    type: String,
    required: true,
  },
});

const leadLists = ref([]);
const filteredLeadLists = ref([]);
const isLoading = ref(false);
const showToolTip = ref(false);
const filterOverviewOpen = ref(false);
const filterSet = ref(null);

async function fetchLeadLists() {
  isLoading.value = true;
  try {
    const {
      data: { results },
    } = await axios({
      url: `/api/usecase-4/export/${props.salesRegionId}/leadlists/`,
      method: 'GET',
    });
    leadLists.value = results;
    filteredLeadLists.value = results;
  } catch (error) {
    console.error('Failed to fetch lead lists:', error);
  } finally {
    isLoading.value = false;
  }
}

onMounted(() => {
  showToolTip.value = true;
});

// Fetch lead lists when component is created
await fetchLeadLists();

async function toggleFilterOverview(item) {
  filterSet.value = await restFetch(EvaluationFiltersetDto, item.filterset);
  filterOverviewOpen.value = !filterOverviewOpen.value;
}

function updateLeadList(leadList) {
  const index = leadLists.value.findIndex((item) => item.id === leadList.id);
  leadLists.value[index] = leadList;
}

async function deletedLeadList() {
  await fetchLeadLists();
  showToast({
    color: 'success',
    message: 'Eintrag gelöscht',
  });
}

const headers = [
  {
    title: 'Leadliste',
    key: 'title',
    width: '200px',
    align: 'left',
  },
  {
    title: 'Filterset',
    key: 'filterset_title',
    align: 'left',
  },
  {
    title: 'Erstellt von',
    key: 'created_by',
    width: '200px',
    align: 'left',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    width: '150px',
    isDate: true,
    sortable: true,
  },
  {
    title: 'Status Leadliste',
    key: 'state',
    width: '110px',
    align: 'center',
  },
  {
    title: '',
    key: 'functions',
    width: '230px',
    align: 'end',
    sortable: false,
  },
];
</script>
