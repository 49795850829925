import { defineStore } from 'pinia';
import axios from 'axios';

export const useBaseStore = defineStore('baseStore', {
  state: () => ({
    fetchData: {
      municipality: {
        geoData: [],
        isFetching: false,
        fetchPromise: null,
      },
      'postal-code': {
        geoData: [],
        isFetching: false,
        fetchPromise: null,
      },
    },
    zIndexSliderContainer: 1000,
  }),
  actions: {
    increaseSliderContainerZIndex() {
      this.zIndexSliderContainer += 1;
      return this.zIndexSliderContainer;
    },
    async fetchSimplifiedMuniData() {
      return this.fetch(
        '/api/basemodule/simplified-municipality-data/',
        'municipality',
      );
    },
    async fetchSimplifiedPostalCodeData() {
      return this.fetch(
        '/api/basemodule/simplified-postal-code-data/',
        'postal-code',
      );
    },
    async fetch(url, spacialAreaKey) {
      // If already fetching, return the existing promise to avoid duplicate calls
      if (this.fetchData[spacialAreaKey].fetchPromise)
        return this.fetchData[spacialAreaKey].fetchPromise;

      // Return data if already fetched
      if (this.fetchData[spacialAreaKey].geoData.length) {
        return this.fetchData[spacialAreaKey].geoData;
      }

      // Create and store the fetch promise
      this.fetchData[spacialAreaKey].isFetching = true;
      this.fetchData[spacialAreaKey].fetchPromise = (async () => {
        try {
          const response = await axios.get(url);
          if (spacialAreaKey === 'municipality') {
            this.fetchData[spacialAreaKey].geoData = response.data.results.sort(
              (a, b) => a.name.localeCompare(b.name),
            );
          } else {
            this.fetchData[spacialAreaKey].geoData = response.data.results;
          }
          return this.fetchData[spacialAreaKey].geoData;
        } catch (error) {
          console.error(error);
          throw error; // Allow error to propagate to await calls
        } finally {
          this.fetchData[spacialAreaKey].isFetching = false;
          this.fetchData[spacialAreaKey].fetchPromise = null; // Clear the promise after fetching completes
        }
      })();

      return this.fetchData[spacialAreaKey].fetchPromise; // Return the fetch promise to await
    },
  },
});
