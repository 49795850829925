<template>
  <!-- Prevent pushing the content below dropdown -->
  <div
    :class="{
      'mb-[-26px]': dropdownOpen && !small,
      'mb-[-28px]': dropdownOpen && small,
    }"
  >
    <TheLabel :label="label" :disabled />
    <div
      ref="dropdownRef"
      class="dropdown-multiple w-full relative"
      :class="{
        'pointer-events-none': model.length === 0 || disabled,
      }"
    >
      <div
        class="flex justify-between items-center cursor-pointer"
        :class="{
          'rounded-b-none border-2 border-b-0 text-neutral border-line-active-neutral py-3':
            dropdownOpen,
          'h-10': !dropdownOpen && !small,
          'h-[30px]': !dropdownOpen && small,
          'bg-active-area': !customBackground && !disabled,
          'bg-subtle': dropdownOpen && !disabled,
          '!py-2': dropdownOpen && small,
          [customBackground]: customBackground,
          'text-neutral': !disabled,
          disabled: disabled,
        }"
        @click="toggleDropdown"
      >
        <div
          v-if="model.length !== 0"
          ref="selectedItems"
          class="whitespace-nowrap overflow-hidden w-full pr-20 pl-3"
          :class="{
            'body-2': !small,
            'body-3': small,
            'text-neutral': !disabled,
            'text-disabled-neutral': disabled,
          }"
        >
          <span v-if="!dropdownOpen" @click.stop="toggleDropdown">
            {{ previewText ? previewText : placeholder }}
          </span>
          <InputEl
            v-if="dropdownOpen"
            :size="small ? 'm' : 'l'"
            suffix="search"
            :suffix-icon="true"
            :model-value="filter"
            :disabled
            @click.stop=""
            @update:model-value="filter = $event"
          />
        </div>

        <div
          :class="{
            'bg-active-area': !customBackground && !disabled,
            'bg-subtle': dropdownOpen || disabled,
            [customBackground]: customBackground,
          }"
          class="flex gap-2 items-center multi-dropdown-actions rounded-r-[6px]"
        >
          <IconWrapper
            v-if="somethingSelected"
            icon="cancel"
            type="round"
            fill="text-color1"
            @click.stop="clearSelection()"
          />
          <IconWrapper
            icon="arrow_drop_down"
            :size="24"
            :class="[{ 'rotate-180': dropdownOpen }, 'duration-300']"
            type="round"
          />
        </div>
      </div>
      <div
        v-if="dropdownOpen"
        class="dropdown-animation w-full rounded-b-[6px] absolute z-10 left-0 bg-default py-1 border-2 border-t-0 border-line-active-neutral pr-1"
        @wheel.stop
      >
        <span
          v-if="noDataFoundByFilter"
          :class="{ 'p-2': small, 'p-3': !small }"
          >Keine Einträge gefunden</span
        >
        <div
          v-else
          class="overflow-y-auto"
          :style="{
            'max-height': `${maxHeightDropdown}px`,
          }"
        >
          <div class="mr-1">
            <div v-for="item in model" :key="item.value">
              <DropDownMultipleGroupedList
                ref="dropDownList"
                v-model="model"
                :small="small"
                :level-adjustments="levelAdjustments"
                :level="0"
                :expandable="'expanded' in item"
                :expanded="'expanded' in item && item.expanded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/input/InputEl.vue';
import { computed, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue';
import TheLabel from '../label/TheLabel.vue';
import DropDownMultipleGroupedList from './DropDownMultipleGroupedList.vue';

const model = defineModel({ type: Array[Object], required: true });
const props = defineProps({
  small: {
    type: Boolean,
    default: false,
  },
  itemTypeAll: {
    type: String,
    default: 'Elemente',
  },
  label: {
    type: String,
    default: null,
  },
  customBackground: {
    type: String,
    default: null,
  },
  maxHeightDropdown: {
    type: Number,
    default: 200,
  },
  placeholder: {
    type: String,
    default: 'Elemente auswählen',
  },
  levelAdjustments: {
    type: Array,
    default: () => [
      { indent: 0, fontClass: '' },
      { indent: 20, fontClass: '' },
      { indent: 30, fontClass: 'font-light' },
    ],
  },
  custonPreviewText: {
    type: String,
    default: undefined,
  },
  maxPreviewItems: {
    type: Number,
    default: 2,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const dropdownOpen = ref(false);
const filter = ref('');
const noDataFoundByFilter = ref(false);
const dropdownRef = ref(null);

const somethingSelected = computed(() => {
  const lookForSelectedSubItem = (item) => {
    if (item.checked) {
      return true;
    }
    if (!hasMoreSubItems(item) && !item.checked) {
      return false;
    }
    return item.items.reduce((prev, cur) => {
      return prev && hasMoreSubItems(cur) && lookForSelectedSubItem(cur);
    });
  };
  for (let item of model.value) {
    if (item.checked || (hasMoreSubItems(item) && lookForSelectedSubItem(item)))
      return true;
  }
  return false;
});

const previewText = computed(() => {
  const previewNames = getPreviewNames(model.value);
  if (props.custonPreviewText) {
    return props.custonPreviewText;
  }
  if (typeof previewNames === 'string') {
    return previewNames;
  }
  if (previewNames.length > props.maxPreviewItems) {
    return `${previewNames.slice(0, props.maxPreviewItems).join(', ')} und ${
      previewNames.length - props.maxPreviewItems
    } weitere`;
  } else {
    return `${previewNames.slice(0, props.maxPreviewItems).join(', ')}`;
  }
});

const hasMoreSubItems = (item) => {
  return 'items' in item && Array.isArray(item.items) && item.items.length > 0;
};

const calculateStateByChildren = (item) => {
  if (hasMoreSubItems(item)) {
    item.checked = item.items.reduce((prev, cur) => prev && cur.checked, true);
  }
};

const getPreviewNames = (items) => {
  let previewNames = [];
  let allChecked = true;

  // Check all items and their sub-items for checked status
  const checkItems = (items) => {
    for (let item of items) {
      if (hasMoreSubItems(item)) {
        checkItems(item.items);
      } else {
        if (item.checked) {
          previewNames.push(item.name);
        } else {
          allChecked = false;
        }
      }
    }
  };

  checkItems(items);

  if (allChecked) {
    return `Alle ${props.itemTypeAll} ausgewählt`;
  }

  return previewNames;
};

const filterItemsData = () => {
  const filterFormatted = (filter.value || '').trim().toLowerCase();

  const setSubItemsVisible = (item) => {
    if (hasMoreSubItems(item)) {
      for (let subItem of item.items) {
        subItem.hidden = false;
        setSubItemsVisible(subItem);
      }
    }
  };
  const applyFilter = (item, itemIndex, parentItems, parentTreeList) => {
    const itemValue = item.name.trim().toLowerCase();
    if (itemValue.includes(filterFormatted)) {
      parentItems[itemIndex].hidden = false;
      setSubItemsVisible(item);

      for (let item of parentTreeList) {
        if ('expanded' in item) {
          item.expanded = true;
        }
      }

      return true;
    } else {
      if (hasMoreSubItems(item)) {
        const childrenRemain = item.items.reduce(
          (prev, cur, curI) =>
            prev |
            applyFilter(cur, curI, item.items, parentTreeList.concat(item)),
          false,
        );
        if (!childrenRemain) {
          parentItems[itemIndex].hidden = true;
          return false;
        } else {
          parentItems[itemIndex].hidden = false;
          return true;
        }
      } else {
        parentItems[itemIndex].hidden = true;
        return false;
      }
    }
  };

  const anythingFound = model.value.reduce(
    (prev, cur, curIndex) =>
      prev | applyFilter(model.value[curIndex], curIndex, model.value, [cur]),
    false,
  );

  noDataFoundByFilter.value = !anythingFound;
};

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const handleOutsideClick = (event) => {
  if (!dropdownRef.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

const clearSelection = () => {
  const uncheckItemsAndSubItems = (item) => {
    item.checked = false;
    if (hasMoreSubItems(item)) {
      for (let subItem of item.items) {
        uncheckItemsAndSubItems(subItem);
      }
    }
  };
  for (let item of model.value) {
    uncheckItemsAndSubItems(item);
  }
};

watch(
  () => filter.value,
  () => {
    filterItemsData();
  },
);

watch(
  () => model.value,
  () => {
    filterItemsData();
  },
);

watchEffect(() => {
  const evaluateChildren = (item) => {
    if (hasMoreSubItems(item)) {
      for (let subItem of item.items) {
        evaluateChildren(subItem);
        calculateStateByChildren(subItem);
      }
    }
  };

  for (let topLevelItem of model.value) {
    if (hasMoreSubItems(topLevelItem)) {
      evaluateChildren(topLevelItem);
      calculateStateByChildren(topLevelItem);
    }
  }
});

onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
});
</script>

<style lang="scss" scoped>
.multi-dropdown-actions {
  position: absolute;
  right: 2px;
  height: calc(100% - 2px);
  padding-right: 6px;
  padding-left: 6px;
}

.disabled {
  @apply bg-subtle text-disabled-neutral border-line-disabled-neutral;
}
</style>

<style lang="scss">
/* Below animation code doesn't work in scoped style block */
.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes dropdown-animation {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
