<template>
  <SlideContainer
    title="Filter Restriktionsflächen"
    :prevent-close-from-outside="true"
  >
    <template #activator="{ openModal, modalIsOpen }">
      <ButtonEl
        class="rounded-[2px] p-0.5"
        size="m"
        :icon="icon"
        :color="modalIsOpen ? 'color1' : isFilterActive ? 'success' : 'color2'"
        @click="openModal"
      />
    </template>
    <RestrictionFilter :map-store="mapStore" />
  </SlideContainer>
</template>

<script setup>
import RestrictionFilter from '@/apps/usecase-2/map/RestrictionFilter.vue';
import SlideContainer from '@/components/SlideContainer.vue';
import { computed } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { LAYER_KEY__RESTRICTION } from '@/apps/features/map/layer-config/base-data';

const props = defineProps({
  toggleOption: {
    type: Object,
    required: true,
  },
  layerState: {
    type: String,
    required: true,
  },
  parentId: {
    type: String || null,
    default: null,
  },
  grandParentId: {
    type: String || null,
    default: null,
  },
  mapStore: {
    type: Object,
    required: true,
  },
  icon: {
    type: String,
  },
});

const isFilterActive = computed(() => {
  const restrictionFilter = props.mapStore.filters[LAYER_KEY__RESTRICTION];
  if (typeof restrictionFilter === 'undefined') return false;
  return Object.keys(restrictionFilter).includes('restrictionFilter');
});
</script>

<style lang="scss" scoped></style>
