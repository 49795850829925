<template>
  <div class="standard-container">
    <DropDown
      v-model="filterSetSelectedWithId"
      :items-data="filterSets"
      label="Filterset"
      enable-search
      items-data-key="title"
      value="id"
      max-height-dropdown="200px"
    >
      <template #selected>
        <span
          v-if="filterSet?.is_default"
          class="caption-3 uppercase text-title-neutral p-[5px] pb-1 rounded-sm border border-title-neutral"
          >Standard</span
        >
      </template>
      <template #options="{ item }">
        <span
          v-if="item.is_default"
          class="caption-3 uppercase text-title-neutral p-[5px] pb-1 rounded-sm border border-title-neutral"
          >Standard</span
        >
        <IconWrapper
          v-else
          icon="more_horiz"
          fill="text-title-neutral"
          hover="hover:text-color1"
          class="cursor-pointer"
          @click.stop="showSetDefault = item.id"
        />

        <Transition name="fade">
          <div
            v-if="showSetDefault === item.id && userStore.uc4CanChangeFilterSet"
            ref="setDefaultContainer"
            class="absolute w-fit right-2.5 bg-white rounded-[4px] standard-elevation-1 overflow-hidden flex items-center gap-1.5 p-2 py-1.5 hover:bg-active-area cursor-pointer"
            @click="setFilterAsDefault(item.id)"
          >
            <IconWrapper icon="flag" fill="text-title-neutral" />
            <div class="body-2 pt-[3px] text-neutral">
              Als Standard festlegen
            </div>
          </div>
        </Transition>
      </template>
    </DropDown>
    <div class="flex items-center justify-between gap-2.5 relative">
      <ButtonEl
        icon="more_horiz"
        color="color2"
        @click="showMoreOptions = true"
      />
      <ButtonEl
        text="anwenden"
        :is-loading="isLoading"
        :disabled="!filterSets.length"
        @click="applyFilter"
      />

      <Transition name="fade">
        <div
          v-if="showMoreOptions"
          ref="moreOptionsContainer"
          class="absolute top-0 w-full bg-white rounded-md standard-elevation-0-dark z-10 overflow-hidden"
        >
          <div
            v-for="option in moreOptionsFilterSet.filter(
              (option) => !option.disabled,
            )"
            :key="option.label"
            class="flex items-center gap-1.5 p-3 hover:bg-active-area cursor-pointer"
            @click="option.action()"
          >
            <IconWrapper :icon="option.icon" fill="text-title-neutral" />
            <div class="body-2 pt-[3px] text-neutral">
              {{ option.label }}
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <EvaluationFilterPanels
      v-model="filterSet"
      @update:model-value="updateFilterSet"
    />
  </div>

  <VuetifyDialog v-model="showCreateDialog" :title="'Filterset erstellen'">
    <template #content>
      <FormValidator v-model="createNameIsValid">
        <InputEl
          v-model="newFiltersetName"
          label="Name neues Filterset"
          :rules="{ required: true }"
          :unique-check-values="filterSets"
          unique-map-key="title"
        />
      </FormValidator>
    </template>
    <template #actions>
      <ButtonEl
        text="Anlegen"
        :disabled="!newFiltersetName || !createNameIsValid"
        @click="createFilterSet"
      />
    </template>
  </VuetifyDialog>
  <DeleteDialog
    :id="selectedFilterId ?? undefined"
    :name-deleted-item="filterSet?.title ?? undefined"
    :dto="EvaluationFiltersetDto"
    :set-dialog="showDeleteDialog"
    @instance-removed="getFilterSets"
    @update:dialog="showDeleteDialog = $event"
  />
</template>

<script setup lang="ts">
import { EvaluationFiltersetDto } from './evaluation-filterset-dto';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import FormValidator from '@/components/FormValidator.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/input/InputEl.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import { useToastStore } from '@/stores/toast-store';
import { restFetchAll, restPatch, restPost } from '@/utils/rest-utils';
import { onClickOutside } from '@vueuse/core';
import { computed, ref, watch, type ComputedRef } from 'vue';
import type { FilterSet } from './types';
import { cloneDeep } from 'lodash';
import EvaluationFilterPanels from './EvaluationFilterPanels.vue';
import { useUsers } from '@/apps/auth/users-store';

interface MoreOption {
  label: string;
  icon: string;
  disabled?: ComputedRef<boolean>;
  action: () => void;
}

type EmitFunctions = {
  (e: 'update:modalIsOpen', value: boolean): void;
  (e: 'update:filterSets', value: FilterSet[]): void;
  (e: 'update:municipality', value: unknown): void;
  (e: 'apply:filter', value: FilterSet | null): void;
  (e: 'changed:filterIsDirty', value: boolean): void;
  (e: 'changed:filterId', value: number | null): void;
};

const { showToast } = useToastStore();
const userStore = useUsers();

const emit = defineEmits<EmitFunctions>();

const props = defineProps({
  salesRegionId: {
    type: [Number, String],
    required: true,
  },
  slideContainerIsOpen: {
    type: Boolean,
    required: true,
  },
});

const isLoading = ref<boolean>(false);

watch(
  () => props.slideContainerIsOpen,
  (newVal) => {
    if (!newVal) {
      isLoading.value = false;
    }
  },
);

const filterSets = ref<FilterSet[]>([]);
const filterSet = ref<FilterSet | null>(new EvaluationFiltersetDto());
const selectedFilterId = ref<number | null>(null);
const showMoreOptions = ref<boolean>(false);
const showSetDefault = ref<number | null>(null);
const moreOptionsContainer = ref<HTMLElement | null>(null);
const setDefaultContainer = ref<HTMLElement | null>(null);
const showDeleteDialog = ref<boolean>(false);
const showCreateDialog = ref<boolean>(false);
const newFiltersetName = ref<string>('');
const createNameIsValid = ref<boolean>(false);
const moreOptionsFilterSet = ref<MoreOption[]>([
  {
    label: 'Neues Filterset erstellen',
    icon: 'add',
    disabled: computed(() => !userStore.uc4CanAddFilterSet),
    action: () => {
      showCreateDialog.value = true;
      showMoreOptions.value = false;
    },
  },
  {
    label: 'Filterset auf gespeicherten Stand zurücksetzen',
    icon: 'replay',
    disabled: computed(() => !filterSets.value.length),
    action: () => {
      resetFilterSet();
    },
  },
  {
    label: 'Filterset löschen',
    icon: 'delete',
    disabled: computed(
      () =>
        (!userStore.uc4CanDeleteFilterSet ||
          filterSet.value?.is_default ||
          filterSet.value?.has_leadlists) ??
        false,
    ),
    action: () => {
      showMoreOptions.value = false;
      showDeleteDialog.value = true;
    },
  },
]);

const filterSetSelectedWithId = computed({
  get() {
    return selectedFilterId.value;
  },
  set(filtersetId: number | null) {
    selectedFilterId.value = filtersetId;
    if (filtersetId === null) {
      filterSet.value = new EvaluationFiltersetDto();
    } else {
      const foundSet = filterSets.value.find((set) => set.id === filtersetId);
      filterSet.value = foundSet
        ? cloneDeep(foundSet)
        : new EvaluationFiltersetDto();
    }
  },
});

await getFilterSets();

async function getFilterSets(): Promise<void> {
  const response = await restFetchAll(EvaluationFiltersetDto);
  // const response: FilterSet[] = [];
  filterSets.value = response as FilterSet[];
  emit('update:filterSets', filterSets.value);
  const defaultSet = filterSets.value.find((set) => set.is_default);
  const firstSet = filterSets.value[0];
  filterSetSelectedWithId.value = defaultSet?.id || firstSet?.id || null;
}

async function createFilterSet(): Promise<void> {
  if (!filterSet.value) return;

  filterSet.value.title = newFiltersetName.value;

  const fieldsToPost: Partial<FilterSet> = { ...filterSet.value };
  delete fieldsToPost.id;
  delete fieldsToPost.is_default;

  const newFilterSet = await restPost(
    filterSet.value,
    Object.keys(fieldsToPost),
  );
  await getFilterSets();
  filterSetSelectedWithId.value = newFilterSet.data.id;
  showCreateDialog.value = false;
}

async function setFilterAsDefault(id: number): Promise<void> {
  await restPatch(EvaluationFiltersetDto, id, { is_default: true }).then(
    async () => {
      showSetDefault.value = null;
      showToast({
        color: 'success',
        message: 'Filterset als Standard festgelegt',
      });
      await getFilterSets();
    },
  );
}

async function resetFilterSet(): Promise<void> {
  filterSetSelectedWithId.value = selectedFilterId.value;
  showMoreOptions.value = false;
  showToast({
    color: 'success',
    message: 'Filterset zurückgesetzt',
  });
}

async function applyFilter(): Promise<void> {
  isLoading.value = true;
  emit('apply:filter', filterSet.value);
}

onClickOutside(moreOptionsContainer, () => {
  showMoreOptions.value = false;
});

onClickOutside(setDefaultContainer, () => {
  showSetDefault.value = null;
});

watch(
  [showDeleteDialog, showCreateDialog],
  ([newDeleteDialog, newCreateDialog]) => {
    emit('update:modalIsOpen', Boolean(newDeleteDialog || newCreateDialog));
    if (newCreateDialog) {
      newFiltersetName.value = '';
    }
  },
);

function updateFilterSet(updatedFilter: FilterSet): void {
  filterSet.value = updatedFilter;
}
function areFilterSetsEqual(
  setA: FilterSet | null,
  setB: FilterSet | null,
): boolean {
  if (!setA || !setB) return setA === setB;
  return JSON.stringify(setA) === JSON.stringify(setB);
}
watch(
  () => filterSet,
  (newVal) => {
    const originalSet =
      filterSets.value.find((set) => set.id === selectedFilterId.value) ?? null;
    emit(
      'changed:filterIsDirty',
      !areFilterSetsEqual(newVal.value, originalSet),
    );
    emit('changed:filterId', selectedFilterId.value);
  },
  { deep: true, immediate: true },
);
</script>
