import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useBaseMapStore } from '@/apps/features/map/base-map-store';
import { layerPanelConfig as panelConfig } from '@/apps/usecase-2/map/layer-panel/layer-panel-config';
import layers from './layer-config';
import { modes } from '@/apps/features/map/draw/constants';
import { getMarked } from '@/mapbox/onClick/constructorClick';
import axios from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import {
  LAYER_KEY__ESTATE,
  LAYER_KEY__HIGH_VOLTAGE_GRID,
} from '@/apps/features/map/layer-config/base-data';
import { drawChangeMode, getMap, setGeoJsonData } from '@/mapbox/main';
import { LAYER_KEY__EXTERNAL_PROJECT } from '@/apps/usecase-2/map/layer-config/external-project';
import { computed, markRaw } from 'vue';
import {
  LAYER_KEY__PV_PROJECT,
  LAYER_KEY__PV_PROJECT_CONNECTION,
  LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
  LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
  LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
} from '@/apps/usecase-2/map/layer-config/potential-analysis';
import { useToastStore } from '@/stores/toast-store';
import IconTrafo from '@/assets/icons/custom/energy-grid/IconTrafo.vue';

export const useMapStore = defineStore('MapUsecase2', {
  state: () => ({
    ...useBaseMapStore.state(),
    layerPanelConfig: cloneDeep(panelConfig),
    layerConfigs: cloneDeep(layers),
    mapActions: {},
    mapActionActive: null,
    customPvProjectActionId: 'customPvProject',
    estateIdsSelected: new Set(),
    estatesSelectedArea: null,
    measureToolActionId: 'measureTool',
    measureToolValue: null,
    externalProjectActionId: 'externalProject',
    externalProjectFeatureType: 'externalProject',
    externalProjectsFetched: false,
    estateSearchActionId: 'estateSearch',
    clusterActionId: 'cluster',
    clusterWithBattery: true,
    clusterProjectIdsSelected: new Set(),
    clusterExternalProjectIdsSelected: new Set(),
    clusterProjectSelectedPower: null,
    clusterSubstationFeatureType: 'subStation',
    filterContainerOpen: false,
    activeFilterId: null,
  }),
  getters: {
    ...useBaseMapStore.getters,
    showCustomPvProjectForm: (state) => {
      return state.mapActionActive?.id === state.customPvProjectActionId;
    },
    showMeasureToolForm: (state) => {
      return state.mapActionActive?.id === state.measureToolActionId;
    },
    showExternalProjectForm: (state) => {
      return state.mapActionActive?.id === state.externalProjectActionId;
    },
    showClusterForm: (state) => {
      return state.mapActionActive?.id === state.clusterActionId;
    },
    showEstateSearchForm: (state) => {
      return state.mapActionActive?.id === state.estateSearchActionId;
    },
    externalProjectPointDraw: (state) => {
      return state.drawFeatures.filter(
        (e) => e.properties.featureType === state.externalProjectFeatureType,
      );
    },
    clusterSubstationPointsDraw: (state) => {
      return state.drawFeatures.filter(
        (e) => e.properties.featureType === state.clusterSubstationFeatureType,
      );
    },
  },
  actions: {
    ...useBaseMapStore.actions,
    resetConfigs() {
      this.layerPanelConfig = cloneDeep(panelConfig);
      this.layerConfigs = cloneDeep(layers);
      this.activeLayerToggleSections = {};
    },
    /**
     * Sets the current active map action.
     * @param {string} actionId - The ID of the action to set as active.
     */
    setMapAction(actionId, draw) {
      try {
        // Reset the current map action if one is active
        if (this.mapActionActive) {
          this.resetMapAction(draw);
        }

        this.mapActionActive = this.mapActions[actionId];
      } catch (error) {
        console.error('Error setting map action:', error);
      }
    },
    /**
     * Resets the active map action and changes the draw mode to SIMPLE_SELECT.
     * @param {object} draw - Mapbox draw object instance.
     * @param {Boolean} setHideToast - hide toast if true.
     */
    resetMapAction(draw, setHideToast = true) {
      this.mapActionActive = null;
      draw.deleteAll();
      drawChangeMode(draw, MapboxDraw.constants.modes.SIMPLE_SELECT);
      this.estateIdsSelected = new Set();
      this.estatesSelectedArea = null;
      this.measureToolValue = null;
      this.clusterProjectIdsSelected = new Set();
      this.clusterExternalProjectIdsSelected = new Set();
      this.drawFeatures = [];
      getMarked().resetFeatureState();
      if (setHideToast) {
        useToastStore().hideToast();
      }
      // TODO: With store reset?
    },
    // initialize map actions here to use store actions as callback functions
    // passed to draw mode setup
    initializeMapActions() {
      this.mapActions = {
        project: {
          id: this.customPvProjectActionId,
          title: 'Projekt\nanlegen',
          icon: 'account_tree',
          hasPerm: {
            app: 'usecase_2',
            perm: 'add_analysis',
          },
          onActivate: () => {
            this.changeLayerVisibility({
              onLayers: [LAYER_KEY__ESTATE],
              active: true,
            });
          },
          buttons: [
            {
              drawMode: modes.DRAW_FEATURES_WITHIN,
              icon: 'highlight_alt',
              toolTip: 'Flurstücke auswählen',
              click: (draw, button) => {
                if (this.drawModeActive !== button.drawMode) {
                  drawChangeMode(draw, button.drawMode, {
                    // async action callback from store, always pass the feature
                    drawCreateCallback: this.handleEstatesWithin,
                  });
                } else {
                  drawChangeMode(
                    draw,
                    MapboxDraw.constants.modes.SIMPLE_SELECT,
                  );
                }
              },
            },
            {
              drawMode: modes.DRAW_SELECT_FEATURE,
              icon: 'ads_click',
              toolTip: 'Flurstücke auswählen',
              click: (draw, button) => {
                if (this.drawModeActive !== button.drawMode) {
                  drawChangeMode(draw, button.drawMode, {
                    // async action callback from store, always pass the feature
                    drawCreateCallback: this.handleEstatesSingleClick,
                    layerIds: [LAYER_KEY__ESTATE],
                  });
                } else {
                  drawChangeMode(
                    draw,
                    MapboxDraw.constants.modes.SIMPLE_SELECT,
                  );
                }
              },
            },
            {
              show: computed(
                () =>
                  this.estateIdsSelected.size > 0 ||
                  this.estatesSelectedArea !== null,
              ),
              icon: 'restart_alt',
              toolTip: 'Flürstücke zurücksetzen',
              click: () => {
                this.estateIdsSelected = new Set();
                this.estatesSelectedArea = null;
              },
            },
          ],
        },
        cluster: {
          id: this.clusterActionId,
          title: 'Projektcluster erstellen',
          icon: 'bubble_chart',
          onActivate: () => {
            this.changeLayerVisibility({
              onLayers: [LAYER_KEY__HIGH_VOLTAGE_GRID],
              active: true,
            });
          },
          buttons: [
            {
              drawMode: modes.DRAW_SELECT_FEATURE,
              icon: 'all_out',
              toolTip: 'Projekte auswählen',
              click: (draw, button) => {
                if (this.drawModeActive !== button.drawMode) {
                  drawChangeMode(draw, button.drawMode, {
                    drawCreateCallback: this.handleProjectSingleClick,
                    layerIds: [
                      LAYER_KEY__EXTERNAL_PROJECT,
                      LAYER_KEY__PV_PROJECT,
                    ],
                  });
                } else {
                  drawChangeMode(
                    draw,
                    MapboxDraw.constants.modes.SIMPLE_SELECT,
                  );
                }
              },
            },
            {
              drawMode: modes.DRAW_SET_POINT,
              icon: markRaw(IconTrafo),
              toolTip: 'Umspannwerk allokieren',
              click: (draw, button) => {
                draw.deleteAll();
                if (this.drawModeActive !== button.drawMode) {
                  drawChangeMode(draw, button.drawMode, {
                    featureType: this.clusterSubstationFeatureType,
                  });
                } else {
                  drawChangeMode(
                    draw,
                    MapboxDraw.constants.modes.SIMPLE_SELECT,
                  );
                }
              },
            },
          ],
        },
        searchEstate: {
          title: 'Flurstück suchen',
          icon: 'layers',
          id: this.estateSearchActionId,
        },
        externalProject: {
          title: 'Externes Projekt anlegen',
          icon: 'device_hub',
          id: this.externalProjectActionId,
          hasPerm: {
            app: 'usecase_2',
            perm: 'add_externalproject',
          },
          buttons: [
            {
              drawMode: modes.DRAW_SET_POINT,
              icon: 'ads_click',
              toolTip: 'Projekt allokieren',
              click: (draw, button) => {
                draw.deleteAll();
                if (this.drawModeActive !== button.drawMode) {
                  drawChangeMode(draw, button.drawMode, {
                    featureType: this.externalProjectFeatureType,
                  });
                } else {
                  drawChangeMode(
                    draw,
                    MapboxDraw.constants.modes.SIMPLE_SELECT,
                  );
                }
              },
            },
          ],
        },
        measuring: {
          id: this.measureToolActionId,
          title: 'Messwerkzeug',
          icon: 'design_services',
          buttons: [
            {
              drawMode: modes.DRAW_MEASURE_LINE,
              icon: 'straighten',
              toolTip: 'Länge messen',
              click: (draw, button) => {
                this.measureToolValue = null;
                draw.deleteAll();
                if (this.drawModeActive !== button.drawMode) {
                  drawChangeMode(draw, button.drawMode, {
                    drawCreateCallback: this.handleMeasureTool,
                  });
                } else {
                  drawChangeMode(
                    draw,
                    MapboxDraw.constants.modes.SIMPLE_SELECT,
                  );
                }
              },
            },
            {
              drawMode: modes.DRAW_MEASURE_POLYGON,
              icon: 'border_style',
              toolTip: 'Fläche messen',
              click: (draw, button) => {
                this.measureToolValue = null;
                draw.deleteAll();
                if (this.drawModeActive !== button.drawMode) {
                  drawChangeMode(draw, button.drawMode, {
                    drawCreateCallback: this.handleMeasureTool,
                  });
                } else {
                  drawChangeMode(
                    draw,
                    MapboxDraw.constants.modes.SIMPLE_SELECT,
                  );
                }
              },
            },
          ],
        },
      };
    },
    /**
     * Fetches the area for selected estates and updates `estatesSelectedArea` in the store.
     */
    async fetchAreaForEstates() {
      const areaData = await axios({
        method: 'POST',
        url: '/api/basemodule/estates-area/',
        data: { estateIds: Array.from(this.estateIdsSelected) },
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      this.estatesSelectedArea = areaData.data.area;
    },
    /**
     * Handles selection of estates within a polygon drawn on the map.
     * @param {object} polygon - GeoJSON object representing the selected polygon.
     */
    async handleEstatesWithin(polygon) {
      const estates = await axios({
        method: 'POST',
        url: '/api/basemodule/estates-within-polygon/',
        data: { polygon: polygon.geometry },
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      this.estateIdsSelected = new Set([
        ...this.estateIdsSelected,
        ...estates.data.estate_ids,
      ]);
      const features = getMap().queryRenderedFeatures({
        layers: [LAYER_KEY__ESTATE],
      });
      const intersection = features.filter((f) =>
        this.estateIdsSelected.has(f.properties.id),
      );
      getMarked().setFeatureState(intersection, true);
      await this.fetchAreaForEstates();
    },
    /**
     * Toggles the selection of an individual estate by ID.
     * @param {object} feature - The map feature representing the estate.
     */
    async handleEstatesSingleClick(feature) {
      const estateId = feature.properties.id;
      if (this.estateIdsSelected.has(estateId)) {
        this.estateIdsSelected.delete(estateId);
      } else {
        this.estateIdsSelected.add(estateId);
      }
      getMarked().reverseFeatureState(feature);
      await this.fetchAreaForEstates();
    },
    /**
     * sets battery option for selected projects in cluster and refetches power on change
     */
    changeClusterBatteryOption(withBattery) {
      this.clusterWithBattery = withBattery;
      if (this.clusterProjectIdsSelected.size > 0) {
        this.fetchPowerForSelectedProjects();
      }
    },
    /**
     * Fetches the area for selected estates and updates `estatesSelectedArea` in the store.
     */
    async fetchPowerForSelectedProjects() {
      const powerData = await axios({
        method: 'POST',
        url: '/api/usecase-2/aggregate-power-for-projects/',
        data: {
          project_ids: Array.from(this.clusterProjectIdsSelected),
          external_project_ids: Array.from(
            this.clusterExternalProjectIdsSelected,
          ),
          with_battery: this.clusterWithBattery,
        },
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      });
      this.clusterProjectSelectedPower = powerData.data.installed_power_kw;
    },
    async handleProjectSingleClick(feature) {
      const projectId = feature.properties.id;
      let ids = this.clusterProjectIdsSelected;
      if (feature.source === LAYER_KEY__EXTERNAL_PROJECT) {
        ids = this.clusterExternalProjectIdsSelected;
      }
      if (ids.has(projectId)) {
        ids.delete(projectId);
      } else {
        ids.add(projectId);
      }
      getMarked().reverseFeatureState(feature);
      await this.fetchPowerForSelectedProjects();
    },
    /**
     * Updates the measurement tool value.
     * @param {string} measureValue - The calculated measure value (length or area).
     */
    handleMeasureTool(measureValue) {
      this.measureToolValue = measureValue;
    },
    async fetchAndCacheExternalProjects() {
      if (!this.externalProjectsFetched) {
        await this.fetchExternalProjects();
        this.externalProjectsFetched = true;
      }
    },
    async fetchExternalProjects() {
      const data = await axios({
        method: 'GET',
        url: '/api/usecase-2/external-project-geo/',
      });
      setGeoJsonData(LAYER_KEY__EXTERNAL_PROJECT, data.data.results);
    },
    /**
     * Fetch default connection options for all pv projects and
     * set filter accordingly
     */
    async fetchAndFilterBatteryOption() {
      if (!this.selectedAnalysis) {
        console.warn('No analysis selected');
        return;
      }

      const analysisId = this.selectedAnalysis.id;
      const projectData = await axios({
        method: 'GET',
        url: `/api/usecase-2/pv-project-battery-option/${analysisId}/`,
      });
      const filterArray = ['any'];
      projectData.data.pv_projects.forEach((e) => {
        filterArray.push([
          'all',
          ['==', ['get', 'project_id'], e.id],
          ['==', ['get', 'battery'], e.with_battery],
        ]);
      });
      this.addFilter(
        [
          LAYER_KEY__PV_PROJECT_CONNECTION,
          LAYER_KEY__PV_PROJECT_GRID_CONNECTION_POINT,
          LAYER_KEY__PV_PROJECT_SUBSTATION_POINT,
          LAYER_KEY__PV_PROJECT_HV_CONNECTION_POINT,
        ],
        filterArray,
        'batteryOptionFilter',
      );
    },

    toggleFilterContainer(id = null) {
      // Check if the filter container is already open and the id is not the same and id is not null
      if (this.filterContainerOpen && this.activeFilterId !== id && id) {
        // If it's open and the ID changes, update the activeFilterId and do not toggle
        this.activeFilterId = id;
        return;
      }
      this.filterContainerOpen = !this.filterContainerOpen;
      // Set activeFilterId to false if the container is being closed
      this.activeFilterId = this.filterContainerOpen ? id : null;
    },
  },
});
