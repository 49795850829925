export const headers = [
  {
    title: 'Vertriebsgebietsbezeichnung',
    key: 'title',
    align: 'left',
  },
  {
    title: 'Erstellt von',
    key: 'created_by',
    align: 'left',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    align: 'center',
    isDate: true,
  },
  {
    title: 'Status Erstellung',
    key: 'progress',
    align: 'center',
  },
  {
    title: 'Status Vertriebsgebiet',
    key: 'status',
    align: 'center',
  },
  {
    title: '',
    key: 'functions',

    align: 'end',
    sortable: false,
  },
];
