export class SalesRegionDto {
  static BASE_URL = '/api/usecase-4/sales-region/';

  id = null;
  title = '';
  created_at = null;
  created_by = null;
  postal_codes = [];
  status = 'created';
}
