<template>
  <div
    v-if="isMounted"
    ref="tooltipWrapper"
    class="w-fit flex gap-1 items-center"
  >
    <slot />
    <IconWrapper
      v-if="!$slots.default"
      :icon="tooltipIcon"
      :fill="tooltipIconFill"
      type="round"
      class="cursor-pointer"
    />
    <VTooltip
      v-if="tooltipText || $slots.text"
      v-model="isTooltipOpen"
      :open-on-click="openOnClick"
      :open-on-hover="!openOnClick"
      :close-delay
      :offset
      activator="parent"
      :content-class="[
        {
          'rounded-[2px] bg-white standard-elevation-0-dark !min-w-fit':
            !cleanContent,
          'bg-transparent p-0': cleanContent,
          'pointer-events-auto': preventCloseOnHover,
        },
        customContentClass,
      ]"
    >
      <h6
        ref="tooltipContent"
        :style="{
          'max-width': cleanContent ? 'fit-content' : `${slotMaxWidth}px`,
        }"
        :class="{
          'whitespace-pre-line': !$slots.text,
          'whitespace-normal': $slots.text,
        }"
      >
        <template v-if="!$slots.text">{{ trimmedTooltipText }}</template>
        <slot v-else name="text" />
      </h6>
    </VTooltip>
  </div>
</template>
<script setup>
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { onClickOutside } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
  tooltipIcon: {
    type: [String, Object],
    default: 'info',
  },
  tooltipText: {
    type: [String, Boolean],
    default: null,
  },
  tooltipIconFill: {
    type: String,
    default: 'text-color1',
  },
  customContentClass: {
    type: String,
    default: '!p-2.5',
  },
  slotMaxWidth: {
    type: Number,
    default: 300,
  },
  cleanContent: {
    type: Boolean,
    default: false,
  },
  openOnClick: {
    type: Boolean,
    default: false,
  },
  closeDelay: {
    type: Number,
    default: 150,
  },
  offset: {
    type: Number,
    default: 10,
  },
  preventCloseOnHover: {
    type: Boolean,
    default: false,
  },
});

const tooltipWrapper = ref(null);
const tooltipContent = ref(null);
const isTooltipOpen = ref(false);

onClickOutside(tooltipWrapper, (event) => {
  if (
    tooltipContent.value !== null &&
    !tooltipContent.value.contains(event.target)
  ) {
    isTooltipOpen.value = false;
  }
});

const trimmedTooltipText = computed(() => props.tooltipText?.trim());

const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});
</script>
