export const headers = [
  {
    title: 'Projektbezeichnung',
    key: 'title',
    align: 'left',
  },
  {
    title: 'Erstellt von',
    key: 'created_by',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    isDate: true,
  },
  {
    title: 'Zieljahr',
    key: 'target_year',
    align: 'center',
  },
  {
    title: 'Status',
    key: 'progress',
    align: 'center',
    sortable: false,
  },
  {
    title: '',
    key: 'functions',
    align: 'end',
    width: '100px',
    sortable: false,
  },
];
