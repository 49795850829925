import {
  BUILDINGS,
  GENERATION_POTENTIAL,
  hexToRgba,
  HEAT_PUMP_BY_ENUM,
  AGE_GROUPS_BY_ENUM,
} from '@/configs/color-constants';
import {
  BUILDING_TYPE_LEGEND,
  BUILDING_TYPE_STYLING,
  CONSTRUCTION_YEAR_CLASS_LEGEND,
  CONSTRUCTION_YEAR_STYLING_UC4,
  CUSTOMER_AT_LEGEND,
  CUSTOMER_AT_STYLING,
  HEATING_TECHNOLOGY_LEGEND,
  powerLegendType,
  powerStyleType,
  SECTOR_LEGEND,
  SECTOR_STYLING,
  TECHNOLOGY_STYLING,
  HEAT_PUMP_STYLING,
  HEAT_PUMP_LEGEND,
} from './utils';
import { fillExtrusionClickEnabledStyle } from '@/apps/features/map/utils';

export const LAYER_KEY__BUILDINGS = 'building';

export default {
  [LAYER_KEY__BUILDINGS]: {
    layoutState: 'default',
    visible: true,
    layerConfig: {
      type: 'polygon',
      subType: 'fillExtrusion',
      id: LAYER_KEY__BUILDINGS,
      sourceLayer: LAYER_KEY__BUILDINGS,
      layerId: LAYER_KEY__BUILDINGS,
      visibility: 'visible',
      tilesDirectory: 'uc4',
    },
    sidebar: {
      name: 'Gebäude',
      subtitles: [
        'key',
        'street_with_number',
        'postal_code',
        'district_name',
        'municipality_name',
        'municipality_key',
        'default_supplier',
        'is_enbw_customer',
      ],
      chips: [
        {
          title: 'Gebäudestruktur',
          active: true,
          items: [
            {
              title: 'Gebäudeinformationen',
              active: true,
              items: [
                {
                  key: 'function',
                  unit: '',
                },
                {
                  key: 'iwu_type',
                  unit: '',
                },
                {
                  key: 'sector',
                  unit: '',
                },
                {
                  key: 'roof_type',
                  unit: '',
                },
                {
                  key: 'construction_method',
                  unit: '',
                },
                {
                  key: 'construction_year_class',
                  unit: '',
                  forceString: true,
                },
                {
                  key: 'height_m',
                  unit: 'm',
                },
                {
                  key: 'floor_count',
                  unit: '',
                },
                {
                  key: 'usable_area_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                {
                  key: 'existing_customer_at',
                  unit: '',
                },
                {
                  key: 'ownership_rate',
                  unit: '%',
                  decimal: 0,
                  multiply: 100,
                },
              ],
            },
          ],
        },
        {
          title: 'Nachfrage',
          active: false,
          items: [
            {
              title: 'Wärme',
              active: false,
              items: [
                {
                  key: 'heat_demand_kwh',
                  unit: 'kWh(th)/a',
                  decimal: 0,
                },
                {
                  key: 'specific_heat_demand_kwh_m2',
                  unit: 'kWh(th)/m²',
                  decimal: 0,
                },
                {
                  key: 'heat_demand_kw',
                  unit: 'kW(th)',
                  decimal: 0,
                },
                {
                  key: 'linear_heat_density_kwh_m',
                  unit: 'kWh(th)/am',
                  decimal: 0,
                },
                {
                  key: 'heating_type',
                  unit: '',
                  decimal: 0,
                },
                {
                  panelItemType: 'heatPumpPotentialIndicator',
                  key: 'heat_pump_potential',
                  unit: '',
                },
              ],
            },
          ],
        },
        {
          title: 'Erzeugung',
          active: false,
          items: [
            {
              title: 'PV-Dach (Potenzial)',
              active: true,
              items: [
                {
                  key: 'pv_roof_area_roof_available_m2',
                  unit: 'm²',
                  decimal: 0,
                },
                // {
                //   key: 'pv_roof_globalradiation_kwh_m2',
                //   unit: 'kWh/m²',
                //   decimal: 0,
                // },
                {
                  key: 'pv_roof_operating_hours_h_a',
                  unit: 'h/a',
                  decimal: 0,
                },
                {
                  key: 'pv_roof_potential_kw',
                  unit: 'kWp',
                  decimal: 0,
                },
                {
                  key: 'pv_roof_potential_kwh_a',
                  unit: 'kWh(el)/a',
                  decimal: 0,
                },
              ],
            },
          ],
        },
        {
          title: 'Demografie',
          active: false,
          items: [
            {
              title: 'Altersstruktur',
              active: true,
              items: [
                {
                  panelItemType: 'pieChart',
                  title: 'Altersstruktur in Zensus-Rasterzelle',
                  titleInside: 'Anteile in %',
                  key: 'age_groups',
                  valueFieldName: 'age_share',
                  seriesFieldName: 'age',
                  style: AGE_GROUPS_BY_ENUM,
                  hideSum: true,
                  unit: '%',
                  chartUnit: '%',
                  multiply: 100,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default: fillExtrusionClickEnabledStyle(['#888888']),
      sector: fillExtrusionClickEnabledStyle([SECTOR_STYLING()]),
      constructionYearClass: fillExtrusionClickEnabledStyle([
        CONSTRUCTION_YEAR_STYLING_UC4(),
      ]),
      buildingType: fillExtrusionClickEnabledStyle([BUILDING_TYPE_STYLING]),
      ownershipRate: fillExtrusionClickEnabledStyle([
        [
          'interpolate',
          ['linear'],
          ['get', `ownership_rate`],
          0,
          hexToRgba(BUILDINGS.OWNERSHIP_RATE_MIN_GRADIENT, 1),
          1,
          hexToRgba(BUILDINGS.OWNERSHIP_RATE_MAX_GRADIENT, 1),
        ],
      ]),
      customerAt: fillExtrusionClickEnabledStyle([CUSTOMER_AT_STYLING]),
      heating_technology: fillExtrusionClickEnabledStyle([
        TECHNOLOGY_STYLING(),
      ]),
      heat_demand_kwh_a: fillExtrusionClickEnabledStyle([
        [
          'interpolate',
          ['linear'],
          ['get', `heat_demand_kwh_a`],
          0,
          hexToRgba(BUILDINGS.HEAT_DEMAND_MIN_GRADIENT, 1),
          1000000,
          hexToRgba(BUILDINGS.HEAT_DEMAND_MAX_GRADIENT, 1),
        ],
      ]),
      heat_demand_kw: fillExtrusionClickEnabledStyle([
        [
          'interpolate',
          ['linear'],
          ['get', `heat_demand_kw`],
          0,
          hexToRgba(BUILDINGS.HEAT_DEMAND_MIN_GRADIENT, 1),
          1000,
          hexToRgba(BUILDINGS.HEAT_DEMAND_MAX_GRADIENT, 1),
        ],
      ]),
      heat_line_density_kwh_am: fillExtrusionClickEnabledStyle([
        [
          'interpolate',
          ['linear'],
          ['get', `linear_heat_density_kwh_m`],
          500,
          '#FCEFBD',
          1000,
          '#FEDE82',
          1500,
          '#EDAF00',
          2000,
          '#FE8F11',
          3000,
          '#FE7E58',
          4000,
          '#E2491B',
          5000,
          '#C73B12',
        ],
      ]),
      potentialPvRoofKw: fillExtrusionClickEnabledStyle(
        powerStyleType(
          'pv_roof_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 500, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      potentialHeatPump: fillExtrusionClickEnabledStyle([HEAT_PUMP_STYLING]),
    },
    legend: {
      default: {
        name: 'Gebäude',
        unit: '',
        items: [
          {
            color: BUILDINGS.BUILDING,
            name: 'Gebäude',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      sector: SECTOR_LEGEND('Gebäude nach Sektoren'),
      constructionYearClass: CONSTRUCTION_YEAR_CLASS_LEGEND(
        'Gebäude nach Baualtersklasse',
      ),
      customerAt: CUSTOMER_AT_LEGEND('Gebäude nach Kunden'),
      buildingType: BUILDING_TYPE_LEGEND('Gebäude nach Typ'),
      ownershipRate: {
        type: 'gradient',
        name: 'Gebäude nach Eigentümerquote',
        unit: '%',
        items: {
          min: {
            value: 0,
            color: BUILDINGS.OWNERSHIP_RATE_MIN_GRADIENT,
          },
          max: {
            value: 100,
            color: BUILDINGS.OWNERSHIP_RATE_MAX_GRADIENT,
          },
        },
      },
      heating_technology: HEATING_TECHNOLOGY_LEGEND(`Gebäude nach Heizträger`),
      heat_demand_kwh_a: {
        type: 'gradient',
        name: 'Gebäude nach Wärmebedarf',
        unit: 'kWh(th)/a',
        items: {
          min: {
            value: 0,
            color: BUILDINGS.HEAT_DEMAND_MIN_GRADIENT,
          },
          max: {
            value: 1000000,
            color: BUILDINGS.HEAT_DEMAND_MAX_GRADIENT,
          },
        },
      },
      heat_demand_kw: {
        type: 'gradient',
        name: 'Gebäude nach Wärmelast',
        unit: 'kW(th)',
        items: {
          min: {
            value: 0,
            color: BUILDINGS.HEAT_DEMAND_MIN_GRADIENT,
          },
          max: {
            value: 1000,
            color: BUILDINGS.HEAT_DEMAND_MAX_GRADIENT,
          },
        },
      },
      heat_line_density_kwh_am: {
        name: 'Gebäude nach Wärmeliniendichte in kWh(th)/am',
        unit: '',
        items: [
          {
            name: '0 bis 500',
            color: '#FCEFBD',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '501 bis 1000',
            color: '#FEDE82',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '1001 bis 1500',
            color: '#EDAF00',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '1501 bis 2000',
            color: '#FE8F11',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '2001 bis 3000',
            color: '#FE7E58',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: '3001 bis 4000',
            color: '#E2491B',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            name: 'über 5000',
            color: '#C73B12',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      potentialPvRoofKw: powerLegendType(
        'Gebäude nach PV-Dach Potenzial',
        'kW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 500, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      potentialHeatPump: HEAT_PUMP_LEGEND('Wärmepumpenampel'),
    },
  },
};
