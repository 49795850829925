import { LAYER_KEY__BUILDINGS } from '@/apps/usecase-4/map/layer-config/buildings';
import { LAYER_KEY__POSTAL_CODE } from '../layer-config/postal-code';
import { LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS } from '../layer-config/infrastructure';

const customAndAgreggationToggle = ({ onLayer, icon }) => ({
  itemClass: 'toggle',
  aggregation: {
    includeCustomToggle: {
      onLayer,
      icon,
    },
    includeAggregationToggle: {
      onLayers: LAYER_KEY__POSTAL_CODE,
    },
  },
});

const customToggle = ({ onLayer, icon }) => ({
  itemClass: 'toggle',
  aggregation: {
    includeCustomToggle: {
      onLayer,
      icon,
    },
  },
});

export const layerPanelConfig = {
  sections: [
    {
      title: 'Basisdaten',
      groups: [
        {
          title: 'Gebäudestruktur',
          id: 'buildingStructure',
          items: [
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Keine Einfärbung',
              layerState: 'default',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Sektor',
              layerState: 'sector',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Baualtersklasse',
              layerState: 'constructionYearClass',
            },
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Gebäudetyp',
              layerState: 'buildingType',
            },
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Eigentümerquote',
              layerState: 'ownershipRate',
            },
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Bestandskunde EnBW',
              layerState: 'customerAt',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'PV-Aufdachleistung',
              layerState: 'potentialPvRoofKw',
            },
          ],
        },
        {
          title: 'Grundversorger',
          id: 'baseSupplier',
          items: [
            {
              ...customToggle({
                onLayer: LAYER_KEY__POSTAL_CODE,
              }),
              title: 'EnBW/Sonstige',
              layerState: 'default_supplier',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Nachfrage',
      groups: [
        {
          title: 'Wärme',
          id: 'heat',
          items: [
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Wärmebedarf',
              layerState: 'heat_demand_kwh_a',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Wärmelast',
              layerState: 'heat_demand_kw',
            },
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Wärmeliniendichte',
              layerState: 'heat_line_density_kwh_am',
            },
            {
              ...customAndAgreggationToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Energieträger',
              layerState: 'heating_technology',
            },
            {
              ...customToggle({
                onLayer: LAYER_KEY__BUILDINGS,
                icon: 'maps_home_work',
              }),
              title: 'Wärmepumpen-Ampel',
              layerState: 'potentialHeatPump',
            },
          ],
        },
      ],
      show: true,
    },
    {
      title: 'Infrastruktur',
      groups: [
        {
          title: 'Wärme ',
          id: 'InfrastructureHeat',
          items: [
            {
              ...customToggle({
                onLayer: LAYER_KEY__HEAT_DENSITY_STREET_SEGMENTS,
                icon: 'shape_line',
              }),
              title: 'Wärmeliniendichte',
              layerState: 'default',
            },
          ],
        },
      ],
      show: true,
    },
  ],
};
