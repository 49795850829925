<template>
  <VuetifyDialog v-model="showFilter" title="Übersicht Filtereinstellungen">
    <template #content>
      <div class="space-y-6 max-w-[650px]">
        <!-- Title -->
        <div class="flex gap-2.5">
          <div
            class="bg-color1 text-white rounded-[4px] px-1.5 pt-[5px] pb-1 flex"
          >
            <span class="subtitle-2 pt-[1px]">{{ filterSet?.title }}</span>
          </div>

          <div
            v-if="filterSet?.is_default"
            class="bg-color1 text-white rounded px-1.5 pt-[5px] pb-1 flex"
          >
            <span class="subtitle-2 pt-[1px]">Standard</span>
          </div>
        </div>

        <!-- Bestandskunden -->
        <div>
          <h5 class="text-color1 mb-4">Bestandskunden</h5>
          <div class="standard-elevation-0-dark rounded-lg p-4">
            <div class="flex flex-wrap gap-2">
              <span
                v-for="customer in filterSet?.existing_customers"
                :key="customer"
                class="px-2 pb-1 pt-[5px] bg-subtle rounded body-3"
              >
                {{
                  filtersetOptions?.existing_customers?.child?.choices.find(
                    (c) => c.value === customer,
                  )?.display_name
                }}
              </span>
            </div>
          </div>
        </div>

        <!-- Building Attributes -->
        <div>
          <h5 class="text-color1 mb-2.5">Gebäudeattribute</h5>
          <table
            class="standard-elevation-0-dark rounded-lg overflow-hidden w-full"
          >
            <thead>
              <tr>
                <th class="text-left">
                  <h6 class="text-title-neutral p-2.5">Gebäudetyp</h6>
                </th>
                <th class="text-left">
                  <h6 class="text-title-neutral p-2.5">Gebäudealter</h6>
                </th>
                <th class="text-left">
                  <h6 class="text-title-neutral p-2.5">Sektor</h6>
                </th>
                <th class="text-left">
                  <h6 class="text-title-neutral p-2.5">Heiztechnologie</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  <div class="space-y-1">
                    <div
                      v-for="type in filterSet?.allowed_building_types"
                      :key="type"
                    >
                      {{
                        filtersetOptions?.allowed_building_types?.child?.choices.find(
                          (c) => c.value === type,
                        )?.display_name
                      }}
                    </div>
                  </div>
                </td>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  <div class="space-y-1">
                    <div
                      v-for="year in filterSet?.construction_year"
                      :key="year"
                    >
                      {{
                        filtersetOptions?.construction_year?.child?.choices.find(
                          (c) => c.value === year,
                        )?.display_name
                      }}
                    </div>
                  </div>
                </td>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  <div class="space-y-1">
                    <div
                      v-for="sector in filterSet?.allowed_sectors"
                      :key="sector"
                    >
                      {{
                        filtersetOptions?.allowed_sectors?.child?.choices.find(
                          (c) => c.value === sector,
                        )?.display_name
                      }}
                    </div>
                  </div>
                </td>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  <div class="space-y-1">
                    <div
                      v-for="type in filterSet?.allowed_heating_types"
                      :key="type"
                    >
                      {{
                        filtersetOptions?.allowed_heating_types?.child?.choices.find(
                          (c) => c.value === type,
                        )?.display_name
                      }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Heat Indicators -->
        <div>
          <h5 class="text-color1 mb-2.5">Wärmekennzahlen</h5>
          <!-- First table for Wärmepumpen-Ampel -->
          <table
            class="standard-elevation-0-dark rounded-lg overflow-hidden w-full mb-2.5"
          >
            <thead>
              <tr>
                <th class="text-left">
                  <h6 class="text-title-neutral p-2.5">Wärmepumpen-Ampel</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  <div class="flex gap-2">
                    <div
                      v-for="potential in filterSet?.allowed_heat_pump_potentials"
                      :key="potential"
                      class="px-2 pb-1 pt-[5px] rounded text-white"
                      :class="{
                        'bg-[#DC3545]': potential === 'red',
                        'bg-[#FFC107]': potential === 'yellow',
                        'bg-[#198754]': potential === 'green',
                        'bg-gray-400': potential === 'unk',
                      }"
                    >
                      {{
                        filtersetOptions?.allowed_heat_pump_potentials?.child?.choices.find(
                          (c) => c.value === potential,
                        )?.display_name
                      }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Second table for other heat indicators -->
          <table
            v-if="haveHeatIndicatorsAnyRange()"
            class="standard-elevation-0-dark rounded-lg overflow-hidden w-full"
          >
            <thead>
              <tr>
                <template
                  v-for="range in heatIndicatorRanges"
                  :key="range.header"
                >
                  <th
                    v-if="
                      hasValidRange(
                        filterSet?.[range.minKey],
                        filterSet?.[range.maxKey],
                      )
                    "
                    class="text-left w-1/3"
                  >
                    <h6 class="text-title-neutral p-2.5">{{ range.header }}</h6>
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr>
                <template
                  v-for="range in heatIndicatorRanges"
                  :key="range.header"
                >
                  <td
                    v-if="
                      hasValidRange(
                        filterSet?.[range.minKey],
                        filterSet?.[range.maxKey],
                      )
                    "
                    class="body-3 text-core-dark p-2.5 bg-subtle align-top"
                  >
                    <template
                      v-if="
                        filterSet?.[range.minKey] && filterSet?.[range.maxKey]
                      "
                    >
                      {{ toLocaleStr(filterSet[range.minKey]) }}
                      -
                      {{ toLocaleStr(filterSet[range.maxKey]) }}
                    </template>
                    <template v-else-if="filterSet?.[range.minKey]">
                      &gt;{{ toLocaleStr(filterSet[range.minKey]) }}
                    </template>
                    <template v-else-if="filterSet?.[range.maxKey]">
                      0 - {{ toLocaleStr(filterSet[range.maxKey]) }}
                    </template>
                    {{ range.unit }}
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Photovoltaik -->
        <div
          v-if="
            hasValidRange(
              filterSet?.min_pv_roof_potential_kw,
              filterSet?.max_pv_roof_potential_kw,
            )
          "
        >
          <h5 class="text-color1 mb-2.5">Photovoltaik</h5>
          <table
            class="standard-elevation-0-dark rounded-lg overflow-hidden w-full"
          >
            <thead>
              <tr>
                <!-- <th class="text-left w-1/2">
                  <h6 class="text-title-neutral p-2.5">Verfügbarkeit</h6>
                </th> -->
                <th class="text-left w-1/2">
                  <h6 class="text-title-neutral p-2.5">Aufdachpotenzial</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <!-- <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  {{ filterSet?.pv_plant_is_available ? 'Ja' : 'Nein' }}
                </td> -->
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  {{ toLocaleStr(filterSet?.min_pv_roof_potential_kw || 0) }}
                  -
                  {{ toLocaleStr(filterSet?.max_pv_roof_potential_kw || 0) }} kW
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Demographics -->
        <div
          v-if="filterSet?.min_age || filterSet?.max_age || filterSet?.gender"
        >
          <h5 class="text-color1 mb-2.5">Demografie</h5>
          <!-- First table for Age -->
          <table
            v-if="filterSet?.min_age || filterSet?.max_age"
            class="standard-elevation-0-dark rounded-lg overflow-hidden w-full mb-2.5"
          >
            <thead>
              <tr>
                <th class="text-left w-1/2">
                  <h6 class="text-title-neutral p-2.5">Alter</h6>
                </th>
                <th class="text-left w-1/2">
                  <h6 class="text-title-neutral p-2.5">Mindestanteil</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  {{
                    filterSet?.min_age && filterSet?.max_age
                      ? `${filterSet.min_age} - ${filterSet.max_age}`
                      : filterSet?.min_age
                        ? `>${filterSet.min_age}`
                        : filterSet?.max_age
                          ? `<${filterSet.max_age}`
                          : '-'
                  }}
                </td>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  {{ toLocaleStr(filterSet?.min_age_share * 100 || 0) + '%' }}
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Second table for Gender -->
          <table
            v-if="filterSet?.gender"
            class="standard-elevation-0-dark rounded-lg overflow-hidden w-full"
          >
            <thead>
              <tr>
                <th class="text-left w-1/2">
                  <h6 class="text-title-neutral p-2.5">Geschlecht</h6>
                </th>
                <th class="text-left w-1/2">
                  <h6 class="text-title-neutral p-2.5">Mindestanteil</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  {{
                    filtersetOptions?.gender?.choices?.find(
                      (c) => c.value === filterSet?.gender,
                    )?.display_name || '-'
                  }}
                </td>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  {{
                    toLocaleStr(filterSet?.min_gender_share * 100 || 0) + '%'
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Sinus Milieus -->
        <!-- <div>
          <h5 class="text-color1 mb-2.5">Sinus-Milieus</h5>
          <div class="standard-elevation-0-dark rounded-lg p-4">
            <div class="space-y-2">
              <h6 class="text-title-neutral">Leitmilieus</h6>
              <div class="space-y-1 body-3 text-core-dark">
                <div
                  v-for="milieu in filterSet?.allowed_sinus_milieus"
                  :key="milieu"
                >
                  {{
                    filtersetOptions?.allowed_sinus_milieus?.child?.choices.find(
                      (c) => c.value === milieu,
                    )?.display_name
                  }}
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- Grundversorger -->
        <div>
          <h5 class="text-color1 mb-2.5">Grundversorger</h5>
          <table
            class="standard-elevation-0-dark rounded-lg overflow-hidden w-full"
          >
            <thead>
              <tr>
                <th class="text-left w-1/2">
                  <h6 class="text-title-neutral p-2.5">Art</h6>
                </th>
                <!-- <th class="text-left w-1/2">
                  <h6 class="text-title-neutral p-2.5">Marktanteil</h6>
                </th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  <div class="space-y-1">
                    <div
                      v-for="supplier in filterSet?.allowed_default_suppliers"
                      :key="supplier"
                    >
                      {{
                        filtersetOptions?.allowed_default_suppliers?.child?.choices.find(
                          (c) => c.value === supplier,
                        )?.display_name
                      }}
                    </div>
                  </div>
                </td>
                <!-- <td class="body-3 text-core-dark p-2.5 bg-subtle align-top">
                  {{
                    toLocaleStr(filterSet?.min_market_share_enbw * 100 || 0) +
                    '%'
                  }}
                  -
                  {{
                    toLocaleStr(filterSet?.max_market_share_enbw * 100 || 0) +
                    '%'
                  }}
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </VuetifyDialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import { EvaluationFiltersetDto } from '../components/evaluation-filterset-dto';
import { restGetOrFetchMetaData } from '@/utils/rest-utils';
import { useVModel } from '@vueuse/core';
import { toLocaleStr } from '@/utils/formatUtils';

interface Choice {
  value: string | number;
  display_name: string;
}

interface FilterOption {
  type: string;
  required: boolean;
  read_only: boolean;
  label: string;
  unit: string | null;
  child?: {
    type: string;
    required: boolean;
    read_only: boolean;
    label: string;
    choices: Choice[];
  };
  choices?: Choice[];
}

interface FiltersetOptions {
  [key: string]: FilterOption;
}

const props = defineProps<{
  modelValue: boolean;
  filterSet: Record<string, any> | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const showFilter = useVModel(props, 'modelValue', emit);

const filtersetOptions = ref<FiltersetOptions>(
  await restGetOrFetchMetaData(EvaluationFiltersetDto),
);

const heatIndicatorRanges = [
  {
    header: 'Wärmebedarf',
    minKey: 'min_heat_demand_kwh_a',
    maxKey: 'max_heat_demand_kwh_a',
    unit: 'kWh/a',
  },
  {
    header: 'Wärmelast',
    minKey: 'min_heat_demand_kw',
    maxKey: 'max_heat_demand_kw',
    unit: 'kW',
  },
  {
    header: 'Wärmeliniendichte',
    minKey: 'min_linear_heat_density_kwh_m',
    maxKey: 'max_linear_heat_density_kwh_m',
    unit: 'kWh/m',
  },
];

function hasValidRange(min: number, max: number) {
  // If both values exist (including zero for min), it's a valid range
  if (min !== null && min !== undefined && max !== null && max !== undefined) {
    return true;
  }
  // For single values, zero is not considered valid
  return (
    (min !== null && min !== undefined && min !== 0) ||
    (max !== null && max !== undefined && max !== 0)
  );
}

function haveHeatIndicatorsAnyRange() {
  return heatIndicatorRanges.some((range) =>
    hasValidRange(
      props.filterSet?.[range.minKey],
      props.filterSet?.[range.maxKey],
    ),
  );
}
</script>

<style scoped>
table {
  table-layout: fixed;
}
</style>
