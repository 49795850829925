<template>
  <TopHeaderBar>
    <InputSearch
      class="w-[565px]"
      placeholder="Nach Projektname und Ersteller:in suchen"
      :search-keys="['title', 'created_by']"
      :items="projects"
      @update:search="filteredProjects = $event"
    />

    <ButtonEl
      v-if="userStore.uc1CanAddProject"
      icon="add"
      text="Neues Projekt"
      @click="router.push({ name: 'UseCase1ProjectCreate' })"
    />
  </TopHeaderBar>

  <VuetifyDataTable
    :item-data="filteredProjects"
    :headers="ProjectListHeaders"
    style="min-height: calc(100vh - 290px)"
  >
    <template #title="{ item }">
      <div class="flex align-center gap-5">
        <img
          :src="`${item.thumbnail ? `/api/usecase-1/${item.thumbnail}` : placeholderImage}`"
          class="cursor-pointer h-[58px] w-[50px] py-1 object-contain hover:shadow-lg"
          :class="{ 'pointer-events-none': !userStore.uc1CanChangeProject }"
          alt="Wappen"
          @click="toggleImageSwitchModal(item)"
        />
        <div class="flex flex-col gap-3 pr-5 pl-0">
          <h5>{{ item.title }}</h5>
          <span class="body-3"
            >{{ formatTableItemsText(item, 'municipalities') }}
            <br />
            {{ formatTableItemsText(item, 'counties') }}
          </span>
        </div>
      </div>
    </template>
    <template #target_year="{ item }">
      <div class="pr-[18px]">
        {{ item.target_year }}
      </div>
    </template>
    <template #progress="{ item }">
      <div class="flex justify-center items-center">
        <IconWrapper
          v-if="
            item.count_scenarios > 0 &&
            [0, 2].some((el) => progressByProject[item.id].has(el))
          "
          :icon="
            progressByProject[item.id].has(0)
              ? 'task_alt'
              : IconTaskProgressSharp
          "
          :fill="
            progressByProject[item.id].has(0)
              ? 'text-spot-success'
              : 'text-spot-warning'
          "
        />
        <ToolTip v-else tooltip-text="Bitte mind. 1 valides Szenario erstellen">
          <IconWrapper
            class="cursor-pointer"
            icon="error_outline"
            fill="text-red-600"
          />
        </ToolTip>
      </div>
    </template>
    <template #functions="{ item }">
      <div class="flex justify-start items-center gap-4">
        <IconWrapper
          v-if="item.count_scenarios > 0 && userStore.uc1CanChangeProject"
          type="round"
          :size="32"
          fill="text-core-color1"
          hover="hover:text-button-primary-hover-color1"
          class="cursor-pointer"
          icon="refresh"
          :disabled="progressByProject[item.id].has(2)"
          @click="refreshProjectScenarios(item.id)"
        />
        <DeleteDialog
          v-if="userStore.uc1CanDeleteProject"
          :id="item.id"
          :name-deleted-item="item.title"
          :dto="ProjectDto"
          :disabled="progressByProject[item.id].has(2)"
          @instance-removed="fetchProjects"
        >
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="delete_forever"
            class="cursor-pointer"
            type="round"
          />
        </DeleteDialog>
        <template v-if="item.count_scenarios > 0">
          <IconWrapper
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="map"
            :disabled="!progressByProject[item.id].has(0)"
            class="cursor-pointer"
            type="round"
            @click="navigateToMap(item.id, item.default_scenario)"
          />
          <IconWrapper
            :size="32"
            type="round"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="arrow_forward"
            :disabled="!progressByProject[item.id].has(0)"
            class="cursor-pointer"
            @click="navigateToDetail(item.id, item.default_scenario)"
          />
        </template>
      </div>
    </template>

    <template #expanded-row="{ item }">
      <div class="flex flex-col gap-2.5 py-2.5 -mx-2">
        <h5 v-if="item.count_scenarios > 0" class="text-dark pt-8 pb-3 pl-4">
          Übersicht Szenarien
        </h5>
        <ScenarioList
          :scenarios="scenarios.filter((e) => e.project === item.id)"
          :default-scenario-id="item.default_scenario"
          :default-scenario-loading
          :project-id="item.id"
          @set-default-scenario="setDefaultScenario($event, item)"
          @instance-removed="fetchProjectsAndScenarios"
        />
        <ButtonEl
          v-if="userStore.uc1CanAddScenario"
          icon="add"
          text="Neues Szenario"
          color="color2"
          class="ml-auto mr-2.5"
          @click="createScenarioDialog = true"
        />
      </div>
      <VuetifyDialog
        v-model="createScenarioDialog"
        title="Neues Szenario erstellen"
        width="960px"
        :persistent="true"
      >
        <template #content>
          <ScenarioCreate
            :project-id="item.id"
            :scenarios
            @abort="createScenarioDialog = false"
            @instance-created="scenarioCreated($event, item)"
          />
        </template>
      </VuetifyDialog>
    </template>
  </VuetifyDataTable>

  <VuetifyDialog
    v-if="showImageSwitchModal"
    v-model="showImageSwitchModal"
    :title="`${editItem.title}: Wappen ändern`"
  >
    <template #content>
      <FileInputMask
        allowed-file-size="1 MB"
        :allowed-file-types="['png', 'jpeg', 'jpg', 'bmp']"
        @files-selected="newThumbnail = $event[0]"
      />
    </template>
    <template #actions>
      <ButtonEl
        text="Abbrechen"
        color="color2"
        @click="toggleImageSwitchModal(null, false)"
      />
      <ButtonEl
        text="Speichern"
        :disabled="!newThumbnail"
        @click="saveNewImage"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { useUsers } from '@/apps/auth/users-store';
import { ProjectDto } from '@/apps/usecase-1/projects/project-dto';
import { headers as ProjectListHeaders } from '@/apps/usecase-1/projects/project-list-headers';
import { useProjectStore } from '@/apps/usecase-1/projects/project-store';
import ScenarioCreate from '@/apps/usecase-1/scenario/ScenarioCreate.vue';
import ScenarioList from '@/apps/usecase-1/scenario/ScenarioList.vue';
import IconTaskProgressSharp from '@/assets/icons/custom/misc/IconTaskProgressSharp.vue';
import placeholderImage from '@/assets/svg/coat-of-arms-placeholder.svg';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import FileInputMask from '@/components/FileInputMask/FileInputMask.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import axios from '@/utils/axiosHelper';
import { restPatch } from '@/utils/rest-utils';
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
  watchEffect,
} from 'vue';
import cookie from 'vue-cookies';
import { useRouter } from 'vue-router';

const router = useRouter();

const projectStore = useProjectStore();
const userStore = useUsers();

const { projects, scenarios } = toRefs(projectStore);
const { fetchProjects, fetchScenarios, fetchProjectsAndScenarios } =
  projectStore;

await fetchProjects();
await fetchScenarios();

const filteredProjects = ref(projects.value);

// ------------- IMAGE UPLOAD
const showImageSwitchModal = ref(false);
const editItem = ref(null);
const newThumbnail = ref(null);

function toggleImageSwitchModal(project, open = true) {
  if (open) {
    editItem.value = project;
    showImageSwitchModal.value = true;
  } else {
    showImageSwitchModal.value = false;
    editItem.value = null;
  }
}

async function saveNewImage() {
  const formData = new FormData();
  formData.append('thumbnail', newThumbnail.value);

  await axios({
    method: 'POST',
    url: `/api/usecase-1/project_thumbnail_create/${editItem.value.id}/`,
    data: formData,
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    fetchProjects();
    toggleImageSwitchModal(null, false);
  });
}

watchEffect(() => {
  filteredProjects.value = projects.value;
});

// ------------ Interval reference
const interval = ref();

function startFetchingScenarios() {
  if (!interval.value) {
    interval.value = setInterval(() => {
      fetchScenarios();
    }, 5000);
  }
}

function stopFetchingScenarios() {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
}
// ---------- Operations
onMounted(() => {
  startFetchingScenarios();
});

onUnmounted(() => {
  stopFetchingScenarios();
});

const createScenarioDialog = ref(false);
const defaultScenarioLoading = ref(false);

function setDefaultScenario(scenarioId, project) {
  defaultScenarioLoading.value = true;
  restPatch(ProjectDto, project.id, { default_scenario: scenarioId }).then(
    () => {
      fetchProjects().then(() => {
        defaultScenarioLoading.value = false;
      });
    },
  );
}

async function scenarioCreated(scenarioId, project) {
  await fetchProjectsAndScenarios();
  // set default scenario if first scenario
  if (project.default_scenario === null) {
    setDefaultScenario(scenarioId, project);
  }
}

function navigateToDetail(projectId, scenarioId) {
  router.push({
    name: 'UseCase1ProjectResultOverview',
    params: { projectId, scenarioId },
  });
}

function refreshProjectScenarios(projectId) {
  axios({
    method: 'POST',
    url: '/api/usecase-1/refresh-project-scenarios/',
    data: { id: projectId },
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    fetchScenarios();
  });
}

// computed property that returns all progresses of all projects as a set
const progressByProject = computed(() => {
  const projectsProgress = {};
  for (const project of projects.value) {
    projectsProgress[project.id] = new Set();
  }
  for (const scenario of scenarios.value) {
    if (typeof projectsProgress[scenario.project] === 'undefined') {
      projectsProgress[scenario.project] = new Set();
    }
    projectsProgress[scenario.project].add(scenario.progress);
  }
  return projectsProgress;
});

function navigateToMap(projectId, scenarioId) {
  router.push({
    name: 'mapView1',
    params: { projectId, scenarioId },
  });
}

function formatTableItemsText(item, type) {
  const count =
    type === 'municipalities' ? item.count_municipalities : item.count_counties;
  const label = type === 'municipalities' ? 'Gemeinde' : 'Landkreis';
  const pluralLabel = type === 'municipalities' ? 'Gemeinden' : 'Landkreise';

  return `${count.toLocaleString('de-DE')} ${count > 1 ? pluralLabel : label}`;
}
</script>
