export class EvaluationFiltersetDto {
  static BASE_URL = '/api/usecase-4/filterset/';

  id = null;
  title = null;
  is_default = false;
  existing_customers = [];
  feedback = [];
  allowed_building_types = [];
  construction_year = [];
  allowed_heating_types = [];
  allowed_roof_types = [];
  min_usable_area_m2 = 0.0;
  max_usable_area_m2 = null;
  min_floor_count = 0;
  max_floor_count = null;
  min_ownership_rate = 0.0;
  max_ownership_rate = 1.0;
  allowed_heat_pump_potentials = [];
  min_heat_demand_kwh_a = 0.0;
  max_heat_demand_kwh_a = null;
  min_heat_demand_kw = 0.0;
  max_heat_demand_kw = null;
  min_linear_heat_density_kwh_m = 0.0;
  max_linear_heat_density_kwh_m = null;
  pv_plant_is_available = false;
  min_pv_roof_potential_kw = 0.0;
  max_pv_roof_potential_kw = null;
  battery_is_available = false;
  electric_mobility_is_available = false;
  min_age = 0;
  max_age = null;
  min_age_share = 0.0;
  min_gender_share = 0.0;
  gender = null;
  allowed_sinus_milieus = [];
  allowed_default_suppliers = [];
  min_market_share_enbw = 0.0;
  max_market_share_enbw = 1.0;
}
