export class LeadListDto {
  static BASE_URL = '/api/usecase-4/leadlist/';

  id = null;
  created_by = null;
  title = '';
  description = '';
  created_at = null;
  state = null;
  sales_region = null;
  filterset = null;
  downloadable_file = null;
}
