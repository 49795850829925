import { defineStore } from 'pinia';
import { restFetchAll } from '@/utils/rest-utils';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';
import { AssignedPostalCodeDto } from '@/apps/usecase-4/sales-regions/assigned-postal-code-dto';

export const useSalesRegionsStore = defineStore('salesRegions', {
  state: () => ({
    salesRegions: [],
    assignedPostalCodes: [],
  }),
  actions: {
    async fetchSalesRegions() {
      try {
        this.salesRegions = await restFetchAll(SalesRegionDto);
      } catch (error) {
        console.error('Error fetching sales regions:', error);
        throw error;
      }
    },
    async fetchAssignedPostalCodes() {
      try {
        this.assignedPostalCodes = await restFetchAll(AssignedPostalCodeDto);
      } catch (error) {
        console.error('Error fetching postal codes with sales regions:', error);
        throw error;
      }
    },
  },
});
