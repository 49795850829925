import IconUrbaneStandorte from '@/assets/icons/custom/misc/IconUrbaneStandorte.vue';
import IconDevelopment from '@/assets/icons/custom/misc/IconDevelopment.vue';
import IconInfrastrukturErzeugung from '@/assets/icons/custom/misc/IconInfrastrukturErzeugung.vue';
import IconEnergieEffizienz from '@/assets/icons/custom/misc/IconEnergieEffizienz.vue';
import IconInfrastrukturSystem from '@/assets/icons/custom/misc/IconInfrastrukturSystem.vue';
import IconFlaechendeckendesLadenetz from '@/assets/icons/custom/misc/IconFlaechendeckendesLadenetz.vue';
import IconSmartMobilityPortal from '@/assets/icons/custom/misc/IconSmartMobilityPortal.vue';
import IconPresse from '@/assets/icons/custom/misc/IconPresse.vue';
import { LAYER_KEY__MUNICIPALITY } from '@/apps/usecase-1/map/layer-config/geographical-level';
import {
  LAYER_KEY__POTENTIAL_PV_FREE,
  LAYER_KEY__POTENTIAL_WIND,
} from '../../map/layer-config/potential';
import { LAYER_KEY__BUILDINGS } from '../../map/layer-config/buildings';
import {
  LAYER_KEY__GRID_STATIONS_SUBSTATION,
  LAYER_KEY__SEARCH_AREA_STATIONS,
  LAYER_KEY__HIGH_VOLTAGE_GRID,
} from '@/apps/features/map/layer-config/base-data';
import {
  LAYER_KEY__GENERATION_PV_FREE,
  LAYER_KEY__GENERATION_WIND,
  LAYER_KEY__GENERATION_HYDROPOWER,
  LAYER_KEY__GENERATION_BIOMASS,
} from '@/apps/usecase-1/map/layer-config/existing-power-plants';

export const ProjectDetailTabs = [
  {
    key: 'overview',
    name: 'Übersicht\nProjektgebiet',
    subUrl: 'overview',
    hasExtraRouterParameter: true,
    color: '#E2491B',
    icon: IconUrbaneStandorte,
    mapLayerConfig: [
      {
        layer: LAYER_KEY__MUNICIPALITY,
        state: 'default',
      },
      {
        layer: LAYER_KEY__BUILDINGS,
        state: 'constructionYearClass',
      },
    ],
    aggregationLevel: LAYER_KEY__MUNICIPALITY,
  },
  {
    key: 'demand-generation',
    name: 'Bestandsanalyse & Nachfrage\u00ADentwicklung',
    subUrl: 'demand-generation',
    hasExtraRouterParameter: true,
    color: '#FE8F11',
    icon: IconDevelopment,
    mapLayerConfig: [
      {
        layer: LAYER_KEY__MUNICIPALITY,
        state: 'electricity_demand_total_kwh_a',
      },
    ],
    aggregationLevel: LAYER_KEY__MUNICIPALITY,
  },
  {
    key: 'renewable-generation',
    name: 'EE-Erzeugung',
    subUrl: 'renewable-generation',
    hasExtraRouterParameter: true,
    color: '#AD8653',
    icon: IconInfrastrukturErzeugung,
    mapLayerConfig: [
      { layer: LAYER_KEY__GENERATION_WIND, state: 'default' },
      { layer: LAYER_KEY__GENERATION_HYDROPOWER, state: 'default' },
      { layer: LAYER_KEY__GENERATION_PV_FREE, state: 'default' },
      { layer: LAYER_KEY__GENERATION_BIOMASS, state: 'default' },
      { layer: LAYER_KEY__POTENTIAL_WIND, state: 'default' },
      { layer: LAYER_KEY__POTENTIAL_PV_FREE, state: 'default' },
      { layer: LAYER_KEY__BUILDINGS, state: 'potentialPvRoofKw' },
    ],
    aggregationLevel: null,
  },
  {
    key: 'area-evaluation',
    name: 'Gebiets\u00ADauswertung',
    subUrl: 'area-evaluation',
    hasExtraRouterParameter: true,
    color: '#70A638',
    icon: IconFlaechendeckendesLadenetz,
    mapLayerConfig: [
      {
        layer: LAYER_KEY__MUNICIPALITY,
        state: 'electricity_demand_total_kwh_a',
      },
    ],
    aggregationLevel: LAYER_KEY__MUNICIPALITY,
  },
  {
    key: 'scenario-comparison',
    name: 'Szenarien\u00ADvergleich',
    subUrl: 'scenario-comparison',
    hasExtraRouterParameter: true,
    color: '#119C8D',
    icon: IconSmartMobilityPortal,
    mapLayerConfig: [
      {
        layer: LAYER_KEY__MUNICIPALITY,
        state: 'electricity_demand_total_kwh_a',
      },
    ],
    aggregationLevel: LAYER_KEY__MUNICIPALITY,
  },
  {
    key: 'power-balance',
    name: 'Mengen- und Leistungsbilanz',
    subUrl: 'power-balance',
    hasExtraRouterParameter: true,
    color: '#077B6F',
    icon: IconEnergieEffizienz,
    mapLayerConfig: [
      {
        layer: LAYER_KEY__MUNICIPALITY,
        state: 'electricity_demand_total_kwh_a',
      },
    ],
    aggregationLevel: LAYER_KEY__MUNICIPALITY,
  },
  {
    key: 'grid-planning',
    name: 'Netzplanung',
    subUrl: 'grid-planning',
    hasExtraRouterParameter: true,
    color: '#0059B9',
    icon: IconInfrastrukturSystem,
    mapLayerConfig: [
      {
        layer: LAYER_KEY__MUNICIPALITY,
        state: 'electricity_demand_total_kw',
      },
      {
        layer: LAYER_KEY__GRID_STATIONS_SUBSTATION,
        state: 'default',
      },
      {
        layer: LAYER_KEY__SEARCH_AREA_STATIONS,
        state: 'default',
      },
      {
        layer: LAYER_KEY__HIGH_VOLTAGE_GRID,
        state: 'default',
      },
    ],
    aggregationLevel: LAYER_KEY__MUNICIPALITY,
  },
  {
    key: 'project-reports',
    name: 'Berichte',
    subUrl: 'project-reports',
    hasExtraRouterParameter: true,
    color: '#000099',
    icon: IconPresse,
    requiresPerm: {
      app: 'usecase_1',
      permission: 'export_data_usecase_1',
    },
  },
];
