export class ProjectFiltersetDto {
  static BASE_URL = '/api/usecase-2/filterset-project/';

  id = null;
  min_area_m2 = 0;
  max_area_m2 = null;
  min_power_kw = 0;
  max_power_kw = null;
  min_battery_capacity_kw = 0;
  max_battery_capacity_kw = null;
  name = null;
  is_default = false;
  min_connection_length_m = 0;
  max_connection_length_m = null;
  min_bbox_width_m = 0;
  max_bbox_width_m = null;
  min_share_potential_area_in_bbox_percent = 0;
  max_share_potential_area_in_bbox_percent = 1;
  min_utility_value = 0;
  max_utility_value = 5;
  allowed_voltage_levels = [0, 1];
  only_favorites = false;
  is_manual = null;
  is_topdown = null;
  is_quickcheck = null;
  only_sufficient_capacity = false;
  allowed_review_states = ['TODO', 'YES', 'NO'];
  analysis = null;
  municipality = null;
}
