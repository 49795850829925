import { ref, watch } from 'vue';
import { useBaseStore } from '@/apps/basemodule/base-store';

export function useSyncGeoMapMunicipalitySelection(
  preSelectedMunicipalities = new Set(),
  filterGemeindeFrei = false,
) {
  const gemeindeFrei = new Set(['08317971', '08415971']);
  const baseStore = useBaseStore();
  const selected = ref([]);
  const municipalitySelectItems = ref([]);
  const municipalities = ref([]);
  baseStore.fetchSimplifiedMuniData().then(populateMunicipalities);

  function setSelected(municipalityId) {
    selected.value.push(municipalityId);
    toggleMunicipalitySelectItem(municipalityId);
  }

  function setUnSelected(municipalityId) {
    const index = selected.value.findIndex((e) => e === municipalityId);
    selected.value.splice(index, 1);
    toggleMunicipalitySelectItem(municipalityId);
  }

  function toggleMunicipalitySelectItem(municipalityId) {
    const counties = municipalitySelectItems.value[0];
    const selectedSet = new Set(selected.value);
    for (const county of counties.items) {
      for (const municipality of county.items) {
        if (municipality.value === municipalityId.toString()) {
          municipality.checked = !municipality.checked;
          if (municipality.checked) selectedSet.add(municipalityId);
          else selectedSet.delete(municipalityId);
        }
      }
    }
    selected.value = Array.from(selectedSet);
  }

  function populateMunicipalities(muniResponse) {
    const multiSelectContent = {
      name: 'Alle Gemeinden',
      value: 'all',
      items: [],
    };
    const processedCountyIds = new Set();
    const countyItemsMap = new Map();

    for (const municipality of muniResponse) {
      // Filter gemeindefreie Gebiete
      if (filterGemeindeFrei && gemeindeFrei.has(municipality.key)) {
        continue;
      }
      const countyId = municipality.county_id;
      const countyName = municipality.county_name;
      municipalities.value.push({
        isSelectable: true,
        key: municipality.key,
        title: municipality.name,
        id: municipality.id.toString(),
        name: municipality.id.toString(),
        geometry_json: municipality.geometry_json,
      });

      if (!processedCountyIds.has(countyId)) {
        processedCountyIds.add(countyId);

        const newCountyItem = {
          name: countyName,
          value: countyId,
          expanded: false,
          checked: false,
          items: [],
        };
        multiSelectContent.items.push(newCountyItem);
        countyItemsMap.set(countyId, newCountyItem.items);
      }
      countyItemsMap.get(countyId).push({
        name: municipality.name,
        value: municipality.id.toString(),
        checked: preSelectedMunicipalities.has(municipality.id.toString()),
      });
    }
    municipalitySelectItems.value.push(multiSelectContent);
  }

  watch(
    municipalitySelectItems,
    (newVal) => {
      const counties = newVal[0];
      const selectedSet = new Set(selected.value);
      for (const county of counties.items) {
        for (const municipality of county.items) {
          if (municipality.checked) selectedSet.add(municipality.value);
          else selectedSet.delete(municipality.value);
        }
      }
      selected.value = Array.from(selectedSet);
    },
    { deep: true },
  );

  return {
    setSelected,
    setUnSelected,
    selected,
    municipalitySelectItems,
    municipalities,
  };
}
