export class ProjectDto {
  static BASE_URL = '/api/usecase-1/project/';
  static NAMES_URL = '/api/usecase-1/project/names/';

  id = null;
  title = '';
  base_year = 2024;
  target_year = 2050;
  municipalities = [];
  created_at = null;
  created_by = null;
  default_scenario = null;
  thumbnail = null;
  years = [];
}
