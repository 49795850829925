import {
  SECTOR,
  CONSTRUCTION_YEAR,
  hexToRgba,
  HEATING_TYPE,
  AQUAMARIN_70,
  LIND_GREEN_70,
  SUN_YELLOW_70,
  IMPULSE_ORANGE_MEDIUM,
  TAROCCO_60,
  AZUR_BLUE_40,
  DEEP_BLUE_MEDIUM,
  GREY_BEIGE_70,
  AZUR_BLUE_70,
  TAROCCO_70,
  NEUTRAL_GREY_60,
  CONSTRUCTION_YEAR_UC4_BY_ENUM,
  BUILDINGTYPE_BY_ENUM,
  CUSTOMER_AT_BY_ENUM,
  HEAT_PUMP_BY_ENUM,
  HEAT_PUMP,
  WARM_GREY_20,
} from '@/configs/color-constants';

export const CONSTRUCTION_YEAR_STYLING_UC4 = (
  property = 'construction_year_class_uc4',
) => [
  'match',
  ['get', property],
  0,
  AQUAMARIN_70,
  1919,
  LIND_GREEN_70,
  1949,
  SUN_YELLOW_70,
  1979,
  IMPULSE_ORANGE_MEDIUM,
  1991,
  TAROCCO_60,
  2001,
  AZUR_BLUE_40,
  2011,
  DEEP_BLUE_MEDIUM,
  2020,
  GREY_BEIGE_70,
  CONSTRUCTION_YEAR.UNKNOWN,
];

export const BASE_SUPPLIER_STYLE = (property = 'default_supplier') => [
  'match',
  ['get', property],
  'EnBW',
  DEEP_BLUE_MEDIUM,
  NEUTRAL_GREY_60,
];

export const BUILDING_TYPE_STYLING = [
  'match',
  ['get', 'building_type'],
  'EFH',
  AQUAMARIN_70,
  'MFH',
  LIND_GREEN_70,
  'GMH',
  SUN_YELLOW_70,
  'HH',
  AZUR_BLUE_70,
  'RH',
  TAROCCO_70,
  'NW',
  DEEP_BLUE_MEDIUM,
  'other',
  GREY_BEIGE_70,
  NEUTRAL_GREY_60,
];

export const SECTOR_STYLING = (property = 'sector') => [
  'match',
  ['get', property],
  0,
  SECTOR.GHD,
  1,
  SECTOR.PUBLIC,
  2,
  SECTOR.HOUSEHOLD,
  3,
  SECTOR.UNKNOWN,
  4,
  SECTOR.INDUSTRY,
  SECTOR.UNKNOWN,
];

export const CUSTOMER_AT_STYLING = [
  'match',
  ['get', 'existing_customer_at'],
  'EnBW',
  IMPULSE_ORANGE_MEDIUM,
  WARM_GREY_20,
];

export const TECHNOLOGY_STYLING = (property = 'heating_type') => [
  'match',
  ['get', property],
  'GAS',
  HEATING_TYPE.GAS,
  'OIL',
  HEATING_TYPE.OIL,
  'REN',
  HEATING_TYPE.REN,
  'DIS',
  HEATING_TYPE.DIS,
  'COA',
  HEATING_TYPE.COA,
  'WOD',
  HEATING_TYPE.WOD,
  'ELE',
  HEATING_TYPE.ELE,
  'BGA',
  HEATING_TYPE.BGA,
  'OTH',
  HEATING_TYPE.OTH,
  '-',
  HEATING_TYPE['-'],
  'UNK',
  HEATING_TYPE.UNK,
  HEATING_TYPE.UNK,
];

export const HEAT_PUMP_STYLING = [
  'match',
  ['get', `heat_pump_potential`],
  'green',
  HEAT_PUMP.GREEN,
  'yellow',
  HEAT_PUMP.YELLOW,
  'red',
  HEAT_PUMP.RED,
  'unk',
  HEAT_PUMP.UNKNOWN,
  HEAT_PUMP.UNKNOWN,
];

export const HEATING_TECHNOLOGY_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: HEATING_TYPE.GAS,
      name: 'Fossiles Gas',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.OIL,
      name: 'Heizöl',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.REN,
      name: 'Wärmepumpen,\nSolar-/Geothermie',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.DIS,
      name: 'Fernwärme',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.COA,
      name: 'Kohle',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.WOD,
      name: 'Holz/Holzpellets',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.ELE,
      name: 'Strom\n(ohne Wärmepumpen)',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.BGA,
      name: 'Biomasse/Biogas',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.OTH,
      name: 'Sonstige',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE['-'],
      name: 'Keine Heizung',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: HEATING_TYPE.UNK,
      name: 'Unbekannt',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const SECTOR_LEGEND = (name) => ({
  name,
  unit: '',
  items: [
    {
      color: SECTOR.HOUSEHOLD,
      name: 'Private Haushalte',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.PUBLIC,
      name: 'Öffentlich',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.GHD,
      name: 'Gewerbe, Handel,\nDienstleistungen',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.INDUSTRY,
      name: 'Industrie',
      icon: 'mdi-checkbox-blank-circle',
    },
    {
      color: SECTOR.UNKNOWN,
      name: 'Sonstige',
      icon: 'mdi-checkbox-blank-circle',
    },
  ],
});

export const CONSTRUCTION_YEAR_CLASS_LEGEND = (name) => ({
  name,
  unit: '',
  items: Object.values(CONSTRUCTION_YEAR_UC4_BY_ENUM).map((e) => ({
    color: e.color,
    name: e.label,
    icon: 'mdi-checkbox-blank-circle',
  })),
});

export const BUILDING_TYPE_LEGEND = (name) => ({
  name,
  unit: '',
  items: Object.values(BUILDINGTYPE_BY_ENUM).map((e) => ({
    color: e.color,
    name: e.label,
    icon: 'mdi-checkbox-blank-circle',
  })),
});

export const CUSTOMER_AT_LEGEND = (name) => ({
  name,
  unit: '',
  items: Object.values(CUSTOMER_AT_BY_ENUM).map((e) => ({
    color: e.color,
    name: e.label,
    icon: 'mdi-checkbox-blank-circle',
  })),
});

export const powerStyleType = (fieldName, min, max) => [
  ['!', ['has', fieldName]],
  hexToRgba(min.color, 1),
  [
    'interpolate',
    ['linear'],
    ['get', fieldName],
    min.value,
    hexToRgba(min.color, 1),
    max.value,
    hexToRgba(max.color, 1),
  ],
];

export const powerLegendType = (legendName, unit, min, max) => ({
  type: 'gradient',
  name: legendName,
  unit,
  items: {
    min: {
      value: min.value,
      color: min.color,
    },
    max: {
      value: max.value,
      color: max.color,
    },
  },
});

export const HEAT_PUMP_LEGEND = (name) => ({
  name,
  unit: '',
  items: Object.values(HEAT_PUMP_BY_ENUM).map((e) => ({
    color: e.color,
    name: e.label,
    icon: 'mdi-checkbox-blank-circle',
  })),
});
