<template>
  <v-chart
    ref="geoChart"
    :option="option"
    :class="(geoItems.length === 1 || readOnly) && 'pointer-events-none'"
    @click="syncSelectedFromChartToggle"
  />
</template>

<script setup lang="ts">
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue';
import { registerMap } from 'echarts/core';

interface GeoItem {
  id: string;
  title: string;
  name: string;
  isSelectable: boolean;
  geometry_json: string;
  areaColor?: string;
}

interface GeoJSON {
  type: 'FeatureCollection';
  features: Feature[];
}

interface Feature {
  type: 'Feature';
  properties: { name: string };
  id: string;
  geometry: any;
}

interface Data {
  id: string;
  name: string;
  itemStyle: {
    areaColor: string;
  };
  emphasis: {
    disabled: boolean;
  };
  select: {
    disabled: boolean;
    itemStyle: {
      areaColor?: string;
      borderColor: string;
    };
  };
  tooltip: {
    formatter: string;
  };
}

const props = withDefaults(
  defineProps<{
    selected: string | string[];
    geoItems: GeoItem[];
    readOnly?: boolean;
    singleSelect?: boolean;
    styleBorderOnSelect?: boolean;
    styleAreaOnSelect?: boolean;
    enableRoam?: boolean;
  }>(),
  {
    readOnly: false,
    singleSelect: false,
    styleBorderOnSelect: false,
    styleAreaOnSelect: true,
    enableRoam: false,
  },
);

const geoChart = ref<any | null>(null);

const features = ref<GeoJSON>({ type: 'FeatureCollection', features: [] });
const items = ref([]);

const MAP_IDENTIFIER = 'geoMap';

const emit = defineEmits(['loaded', 'geo-unselected', 'geo-selected']);
defineExpose({ syncGeoSelect });
// register empty map to init chart ref in mounted cycle
registerMap(MAP_IDENTIFIER, features.value);

const initialize = async () => {
  features.value.features = props.geoItems.map((geoItem) => ({
    geometry: JSON.parse(geoItem.geometry_json),
    type: 'Feature',
    properties: { name: geoItem.id },
    id: geoItem.id,
  }));

  await nextTick();
  await nextTick();
  registerMap(MAP_IDENTIFIER, features.value);
  syncGeoSelect();
  await defaultSelectOneMunicipality();
};

onBeforeMount(initialize);

const option = computed(() => {
  const style = getComputedStyle(document.documentElement);
  const unSelectedColor = `rgb(${style.getPropertyValue('--bg-subtle')})`;
  const hoverColor = `rgb(255, 224, 178)`;
  const selectedColor = `rgb(${style.getPropertyValue('--core-color2')})`;
  return {
    textStyle: {
      fontFamily: 'mark',
      fontWeight: 500,
    },
    tooltip: getTooltipStyling(),
    series: [
      {
        selectedMode: props.singleSelect ? 'single' : 'multiple',
        map: MAP_IDENTIFIER,
        roam: props.enableRoam,
        scaleLimit: {
          min: 1,
          max: 3,
        },
        type: 'map',
        geoIndex: 0,
        layoutCenter: ['50%', '50%'],
        layoutSize: '100%',
        emphasis: {
          label: {
            show: false,
          },
          itemStyle: {
            areaColor: hoverColor,
          },
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: 'white',
        },
        select: {
          label: {
            show: false,
          },
        },
        data: props.geoItems.map((item): Data => {
          const itemStyleSelect = {
            borderColor: props.styleBorderOnSelect ? selectedColor : 'white',
            areaColor: props.styleAreaOnSelect ? selectedColor : item.areaColor,
          };
          return {
            id: item.id,
            name: item.name,
            itemStyle: {
              areaColor: item.areaColor || unSelectedColor,
            },
            emphasis: {
              disabled: !item.isSelectable,
            },
            select: {
              disabled: !item.isSelectable,
              itemStyle: itemStyleSelect,
            },
            tooltip: {
              formatter: item.title + ' (' + item.id + ')',
            },
          };
        }),
      },
    ],
  };
});

const getTooltipStyling = () => ({
  trigger: 'item',
  backgroundColor: 'rgba(255, 255, 255)',
  borderWidth: 0,
  borderRadius: 0,
  boxShadow: '0 0.5px 8px 0 rgba(207, 216, 220, 0.50)',
  padding: 8,
  textStyle: {
    fontSize: 12,
    color: 'black',
    fontWeight: 500,
    fontFamily: 'mark',
    align: 'center',
    style: 'normal',
  },
});

// dispatch action. Type can be select, unselect or toggleSelect
function selectAction(type: string, ids: string | string[]) {
  if (geoChart.value === null) return;
  geoChart.value.dispatchAction({ type, name: ids });
}

function syncSelectedFromChartToggle(param: { data: Data }) {
  const selectedOld = props.selected;
  const { data } = param;
  if (data.select.disabled) return;
  const unselect = props.singleSelect
    ? selectedOld === data.id
    : selectedOld.includes(data.id);
  if (unselect && props.singleSelect) emit('geo-selected', null);
  else if (unselect && !props.singleSelect) emit('geo-unselected', data.id);
  else emit('geo-selected', data.id);
}

function syncGeoSelect() {
  selectAction(
    'unselect',
    props.geoItems.map((e) => e.id),
  );
  selectAction('select', props.selected);
}

watch(() => props.selected, syncGeoSelect);
watch(() => props.geoItems, initialize);

async function defaultSelectOneMunicipality() {
  await nextTick();
  if (props.geoItems.length === 1) {
    selectAction(
      'select',
      props.geoItems.map((e) => e.id),
    );
  }
}
</script>
