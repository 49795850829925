<template>
  <ExpansionPanel
    :panels="[
      'Bestandsanalyse',
      'Nachfrageentwicklung Strom',
      'Nachfrageentwicklung Wärme',
    ]"
  >
    <template #panel1>
      <div class="flex flex-col gap-4">
        <div class="projects-grid-wrapper max-h-[300px]">
          <BasicPieChart
            :chart-data="electricityDemandBySectorGWh"
            :title="`Stromverbrauch nach Sektoren`"
            chart-unit="GWh(el)/a"
            title-inside="GWh(el)/a"
            :show-chart-unit-inside-title="false"
            class="projects-grid-item"
            :custom-radius="['50%', '80%']"
            custom-title-classes="mb-3"
            :series-position="['33%', '50%']"
            :legend-data="SECTOR_LEGENDS_ORDERED"
            legend-right="10%"
            :show-label="false"
          />
          <BasicPieChart
            :chart-data="generation"
            :title="`EE-Erzeugung`"
            :custom-radius="['50%', '80%']"
            custom-title-classes="mb-3"
            :series-position="['33%', '50%']"
            :legend-data="RENEWABLE_GENERATION_LEGENDS_ORDERED"
            chart-unit="GWh(el)/a"
            title-inside="GWh(el)/a"
            :show-chart-unit-inside-title="false"
            class="projects-grid-item"
            legend-right="15%"
            :show-label="false"
          />
          <WaterfallChart
            class="min-h-[300px]"
            :data="dataPowerBalance"
            :labels="labelsPowerBalance"
            title="Mengenbilanz"
            grid-top="70px"
            grid-left="35px"
            grid-right="25px"
            title-top="16px"
            unit="GWh(el)/a"
            :y-axis-name="'GWh\n(el)/a'"
            :show-y-axis-line="true"
          />
        </div>
        <div class="projects-grid-item">
          <div
            class="flex items-center rounded-[4px] justify-start gap-2.5 pr-5 pl-10 py-4 bg-dark text-white w-full h-full"
          >
            <div class="flex items-center gap-3">
              <VProgressCircular
                v-model="shareRenewables"
                size="42"
                width="6"
                bg-color="#1867C0"
                color="white"
              />
              <h2>{{ shareRenewables + '%' }}</h2>
            </div>
            <span class="body-2 mt-[5px]">
              des Stromverbrauchs wird heute lokal durch Erneuerbare Energien
              erzeugt (bilanzieller Autarkiegrad).
            </span>
          </div>
        </div>
        <div class="projects-grid-wrapper max-h-[370px]">
          <BasicPieChart
            :chart-data="heatDemandBySectorGWh"
            :title="`Wärmeverbrauch nach Sektoren`"
            :chart-unit="'GWh(th)/a'"
            title-inside="GWh(th)/a"
            :show-chart-unit-inside-title="false"
            :custom-radius="['35%', '55%']"
            :series-position="['36%', '50%']"
            :legend-data="SECTOR_NO_MOBILITY_LEGENDS_ORDERED"
            legend-right="23%"
            class="h-[370px] projects-grid-item"
            :show-label="false"
          />

          <BasicPieChart
            :chart-data="heatDemandByTech"
            title="Wärmeverbrauch nach Energieträgern"
            :chart-unit="'GWh(th)/a'"
            title-inside="GWh(th)/a"
            :show-chart-unit-inside-title="false"
            :custom-radius="['35%', '55%']"
            :series-position="['36%', '50%']"
            legend-right="19%"
            class="h-[370px] projects-grid-item"
            :show-label="false"
          />
        </div>
      </div>
    </template>
    <template #panel2>
      <div class="flex flex-col gap-10">
        <div class="second-panel-card">
          <BarChartBasic
            :stacks="assumedFutureDemandByDrivers"
            :title="'Stromverbrauch nach Verbrauchstreibern'"
            :x-axis-data="years"
            :y-axis-name="'MWh(el)/a'"
            chart-unit="MWh(el)/a"
            class="min-h-[500px]"
          />
          <ArrowNumberCard v-model="arrowElectricityDemand" unit="%" />
        </div>
        <div class="second-panel-card">
          <BarChartBasic
            :stacks="assumedElectricityDemandBySector"
            :title="'Stromverbrauch nach Sektoren'"
            :x-axis-data="years"
            :y-axis-name="'MWh(el)/a'"
            :legend-data="SECTOR_LEGENDS_ORDERED"
            chart-unit="MWh(el)/a"
            class="min-h-[500px]"
          />
          <ArrowNumberCard v-model="arrowElectricityDemand" unit="%" />
        </div>
      </div>
    </template>
    <template #panel3>
      <div class="flex flex-col gap-10">
        <div class="second-panel-card">
          <BarChartBasic
            :stacks="assumedHeatDemandBySector"
            :title="'Wärmeverbrauch nach Sektoren'"
            :x-axis-data="years"
            :y-axis-name="'MWh(th)/a'"
            :legend-data="SECTOR_NO_MOBILITY_LEGENDS_ORDERED"
            chart-unit="MWh(th)/a"
            class="min-h-[500px]"
          />
          <ArrowNumberCard v-model="arrowHeatDemand" unit="%" />
        </div>
        <div class="second-panel-card">
          <BarChartBasic
            :stacks="heatDemandByTechYear"
            :title="'Wärmeverbrauch nach Energieträgern'"
            :x-axis-data="years"
            :y-axis-name="'MWh(th)/a'"
            chart-unit="MWh(th)/a"
            class="min-h-[500px]"
            grid-bottom="10%"
            legend-bottom="2px"
          />
          <ArrowNumberCard v-model="arrowHeatDemand" unit="%" />
        </div>
      </div>
    </template>
  </ExpansionPanel>
  <div class="flex gap-5 w-fit ml-auto">
    <SourcesItem item="demand-generation" />
  </div>
</template>

<script setup>
import { groupBy, sumBy } from 'lodash';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import WaterfallChart from '@/components/charts/WaterfallChart/WaterfallChart.vue';
import BarChartBasic from '@/components/charts/BarChartBasic.vue';
import {
  BALANCE,
  HEATING_TYPE_BY_ENUM,
  POWER_DEMAND,
  RENEWABLE_GENERATION,
  SECTOR_BY_ENUM,
} from '@/configs/color-constants';
import { computed } from 'vue';
import ArrowNumberCard from '@/apps/usecase-1/projects/project-result-detail/components/ArrowNumberCard.vue';
import { useProjectResult } from './project-result-store';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import ExpansionPanel from '@/components/ExpansionPanel/ExpansionPanel.vue';
import {
  RENEWABLE_GENERATION_LEGENDS_ORDERED,
  SECTOR_LEGENDS_ORDERED,
  SECTOR_NO_MOBILITY_LEGENDS_ORDERED,
} from '@/utils/chart-utils';
import { useRouter } from 'vue-router';

const resultStore = useProjectResult();
const router = useRouter();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

// fetch data
await resultStore.fetchDemandData(props.projectId, props.scenarioId);
await resultStore.fetchPotentialGenerationData(
  props.projectId,
  props.scenarioId,
);
// set scenario in store after data is fetched
resultStore.setActiveScenarioById(props.scenarioId);

const demandData = computed(() => resultStore.demandData[props.scenarioId]);
const baseYear = computed(() => {
  return Math.min(...demandData.value.sector_data.map((e) => e.year));
});
const years = computed(() => {
  return Array.from(
    new Set(demandData.value.sector_data.map((e) => e.year)),
  ).sort();
});

// --------------- charts for status quo
const labelsPowerBalance = [
  'Stromverbrauch',
  'EE-Erzeugung',
  'Konventionell\nbenötigte\nErzeugung',
];
const heatDemandBySectorGWh = computed(() => {
  return processBaseYearSectorData('heat_demand_kwh_a', baseYear.value);
});
const electricityDemandBySectorGWh = computed(() => {
  return processBaseYearSectorData(
    'electricity_demand_total_kwh_a',
    baseYear.value,
  );
});
const electricityTotalBaseYearGWh = computed(() => {
  return (
    demandData.value.sector_data
      .filter((e) => e.year === baseYear.value)
      .reduce((sum, item) => sum + item.electricity_demand_total_kwh_a, 0) /
    1000000
  );
});
const generation = computed(() => {
  const generationByTechnologyKWh = resultStore.getGenerationByTechnologyKwh;
  return [
    {
      name: 'Wind',
      color: RENEWABLE_GENERATION.WIND,
      value: generationByTechnologyKWh.wind / 1000000,
    },
    {
      name: 'PV-Aufdach',
      color: RENEWABLE_GENERATION.PV_ROOF,
      value: generationByTechnologyKWh.pvRoof / 1000000,
    },
    {
      name: 'PV-Freifläche',
      color: RENEWABLE_GENERATION.PV_FREE,
      value: generationByTechnologyKWh.pvFree / 1000000,
    },
    {
      name: 'Wasserkraft',
      color: RENEWABLE_GENERATION.HYDRO,
      value: generationByTechnologyKWh.hydroPower / 1000000,
    },
    {
      name: 'Biomasse',
      color: RENEWABLE_GENERATION.BIOMASS,
      value: generationByTechnologyKWh.biomass / 1000000,
    },
  ];
});
const dataPowerBalance = computed(() => {
  const generationTotalGWh = resultStore.getGenerationTotalKwh / 1000000;
  return {
    visibleStacks: [
      {
        value: electricityTotalBaseYearGWh.value,
        itemStyle: { color: BALANCE.POWER_DEMAND },
      },
      {
        value: generationTotalGWh,
        itemStyle: { color: BALANCE.RENEWABLE_GENERATION },
      },
      {
        value: Math.max(
          electricityTotalBaseYearGWh.value - generationTotalGWh,
          0,
        ),
        itemStyle: { color: BALANCE.CONVENTIONAL_GENERATION },
      },
    ],
    hiddenStacks: [
      0,
      Math.max(electricityTotalBaseYearGWh.value - generationTotalGWh, 0),
      0,
    ],
  };
});
const shareRenewables = computed(() => {
  const generationTotalGWh = resultStore.getGenerationTotalKwh / 1000000;
  let share = (generationTotalGWh / electricityTotalBaseYearGWh.value) * 100;
  if (share > 100) return 100;
  return share.toLocaleString('de-DE', { maximumFractionDigits: 0 });
});
const heatDemandByTech = computed(() => {
  const data = demandData.value.heating_tech_data.filter(
    (e) => e.year === baseYear.value,
  );
  return data.map((e) => ({
    name: HEATING_TYPE_BY_ENUM[e.heating_type].label,
    color: HEATING_TYPE_BY_ENUM[e.heating_type].color,
    value: e.heat_demand_kwh_a / 1000000,
  }));
});

// ------------- yearly electricity bar chart data
const assumedElectricityDemandBySector = computed(() => {
  return processYearlyGroupData(
    demandData.value.sector_data,
    'sector',
    'electricity_demand_total_kwh_a',
    SECTOR_BY_ENUM,
  );
});
const assumedFutureDemandByDrivers = computed(() => {
  const groupedByYear = (field, data) =>
    Object.values(
      Object.values(groupBy(data, 'year')).map((items) => sumBy(items, field)),
    );
  let data = demandData.value.sector_data;
  data = data.sort((a, b) => a.year - b.year);
  const mobility = data.filter((e) => e.sector === 5);
  return [
    {
      name: 'Konventionell',
      itemStyle: { color: POWER_DEMAND.CONVENTIONAL },
      data: groupedByYear('electricity_demand_base_kwh_a', data).map(
        (e) => e / 1000,
      ),
    },
    {
      name: 'Elektrifizierung E-Mobilität',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_MOBILITY },
      data: groupedByYear('electricity_demand_total_kwh_a', mobility).map(
        (e) => e / 1000,
      ),
    },
    {
      name: 'Elektrifizierung Wärme Einzelversorgung',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_HEAT_SINGLE },
      data: groupedByYear('electricity_demand_heat_pump_kwh_a', data).map(
        (e) => e / 1000,
      ),
    },
    {
      name: 'Elektrifizierung Wärmenetze',
      itemStyle: { color: POWER_DEMAND.ELECTRIFICATION_HEAT_NETWORK },
      data: groupedByYear('electricity_demand_heat_network_kwh_a', data).map(
        (e) => e / 1000,
      ),
    },
  ];
});
const arrowElectricityDemand = computed(() => {
  return getTotalIncreasePercent(assumedElectricityDemandBySector.value);
});

// ---------------- yearly heat demand bar chart data
const assumedHeatDemandBySector = computed(() => {
  return processYearlyGroupData(
    demandData.value.sector_data,
    'sector',
    'heat_demand_kwh_a',
    SECTOR_BY_ENUM,
  );
});
const heatDemandByTechYear = computed(() => {
  return processYearlyGroupData(
    demandData.value.heating_tech_data,
    'heating_type',
    'heat_demand_kwh_a',
    HEATING_TYPE_BY_ENUM,
  );
});
const arrowHeatDemand = computed(() => {
  return getTotalIncreasePercent(assumedHeatDemandBySector.value);
});

// ------------------ data format functions
function processBaseYearSectorData(valueFieldNames, year) {
  const data = demandData.value.sector_data.filter((e) => e.year === year);
  return data.map((e) => ({
    name: SECTOR_BY_ENUM[e.sector].label,
    color: SECTOR_BY_ENUM[e.sector].color,
    value: e[valueFieldNames] / 1000000,
  }));
}

function processYearlyGroupData(
  baseData,
  groupField,
  valueFieldName,
  chartOptions,
) {
  let years = new Set();
  const data = {};
  baseData.forEach((e) => {
    years.add(e.year);
    data[e[groupField]] = data[e[groupField]] || {};
    data[e[groupField]][e.year] = e[valueFieldName];
  });
  years = Array.from(years).sort((a, b) => a - b);
  const chartData = Object.keys(chartOptions).map((e) => {
    const rawData = data[e] || {};
    const processedData = [];
    for (const year of years) {
      processedData.push(rawData[year] / 1000 || 0);
    }
    return {
      name: chartOptions[e].label,
      itemStyle: { color: chartOptions[e].color },
      data: processedData,
    };
  });
  if (groupField === 'sector') {
    return chartData.sort(
      (a, b) =>
        SECTOR_LEGENDS_ORDERED.indexOf(a.name) -
        SECTOR_LEGENDS_ORDERED.indexOf(b.name),
    );
  }
  return chartData;
}

function getTotalIncreasePercent(data) {
  const values = data.map((e) => e.data);
  const baseYearSum = values.reduce((sum, arr) => sum + arr[0], 0);
  const targetYearSum = values.reduce(
    (sum, arr) => sum + arr[arr.length - 1],
    0,
  );
  return ((targetYearSum - baseYearSum) / baseYearSum) * 100;
}
</script>

<style scoped lang="scss">
.second-panel-card {
  @apply flex justify-between items-center gap-5;
}
</style>
