import IconUrbaneStandorte from '@/assets/icons/custom/misc/IconUrbaneStandorte.vue';
import IconDevelopment from '@/assets/icons/custom/misc/IconDevelopment.vue';
import IconInfrastrukturErzeugung from '@/assets/icons/custom/misc/IconInfrastrukturErzeugung.vue';
import {
  IMPULSE_ORANGE_MEDIUM,
  LIND_GREEN_60,
  TAROCCO_60,
} from '@/configs/color-constants';
import IconVertriebsgebiete from '@/assets/icons/custom/misc/IconVertriebsgebiete.vue';
import IconAgileFlaechen from '@/assets/icons/custom/misc/IconAgileFlaechen.vue';
import IconWohnQuartier from '@/assets/icons/custom/misc/IconWohnQuartier.vue';
import IconDezentraleEnergie from '@/assets/icons/custom/misc/IconDezentraleEnergie.vue';
import IconGebaeudeInfrastruktur from '@/assets/icons/custom/misc/IconGebaeudeInfrastruktur.vue';
import IconFlaechendeckendesLadenetz from '@/assets/icons/custom/misc/IconFlaechendeckendesLadenetz.vue';
import IconIhrVertrag from '@/assets/icons/custom/misc/IconIhrVertrag.vue';

export const EvaluationTabs = [
  {
    key: 'overview',
    name: 'Übersicht Vertriebsgebiet',
    subUrl: 'overview',
    hasExtraRouterParameter: true,
    color: TAROCCO_60,
    icon: IconFlaechendeckendesLadenetz,
  },
  {
    key: 'demand-generation',
    name: 'Vertriebsfilter',
    subUrl: 'filter',
    hasExtraRouterParameter: true,
    color: IMPULSE_ORANGE_MEDIUM,
    icon: IconVertriebsgebiete,
  },
  {
    key: 'renewable-generation',
    name: 'Export',
    subUrl: 'export',
    hasExtraRouterParameter: true,
    color: LIND_GREEN_60,
    icon: IconIhrVertrag,
  },
];
