<template>
  <div
    class="select-none flex items-center"
    :class="{ 'opacity-50 pointer-events-none': disabled }"
    @click="handleClick"
  >
    <div v-if="isCustomIcon" :class="customIconClasses">
      <Component :is="icon" :style="measurements" />
    </div>
    <div v-else :style="measurements" class="flex items-center">
      <span :class="iconClasses" :style="iconStyle">{{ icon }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  icon: {
    type: [String, Object],
    required: true,
  },
  fill: {
    type: String,
    default: 'currentColor',
  },
  hover: {
    type: String,
    default: 'currentColor',
  },
  size: {
    type: Number,
    default: 24,
  },
  type: {
    type: String,
    default: 'round',
    validator: (value) =>
      ['filled', 'outlined', 'sharp', 'two-tone', 'round', 'rounded'].includes(
        value,
      ),
  },
  customInnerIconClasses: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const isCustomIcon = computed(() => typeof props.icon === 'object');

// For Material Icons
const iconClasses = computed(() => {
  const { fill, hover, type, customInnerIconClasses } = props;
  return {
    'flex items-center justify-center': true,
    'variation-settings': true,
    [fill]: true,
    [hover]: true,
    'material-icons-round material-symbols-rounded': type === null,
    'material-icons': type === 'filled',
    [`material-icons-${type} material-symbols-${type}`]: type,
    [customInnerIconClasses]: Boolean(customInnerIconClasses),
  };
});

const iconStyle = computed(() => ({
  fontSize: `${props.size}px`,
  width: `${props.size}px`,
}));

// For Custom Icons
const customIconClasses = computed(() => {
  return {
    [props.hover]: true,
    [props.fill]: true,
  };
});
const measurements = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`,
}));

const emit = defineEmits(['click']);

function handleClick(event) {
  if (props.disabled) return;
  emit('click', event);
}
</script>

<style scoped>
.variation-settings {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 20;
}
</style>
