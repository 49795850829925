export class ClusterFiltersetDto {
  static BASE_URL = '/api/usecase-2/filterset-cluster/';

  id = null;
  min_power_enbw_kw = 0;
  max_power_enbw_kw = null;
  name = null;
  is_default = false;
  min_count_projects_enbw = 0;
  max_count_projects_enbw = null;
  only_favorites = false;
  has_external_projects = null;
  allowed_review_states = ['TODO', 'YES', 'NO'];
  is_manual = null;
  analysis = null;
  municipality = null;
}
