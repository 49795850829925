<template>
  <TopHeaderBar />
  <div class="flex flex-col gap-5">
    <div class="flex items-center gap-4">
      <IconWrapper
        :size="64"
        :icon="IconEnergieEffizienz"
        fill="text-core-color2"
      />
      <h1 class="text-title-neutral">Auswertung</h1>
    </div>
    <div
      class="flex flex-col gap-5 bg-white rounded-xl p-5 standard-elevation-2"
    >
      <FormTabs
        :tabs
        custom-element-tag="h4"
        hide-tab-windows
        prevent-window-scroll
        @updated:tab="activeTab = $event"
      />

      <ProjectResultOverviewPage v-show="activeTab.id === 'pv-project'" />
      <ClusterResultOverviewPage v-show="activeTab.id === 'cluster'" />
    </div>
  </div>
</template>

<script setup>
import IconEnergieEffizienz from '@/assets/icons/custom/misc/IconEnergieEffizienz.vue';
import FormTabs from '@/components/formTabs/FormTabs.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import ProjectResultOverviewPage from '@/apps/usecase-2/result-evaluation/ProjectResultOverviewPage.vue';
import ClusterResultOverviewPage from '@/apps/usecase-2/result-evaluation/ClusterResultOverviewPage.vue';
import { ref } from 'vue';

const tabs = [
  {
    title: 'Einzelprojekte',
    icon: 'all_out',
    id: 'pv-project',
  },
  {
    title: 'Cluster',
    icon: 'bubble_chart',
    id: 'cluster',
  },
];

const activeTab = ref('pv-project');
</script>

<style scoped lang="scss">
@use '@/assets/styles';

:deep(.third-table) {
  position: unset;
  .vuetify-data-table-footer {
    position: absolute;
    background-color: white;
    right: 0;
    bottom: -68px;
    width: 100%;
    @apply rounded-b-lg;
    @extend .standard-elevation-0-dark;
  }
}

.second-table-custom {
  position: relative;

  .left-inner-shadow {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background: linear-gradient(
      to right,
      rgba(126, 117, 112, 0.2),
      transparent
    );
    z-index: 1;
  }

  .right-inner-shadow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background: linear-gradient(to left, rgba(126, 117, 112, 0.2), transparent);
    z-index: 1;
  }
}
</style>
