import { markRaw } from 'vue';
import IconLineCalc from '@/assets/icons/custom/energy-grid/IconLineCalc.vue';
import { toLocaleStr } from '@/utils/formatUtils';

export const firstTableHeader = [
  {
    title: 'Cluster',
    key: 'name',
    align: 'left',
    width: '250px',
    sortable: false,
  },
];
export const secondTableHeader = [
  {
    title: 'Anzahl\nEinzelprojekte',
    key: 'count_projects_enbw',
    align: 'left',
    sortable: false,
  },
  {
    title: 'Gemeinde, Landkreis etc.',
    key: 'municipalities',
    align: 'left',
    sortable: false,
  },
  {
    title: 'Analysetyp',
    key: 'is_type',
    align: 'left',
    sortable: false,
  },
  {
    title: 'Fläche*',
    key: 'area_enbw_m2',
    align: 'end',
    sortable: false,
    unit: 'ha',
    multiplier: 0.0001,
  },
  {
    title: 'Flur-\nstücke*',
    key: 'count_estates_enbw',
    align: 'end',
    sortable: false,
  },
  {
    title: 'Leistung\nPV*',
    key: 'power_enbw_kw',
    align: 'end',
    sortable: false,
    unit: 'MW',
  },
  {
    title: 'Leistung\n EE-Gesamt',
    key: 'power_kw',
    align: 'end',
    sortable: false,
    unit: 'MW',
  },
  {
    title: 'Leistung\nBatteriesp.*',
    key: 'battery_power_enbw_kw',
    align: 'end',
    sortable: false,
    unit: 'MW',
  },
  {
    title: 'Spezifische\nInvestition*',
    key: 'specific_investment_enbw_eur_kw',
    align: 'end',
    sortable: false,
    unit: '€/kW',
  },
  {
    title: 'Investitions-\nvolumen*',
    key: 'investment_cost_enbw_eur',
    align: 'end',
    sortable: false,
    unit: 'Mio. €',
    multiplier: 0.001,
    minDigits: 1,
    maxDigits: 1,
  },
];
export const thirdTableHeader = [
  {
    title: '',
    key: 'review_state',
    width: '80px',
    align: 'center',
    sortable: false,
  },
  {
    title: '',
    key: 'functions',
    width: '100px',
    align: 'end',
    sortable: false,
  },
];

export const configTopField = (projectAggregationData) => [
  [
    {
      label: 'Anzahl Cluster',
      value: toLocaleStr(projectAggregationData.value.count_clusters),
      icon: 'bubble_chart',
    },
    {
      label: 'Anzahl Einzelprojekte',
      value: toLocaleStr(projectAggregationData.value.count_projects_enbw),
      icon: 'flip_to_front',
    },
  ],
  [
    {
      label: 'Gesamtfläche (ha)',
      value: toLocaleStr(projectAggregationData.value.area_enbw_m2),
      icon: 'select_all',
    },
    {
      label: 'Anzahl betroffener Flurstücke',
      value: toLocaleStr(projectAggregationData.value.count_estates_enbw),
      icon: 'layers',
    },
  ],

  [
    {
      label: 'Installierbare Leistung EE-Gesamt (MW)',
      value: toLocaleStr(projectAggregationData.value.power_kw),
      icon: 'offline_bolt',
      maxDigits: 0,
    },
    {
      label: 'Installierbare Leistung PV (MW)*',
      value: toLocaleStr(projectAggregationData.value.power_enbw_kw),
      icon: 'solar_power',
      maxDigits: 0,
    },
  ],

  [
    {
      label: 'Investitionskosten Netzanschluss (Mrd. €)',
      value: toLocaleStr(
        (projectAggregationData.value.investment_cost_enbw_hv_connection_eur +
          projectAggregationData.value.investment_cost_enbw_cable_eur +
          projectAggregationData.value.investment_cost_enbw_underground_eur) /
          1000,
        2,
        2,
      ),
      icon: markRaw(IconLineCalc),
    },
    {
      label: 'Investitionskosten Anlage (Mrd. €)',
      value: toLocaleStr(
        (projectAggregationData.value.investment_cost_enbw_pv_eur +
          projectAggregationData.value.investment_cost_enbw_battery_eur) /
          1000,
        2,
        2,
      ),
      icon: 'euro',
    },
    {
      label: 'Investitionskosten (Mrd. €)',
      value: toLocaleStr(
        Object.keys(projectAggregationData.value)
          .filter((key) => key.startsWith('investment_'))
          .reduce((sum, key) => sum + projectAggregationData.value[key], 0) /
          1000,
        2,
        2,
      ),
      icon: 'euro',
    },
  ],
];
