<template>
  <div class="flex justify-end w-full">
    <ToolTip
      class="flex justify-end w-full gap-2"
      :tooltip-text="mappedValue.tooltip"
    >
      <div class="rounded-full standard-elevation-0-dark bg-white p-[2px]">
        <div
          class="rounded-full w-3 h-3"
          :style="{
            backgroundColor: mappedValue.color,
            borderColor: 'transparent',
            borderWidth: '0',
            borderStyle: 'none',
          }"
        ></div>
      </div>
      <span>{{ mappedValue.label }}</span>
    </ToolTip>
  </div>
</template>

<script setup lang="ts">
import { HEAT_PUMP_BY_ENUM } from '@/configs/color-constants';
import { computed } from 'vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';

const props = defineProps<{
  value: string;
}>();

const mappedValue = computed(() => {
  const object = mapping[props.value];
  return object;
});

const mapping: Record<
  string,
  { color: string; label: string; tooltip?: string }
> = {
  ['Grün']: {
    ...HEAT_PUMP_BY_ENUM.GREEN,
    tooltip: 'Wärmbedarfsdeckungsgrad durch Wärmepumpe bei 100%',
  },
  ['Gelb']: {
    ...HEAT_PUMP_BY_ENUM.YELLOW,
    tooltip: 'Wärmbedarfsdeckungsgrad durch Wärmepumpe zwischen 70% und 100%',
  },
  ['Rot']: {
    ...HEAT_PUMP_BY_ENUM.RED,
    tooltip: 'Wärmbedarfsdeckungsgrad durch Wärmepumpe zwischen 0% und 70%',
  },
  ['Unbekannt']: { ...HEAT_PUMP_BY_ENUM.UNKNOWN },
};
</script>
