import { omit } from 'lodash';
import { fetchData } from './utils';
import axios from '@/utils/axiosHelper';
import { mergeFeatureDataAndLayerConfig } from '@/apps/features/map/info-panel/panel-data/panel-data-calculator';

export class SimplePanelScenarioDependent {
  metaData = null;
  url: string;
  layerIds: string[];
  optionAxios: {
    method: 'GET' | 'OPTIONS';
    url: string;
  };
  otherNestedKeys: string[];
  yearlyResultsKey: string;
  customMetaView: boolean;

  constructor(
    url: string,
    layerIds: string[],
    optionAxios: {
      method: 'GET' | 'OPTIONS';
      url: string;
    },
    {
      otherNestedKeys = [],
      yearlyResultsKey = 'yearly_results',
      customMetaView = false,
    }: {
      otherNestedKeys?: string[];
      yearlyResultsKey?: string;
      customMetaView?: boolean;
    } = {},
  ) {
    this.url = url;
    this.layerIds = layerIds;
    this.otherNestedKeys = otherNestedKeys;
    this.yearlyResultsKey = yearlyResultsKey;
    this.customMetaView = customMetaView;
    this.optionAxios = optionAxios;
  }

  async requestFeatureData(feature: any, mapStore: any) {
    const scenarioId = mapStore.selectedAnalysis.id;
    const featureId = feature.properties.id;
    const layerId = feature.layer.id;
    if (!mapStore.metaDataLayers[layerId]) {
      const metaData: Record<string, any> = {};
      const options = await axios(this.optionAxios);

      let metaDataResponse: Array<{
        type: string;
        children: Record<string, any>;
        child: {
          children: Record<string, any>;
        };
      }>;
      if (this.customMetaView) metaDataResponse = options.data;
      else metaDataResponse = options.data.actions.POST;

      for (const [key, value] of Object.entries(metaDataResponse)) {
        if (value.type === 'nested object') {
          for (const [keyNested, valueNested] of Object.entries(
            value.children,
          )) {
            metaData[keyNested] = valueNested;
          }
        } else if (key === this.yearlyResultsKey) {
          let metaChild: Record<string, any> = value;
          if (typeof value.child !== 'undefined')
            metaChild = value.child.children;
          for (const [keyNested, valueNested] of Object.entries(metaChild)) {
            metaData[keyNested] = valueNested;
          }
        } else {
          metaData[key] = value;
        }
      }
      mapStore.setLayerMetaData(layerId, metaData);
    }
    const resp = await fetchData(
      `${this.url}/${scenarioId}/${featureId}/`,
      mapStore,
    );

    if (resp === null) return resp;

    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(
    featureData: Record<string, any>,
    scenarioYear: string,
    mapStore: any,
  ) {
    const nestedResults = [...this.otherNestedKeys, this.yearlyResultsKey];
    const mainResult = omit(featureData, nestedResults);
    const yearlyResult = featureData[this.yearlyResultsKey];
    for (const key of this.otherNestedKeys) {
      for (const [fieldKey, value] of Object.entries(featureData[key]))
        (mainResult as Record<string, any>)[fieldKey] = value;
    }
    const panelConfig = mapStore.layerConfigs[featureData.layerId].sidebar;
    const metaData = mapStore.metaDataLayers[featureData.layerId];
    return mergeFeatureDataAndLayerConfig(
      mainResult,
      yearlyResult,
      scenarioYear,
      panelConfig,
      null,
      metaData,
    );
  }
}
