import {
  BASE_SUPPLIER_STYLE,
  CONSTRUCTION_YEAR_CLASS_LEGEND,
  CONSTRUCTION_YEAR_STYLING_UC4,
  HEATING_TECHNOLOGY_LEGEND,
  powerLegendType,
  powerStyleType,
  SECTOR_LEGEND,
  SECTOR_STYLING,
  TECHNOLOGY_STYLING,
} from './utils';
import { polygonClickEnabledStyle } from '@/apps/features/map/utils';
import {
  GENERATION_POTENTIAL,
  GEOGRAPHICAL_LEVEL,
  NEUTRAL_GREY_60,
  DEEP_BLUE_MEDIUM,
  SECTOR_BY_ENUM,
  CONSTRUCTION_YEAR_BY_ENUM,
  HEATING_TYPE_BY_ENUM,
  BUILDINGTYPE_BY_ENUM,
  CONSTRUCTION_YEAR_UC4_BY_ENUM,
} from '@/configs/color-constants';
import { SECTOR_NO_MOBILITY_LEGENDS_ORDERED } from '@/utils/chart-utils';

export const LAYER_KEY__POSTAL_CODE = 'postal_code';

const linearFillStyle = (key, type) => [
  ['==', ['get', key], null],
  'rgba(255,255,255,0)',
  [
    'interpolate',
    ['linear'],
    ['get', key],
    type.min.value * 1000, // convert back to kw
    type.min.color,
    type.max.value * 1000, // convert back to kw
    type.max.color,
  ],
];

const AGGREGATION_STYLE = (name, layerStyle) => {
  return {
    style: {
      default: polygonClickEnabledStyle(['rgba(255,255,255,0)']),
      sector: polygonClickEnabledStyle([SECTOR_STYLING('dominant_sector')]),
      constructionYearClass: polygonClickEnabledStyle([
        CONSTRUCTION_YEAR_STYLING_UC4('dominant_construction_year_class'),
      ]),
      default_supplier: polygonClickEnabledStyle([
        BASE_SUPPLIER_STYLE('dominant_default_supplier'),
      ]),
      heating_technology: polygonClickEnabledStyle([
        TECHNOLOGY_STYLING('dominant_heating_type'),
      ]),
      potentialPvRoofKw: polygonClickEnabledStyle(
        powerStyleType(
          'pv_roof_potential_kw',
          { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
          { value: 100000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
        ),
      ),
      heat_demand_kwh_a: polygonClickEnabledStyle(
        linearFillStyle('sum_heat_demand_kwh', layerStyle.heat_demand_kwh_a),
      ),
      heat_demand_kw: polygonClickEnabledStyle(
        linearFillStyle('sum_heat_demand_kw', layerStyle.heat_demand_kw),
      ),
    },
    legend: {
      sector: SECTOR_LEGEND(`${name} nach Sektoren`),
      default_supplier: {
        name: 'Grundversorger',
        unit: '',
        items: [
          {
            color: DEEP_BLUE_MEDIUM,
            name: 'EnBW',
            icon: 'mdi-checkbox-blank-circle',
          },
          {
            color: NEUTRAL_GREY_60,
            name: 'Sonstige',
            icon: 'mdi-checkbox-blank-circle',
          },
        ],
      },
      constructionYearClass: CONSTRUCTION_YEAR_CLASS_LEGEND(
        `${name} nach Baualtersklasse`,
      ),
      heating_technology: HEATING_TECHNOLOGY_LEGEND(
        `${name} nach dominanten Energieträgern`,
      ),
      potentialPvRoofKw: powerLegendType(
        `${name} nach PV-Dach Potenzial`,
        'MW',
        { value: 0, color: GENERATION_POTENTIAL.PV_FREE_MIN_GRADIENT },
        { value: 10000, color: GENERATION_POTENTIAL.PV_FREE_MAX_GRADIENT },
      ),
      heat_demand_kwh_a: {
        type: 'gradient',
        name: `${name} nach Wärmebedarf`,
        unit: 'MWh(th)/a',
        items: layerStyle.heat_demand_kwh_a,
      },
      heat_demand_kw: {
        type: 'gradient',
        name: `${name} nach Wärmelast`,
        unit: 'MW(th)',
        items: layerStyle.heat_demand_kw,
      },
    },
  };
};

function createStyleConfig(minValue, minColor, maxValue, maxColor) {
  return {
    min: { value: minValue, color: minColor },
    max: { value: maxValue, color: maxColor },
  };
}

export function getStyle(heatDemandMax, heatLoadMax) {
  return {
    heat_demand_kwh_a: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MIN_GRADIENT,
      heatDemandMax,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MAX_GRADIENT,
    ),
    heat_demand_kw: createStyleConfig(
      0,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MIN_GRADIENT,
      heatLoadMax,
      GEOGRAPHICAL_LEVEL.HEAT_DEMAND_MAX_GRADIENT,
    ),
  };
}

export default {
  [LAYER_KEY__POSTAL_CODE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      type: 'polygon',
      id: LAYER_KEY__POSTAL_CODE,
      sourceLayer: LAYER_KEY__POSTAL_CODE,
      layerId: LAYER_KEY__POSTAL_CODE,
      visibility: 'none',
      tilesDirectory: 'uc4',
    },
    sidebar: {
      name: 'Postleitzahl',
      subtitles: [
        'code',
        'municipality_name',
        'county_name',
        'count_residents',
        'default_supplier',
        'count_customer_at_enbw',
      ],
      chips: [
        {
          title: 'Gebäude',
          active: true,
          items: [
            {
              title: 'Gebäudestruktur',
              active: false,
              items: [
                {
                  panelItemType: 'pieChart',
                  title: 'Gebäude nach Sektoren',
                  titleInside: 'Gebäude',
                  key: 'by_sector',
                  valueFieldName: 'count_buildings',
                  seriesFieldName: 'sector',
                  style: SECTOR_BY_ENUM,
                  legend: SECTOR_NO_MOBILITY_LEGENDS_ORDERED,
                  unit: '',
                },
                {
                  panelItemType: 'pieChart',
                  title: 'Gebäude nach Baualtersklassen',
                  titleInside: 'Gebäude',
                  key: 'by_construction_year_class',
                  valueFieldName: 'count_buildings',
                  seriesFieldName: 'construction_year_class_uc4',
                  style: CONSTRUCTION_YEAR_UC4_BY_ENUM,
                  unit: '',
                },
                {
                  panelItemType: 'pieChart',
                  title: 'Gebäude nach Gebäudetyp',
                  titleInside: 'Gebäude',
                  key: 'by_building_type',
                  valueFieldName: 'count_buildings',
                  seriesFieldName: 'building_type',
                  style: BUILDINGTYPE_BY_ENUM,
                  unit: '',
                },
                {
                  panelItemType: 'pieChart',
                  title: 'Gebäude nach Energieträger',
                  titleInside: 'Gebäude',
                  key: 'by_heating_type',
                  valueFieldName: 'count_buildings',
                  seriesFieldName: 'heating_type',
                  style: HEATING_TYPE_BY_ENUM,
                  unit: '',
                },
              ],
            },
          ],
        },
        {
          title: 'Nachfrage',
          active: false,
          items: [
            {
              title: 'Wärme',
              active: true,
              items: [
                {
                  key: 'demand_kwh',
                  decimal: 0,
                },
                {
                  key: 'demand_kw',
                  decimal: 1,
                },
                {
                  key: 'average_demand_kw',
                  decimal: 1,
                },
              ],
            },
            {
              title: 'Wärmestruktur',
              active: false,
              items: [
                {
                  panelItemType: 'pieChart',
                  title: 'Wärmebedarf nach Sektoren',
                  titleInside: 'MWh(th)',
                  key: 'by_sector',
                  valueFieldName: 'demand_kwh',
                  seriesFieldName: 'sector',
                  style: SECTOR_BY_ENUM,
                  legend: SECTOR_NO_MOBILITY_LEGENDS_ORDERED,
                  unit: 'MWh(th)',
                },
                {
                  panelItemType: 'pieChart',
                  title: 'Wärmebedarf nach Baualtersklassen',
                  titleInside: 'MWh(th)',
                  key: 'by_construction_year_class',
                  valueFieldName: 'demand_kwh',
                  seriesFieldName: 'construction_year_class_uc4',
                  style: CONSTRUCTION_YEAR_UC4_BY_ENUM,
                  unit: '',
                },
                {
                  panelItemType: 'pieChart',
                  title: 'Wärmebedarf nach Gebäudetyp',
                  titleInside: 'MWh(th)',
                  key: 'by_building_type',
                  valueFieldName: 'demand_kwh',
                  seriesFieldName: 'building_type',
                  style: BUILDINGTYPE_BY_ENUM,
                  unit: '',
                },
                {
                  panelItemType: 'pieChart',
                  title: 'Wärmebedarf nach Energieträger',
                  titleInside: 'MWh(th)',
                  key: 'by_heating_type',
                  valueFieldName: 'demand_kwh',
                  seriesFieldName: 'heating_type',
                  style: HEATING_TYPE_BY_ENUM,
                  unit: '',
                },
              ],
            },
          ],
        },
      ],
    },
    ...AGGREGATION_STYLE('Postleitzahl', getStyle(1000000, 1000)),
  },
};
