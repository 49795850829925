<template>
  <div class="projects-grid-wrapper">
    <div class="w-[270px] h-80 pointer-events-none">
      <GeoMunicipalityChart :selected="selected" :geo-items="municipalities" />
    </div>
    <div class="projects-grid-item">
      <div
        class="flex flex-col gap-6 items-center justify-center h-full w-full p-6"
      >
        <div
          v-for="item in metaCardData"
          :key="item"
          class="flex items-center gap-2 p-2 w-full"
        >
          <IconWrapper :icon="item.icon" :size="32" fill="text-core-color2" />
          <span class="body-1 text-neutral whitespace-pre-line mt-1">{{
            item.title + (item.unit ? `\nin ${item.unit}` : '')
          }}</span>
          <h4 class="ml-auto text-title-neutral">
            {{ item.value.toLocaleString('de-DE') }}
          </h4>
        </div>
      </div>
    </div>
    <div class="projects-grid-item">
      <BasicPieChart
        :chart-data="buildingsByConstructionYear"
        :title="`Gebäude nach\nBaualtersklassen`"
        title-inside="Gebäude"
        :custom-radius="['40%', '60%']"
        :series-position="['33%', '50%']"
        legend-right="15%"
        :show-label="false"
        class="h-[300px]"
        custom-title-classes="mt-1.5"
      />
    </div>
    <div class="projects-grid-item">
      <BasicPieChart
        :chart-data="buildingsBySector"
        :title="`Gebäude nach\nSektoren`"
        title-inside="Gebäude"
        :custom-radius="['40%', '60%']"
        :series-position="['32%', '50%']"
        :legend-data="SECTOR_LEGENDS_ORDERED"
        legend-right="15px"
        :show-label="false"
        grid-bottom="0%"
        class="h-[300px]"
        custom-title-classes="mt-1.5"
      />
    </div>
  </div>
  <ProjectOverviewListMunicipalities :data="muniData" />
  <SourcesItem item="overview" />
</template>

<script setup>
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import { computed, ref } from 'vue';
import {
  CONSTRUCTION_YEAR_BY_ENUM,
  SECTOR_BY_ENUM,
} from '@/configs/color-constants';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { useProjectResult } from './project-result-store';
import IconKarriere from '@/assets/icons/custom/misc/IconKarriere.vue';
import IconWohnQuartier from '@/assets/icons/custom/misc/IconWohnQuartier.vue';
import IconAgileFlaechen from '@/assets/icons/custom/misc/IconAgileFlaechen.vue';
import SourcesItem from '@/components/sourcesItem/SourcesItem.vue';
import GeoMunicipalityChart from '@/components/charts/GeoChart.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';
import { SECTOR_LEGENDS_ORDERED } from '@/utils/chart-utils';
import ProjectOverviewListMunicipalities from '@/apps/usecase-1/projects/project-result-detail/components/ProjectOverviewListMunicipalities.vue';

const resultStore = useProjectResult();
const baseStore = useBaseStore();

const props = defineProps({
  projectId: {
    type: String,
    required: true,
  },
  scenarioId: {
    type: String,
    required: true,
  },
});

const municipalities = ref([]);
const municipalityGeoData = await baseStore.fetchSimplifiedMuniData();
const selected = await resultStore.activeProject.municipalities;
const muniData = await resultStore.activeProject.municipalitiesData;
const buildingData = await resultStore.fetchBuildingData(props.projectId);

const metaCardData = computed(() => {
  return [
    {
      title: 'Einwohner',
      value: buildingData.population,
      icon: IconKarriere,
    },
    {
      title: 'Fläche',
      value: Math.round(buildingData.area_m2 / 1000000),
      unit: 'km²',
      icon: IconAgileFlaechen,
    },
    {
      title: 'Einwohnerdichte',
      value: Math.round(
        buildingData.population / (buildingData.area_m2 / 1000000),
      ),
      unit: 'EW/km²',
      icon: IconKarriere,
    },
    {
      title: 'Anzahl Gebäude',
      value: buildingData.buildings,
      icon: IconWohnQuartier,
    },
  ];
});

const buildingsBySector = ref(
  Object.keys(buildingData.sector).map((e) => ({
    name: SECTOR_BY_ENUM[e].label,
    color: SECTOR_BY_ENUM[e].color,
    value: buildingData.sector[e],
  })),
);

const buildingsByConstructionYear = ref(
  Object.keys(buildingData.construction_year_class).map((e) => ({
    name: CONSTRUCTION_YEAR_BY_ENUM[e].label,
    color: CONSTRUCTION_YEAR_BY_ENUM[e].color,
    value: buildingData.construction_year_class[e],
  })),
);

populateMunicipalities(municipalityGeoData);

function populateMunicipalities(muniResponse) {
  for (const municipality of muniResponse) {
    municipalities.value.push({
      isSelectable: true,
      key: municipality.key,
      title: municipality.name,
      id: municipality.id.toString(),
      name: municipality.id.toString(),
      geometry_json: municipality.geometry_json,
    });
  }
}
</script>

<style lang="scss">
@use '@/assets/styles';

.projects-grid-wrapper {
  @apply grid auto-cols-fr grid-flow-col gap-2.5;
}

.projects-grid-item {
  @extend .standard-elevation-0-dark;
  @apply rounded-[4px] flex items-center;
}
</style>
