import { SimplePanel } from '@/apps/features/map/info-panel/panel-data/simple-panel';
import { LAYER_KEY__BUILDINGS } from '../layer-config/buildings';
import { LAYER_KEY__POSTAL_CODE } from '../layer-config/postal-code';

const urlPrefix = '/api/usecase-4';

interface PanelObjectParamters {
  url: string;
  layerKey: string;
  pk?: string;
  otherNestedKeys?: string[];
  metaOpt?: object;
}

const panelObjectParamters: PanelObjectParamters[] = [
  {
    url: '/building/',
    layerKey: LAYER_KEY__BUILDINGS,
    otherNestedKeys: ['building'],
  },
  {
    url: '/map/postal-code-aggregation/',
    layerKey: LAYER_KEY__POSTAL_CODE,
    pk: 'postal_code_id',
    metaOpt: {
      url: '/api/usecase-4/map/postal-code-aggregation-metadata/',
      method: 'GET',
    },
  },
];

export const panelObjects = panelObjectParamters.map(
  ({ url, layerKey, pk = 'id', otherNestedKeys = [], metaOpt = null }) =>
    new SimplePanel(
      `${urlPrefix}${url}`,
      [layerKey],
      pk,
      otherNestedKeys,
      metaOpt,
    ),
);
