<template>
  <div class="space-y-7">
    <div v-for="row in cards" :key="row" class="flex space-x-2.5">
      <DetailBox
        v-for="item in row"
        :key="item.label"
        :minimum-digits="0"
        :maximum-digits="0"
        v-bind="item"
      />
    </div>
    <div
      class="flex w-full justify-between standard-elevation-0-dark rounded-md"
    >
      <BasicPieChart
        v-for="chart in pieCharts"
        :key="chart.title"
        :chart-data="chart.data"
        :title="chart.title"
        chart-unit="Gebäude"
        title-inside="Gebäude"
        :show-chart-unit-inside-title="false"
        class="chart-container"
        :series-position="['50%', '40%']"
        :custom-radius="['40%', '60%']"
        :legend-top="chart.legendTop"
        :legend-right="null"
        legend-orient="horizontal"
        :show-label="false"
      />
    </div>
    <div class="standard-elevation-0-dark rounded-md p-5 space-y-5">
      <h3 class="text-title-color1 mb-5">Übersicht PLZ</h3>
      <VuetifyDataTable
        :item-data="sortedPostalCodes"
        :headers="plzHeaders"
        :show-expand="false"
        :fixed-header="true"
        style="min-height: calc(100vh - 290px)"
      />
    </div>
  </div>
</template>

<script setup>
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import DetailBox from '@/apps/features/boxes/DetailBox.vue';
import {
  BUILDINGTYPE_BY_ENUM,
  CONSTRUCTION_YEAR_UC4_BY_ENUM,
  HEATING_TYPE_BY_ENUM_UC4,
  SECTOR_BY_ENUM_UC_4,
} from '@/configs/color-constants';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import axios from '@/utils/axiosHelper';
import { computed } from 'vue';

const props = defineProps({
  salesRegionId: {
    type: String,
    required: true,
  },
});

const response = await axios({
  method: 'GET',
  url: `/api/usecase-4/overview/${props.salesRegionId}`,
});

const responseData = computed(() => {
  if (response.status === 200) {
    return response.data;
  }
  return undefined;
});

const postalCodes = computed(() => {
  if (!responseData.value) {
    return [];
  }
  return responseData.value.postal_codes;
});

const sortedPostalCodes = computed(() => {
  if (!postalCodes.value) {
    return [];
  }
  return postalCodes.value.toSorted((a, b) => {
    if (a.code < b.code) {
      return -1;
    }
    if (a.code > b.code) {
      return 1;
    }
    return 0;
  });
});

// ---------------- Cards section -----------------

const cards = computed(() => {
  if (!responseData.value) {
    return [];
  }

  return [
    [
      {
        icon: 'groups',
        label: 'Einwohner',
        value: responseData.value.count_residents,
      },
      {
        icon: 'home_work',
        label: 'Anzahl Gebäude',
        value: responseData.value.count_buildings,
      },
      {
        icon: 'settings_overscan',
        label: 'Fläche',
        value: responseData.value.area_m2,
        unit: 'km²',
      },
      {
        icon: 'portrait',
        label: 'Einwohnerdichte',
        value: responseData.value.resident_density_persons_m2,
        unit: 'EW/km²',
      },
    ],
  ];
});

// ---------------- Piecharts section -----------------

const pieCharts = computed(() => {
  if (!responseData.value) {
    return [];
  }

  const byBuildingTypeData = {
    title: 'Gebäudetyp',
    legendTop: '270px',
    data: getChartData(
      responseData.value.by_building_type,
      BUILDINGTYPE_BY_ENUM,
      'building_type',
    ),
  };

  const byBuildingYearClassData = {
    title: 'Gebäude nach Baualtersklassen',
    legendTop: '270px',
    data: getChartData(
      responseData.value.by_construction_year_class,
      CONSTRUCTION_YEAR_UC4_BY_ENUM,
      'construction_year_class_uc4',
    ),
  };

  const byBuildingSectorData = {
    title: 'Gebäude nach Sektor',
    legendTop: '270px',
    data: getChartData(
      responseData.value.by_sector,
      SECTOR_BY_ENUM_UC_4,
      'sector',
    ),
  };

  const byBuildingHeatingTypeData = {
    title: 'Gebäude nach Energieträger heute',
    legendTop: '270px',
    data: getChartData(
      responseData.value.by_heating_type,
      HEATING_TYPE_BY_ENUM_UC4,
      'heating_type',
    ),
  };

  return [
    byBuildingTypeData,
    byBuildingYearClassData,
    byBuildingSectorData,
    byBuildingHeatingTypeData,
  ];
});

function getChartData(response, buildingEnum, key) {
  return response.reduce((result, current) => {
    current.color = buildingEnum[current[key]].color;
    current.name = buildingEnum[current[key]].label;
    current.value = current.count_buildings;
    result.push(current);

    return result;
  }, []);
}

// ---------------- Municipalities and PLZ section -----------------

const municipalityHeaders = [
  {
    title: 'Gemeinde',
    key: 'municipality_name',
  },
  {
    title: 'Landkreis',
    key: 'county_name',
  },
  {
    title: 'Einwohner',
    key: 'count_residents',
    align: 'end',
  },
  {
    title: 'Anzahl Gebäude',
    key: 'count_buildings',
    align: 'end',
  },
  {
    title: 'Fläche',
    key: 'area_m2',
    align: 'end',
    unit: 'km²',
  },
  {
    title: 'Einwohnerdichte',
    key: 'resident_density_persons_m2',
    align: 'end',
    unit: 'EW/km²',
  },
];

const plzHeaders = [
  {
    title: 'PLZ',
    key: 'code',
  },
  ...municipalityHeaders,
];
</script>

<style scoped>
.chart-container {
  height: 400px;
  max-width: 330px;
}
</style>
