import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import { useBaseMapStore } from '@/apps/features/map/base-map-store';
import { layerPanelConfig as panelConfig } from '@/apps/usecase-4/map/layer-panel/layer-panel-config';
import layers from './layer-config';

export const useMapStore = defineStore('MapUsecase4', {
  state: () => ({
    ...useBaseMapStore.state(),
    layerPanelConfig: cloneDeep(panelConfig),
    layerConfigs: cloneDeep(layers),
  }),
  getters: {
    ...useBaseMapStore.getters,
  },
  actions: {
    ...useBaseMapStore.actions,
    resetConfigs() {
      this.layerPanelConfig = cloneDeep(panelConfig);
      this.layerConfigs = cloneDeep(layers);
      this.activeLayerToggleSections = {};
    },
  },
});
