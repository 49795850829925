<template>
  <div>
    <div
      class="flex flex-col gap-5 bg-white rounded-xl p-5 standard-elevation-2 min-h-[calc(100vh-374px)]"
    >
      <div class="flex justify-end gap-2.5">
        <SlideContainer
          ref="clusterSlideContainer"
          :title="'Filter'"
          :prevent-close-from-outside="createDeleteModalIsOpen"
        >
          <template #activator="{ openModal }">
            <ButtonEl
              icon="filter_alt"
              :color="filterApplied ? 'success' : 'color1'"
              @click="openModal"
            />
          </template>
          <template #header-action>
            <div class="mr-auto">
              <ToolTip v-if="filterApplied" tooltip-text="Filter zurücksetzen">
                <IconWrapper
                  icon="restart_alt"
                  fill="text-color1"
                  class="cursor-pointer"
                  @click="applyClusterFilter({})"
                />
              </ToolTip>
              <h4 v-else class="text-title-color1">(inaktiv)</h4>
            </div>
          </template>
          <FilterCluster
            ref="filterClusterEl"
            :municipalities
            @apply:filter="applyClusterFilter($event)"
            @update:modal-is-open="createDeleteModalIsOpen = $event"
          />
        </SlideContainer>
      </div>
      <div class="flex flex-col justify-between w-full gap-5">
        <div class="flex flex-col gap-2.5">
          <div
            v-for="row in topFieldData"
            :key="row"
            class="grid grid-flow-col auto-cols-fr gap-2.5"
          >
            <div
              v-for="item in row"
              :key="item"
              class="bg-white standard-elevation-0-dark rounded-lg p-2.5 flex items-center w-full body-1 gap-3"
            >
              <IconWrapper
                :size="32"
                :icon="item.icon"
                fill="text-title-neutral"
              />
              <div class="flex items-center justify-between w-full">
                <span class="pt-0.5 text-neutral">{{ item.label }}</span>
                <h4 class="text-title-neutral pb-0.5">
                  {{ item.value }}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div
          class="grid auto-cols-auto grid-flow-col relative mb-[68px] standard-elevation-0-dark rounded-lg"
        >
          <VuetifyDataTable
            ref="firstTable"
            :total-items="countItems"
            :headers="firstTableHeader"
            :item-data="clusterListData.results"
            :show-expand="false"
            :hide-footer="true"
            :fixed-header-height="80"
            :fixed-row-height="68"
            class="rounded-r-none w-[275px] rounded-bl-none shadow-none"
          >
            <template #name="{ item }">
              <div
                v-if="item.name"
                class="flex items-center justify-between gap-2"
              >
                <!-- name -->
                <div class="flex items-center gap-5">
                  <IconWrapper
                    type="round"
                    fill="text-title-neutral"
                    icon="all_out"
                  />
                  <div
                    class="whitespace-nowrap subtitle-2 text-core-dark pt-[3px]"
                  >
                    <template v-if="item.name.length > 25">
                      <ToolTip :clean-content="true" :slot-max-width="null">
                        <div>
                          {{ item.name.substring(0, 25) + '...' }}
                        </div>
                        <template #text>
                          <div
                            class="bg-white rounded-xs standard-elevation-0-dark flex justify-start items-center p-2.5"
                          >
                            <h6 class="text-core-dark">{{ item.name }}</h6>
                          </div>
                        </template>
                      </ToolTip>
                    </template>
                    <template v-else>
                      {{ item.name }}
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </VuetifyDataTable>
          <div
            class="w-full overflow-x-auto hide-scrollbar second-table-custom"
          >
            <div class="left-inner-shadow"></div>
            <div class="right-inner-shadow"></div>
            <VuetifyDataTable
              ref="secondTable"
              :total-items="countItems"
              :headers="secondTableHeader"
              :item-data="clusterListData.results"
              :show-expand="false"
              :hide-footer="true"
              :fixed-header-height="80"
              :fixed-row-height="68"
              class="rounded-none w-full shadow-none"
            >
              <template #count_projects_enbw="{ item }">
                <div class="flex items-center gap-1">
                  <template
                    v-for="tag in countProjectsTagConfig(item)"
                    :key="tag"
                  >
                    <ToolTip
                      :clean-content="true"
                      :slot-max-width="null"
                      :offset="2"
                      :close-delay="0"
                    >
                      <div
                        class="bg-white rounded-xs border border-line-disabled-neutral flex items-center gap-2 p-1"
                      >
                        <IconWrapper
                          fill="text-title-neutral"
                          :icon="tag.icon"
                        />
                        <span
                          class="text-core-dark subtitle-2 pt-[3px] min-w-[12px] text-right pr-0.5"
                          >{{ tag.value || 0 }}</span
                        >
                      </div>
                      <template #text>
                        <div
                          class="bg-white rounded-xs standard-elevation-0-dark flex justify-start items-center p-2"
                        >
                          <h6 class="text-core-dark">{{ tag.label }}</h6>
                        </div>
                      </template>
                    </ToolTip>
                  </template>
                </div>
              </template>
              <template #is_type="{ item }">
                {{ item.is_manual ? 'Bottom-Up' : 'Top-Down' }}
              </template>

              <template #municipalities="{ item }">
                <div
                  v-if="item.municipalities.length"
                  class="flex items-center justify-between"
                >
                  <span class="whitespace-nowrap">
                    {{
                      `${item.municipalities.length} ${item.municipalities.length > 1 ? 'Gemeinden' : 'Gemeinde'}, ${item.municipalities[0].name.length > 10 ? item.municipalities[0].name.substring(0, 10) + '...' : item.municipalities[0].name}`
                    }}
                  </span>
                  <ToolTip :clean-content="true" :slot-max-width="null">
                    <template #default>
                      <IconWrapper
                        type="round"
                        fill="text-core-color1"
                        icon="more"
                        class="cursor-pointer"
                      />
                    </template>
                    <template #text>
                      <div
                        class="flex flex-col rounded-lg overflow-hidden standard-elevation-0-dark border border-line-disabled-neutral"
                      >
                        <div
                          class="grid grid-flow-col auto-cols-fr whitespace-nowrap bg-white"
                        >
                          <h6
                            v-for="headerItem in [
                              'Regierungsbezirk',
                              'Landkreis',
                              'Gemeinde',
                            ]"
                            :key="headerItem"
                            class="text-title-neutral p-2.5"
                          >
                            {{ headerItem }}
                          </h6>
                        </div>
                        <div>
                          <div
                            v-for="(municipality, index) in item.municipalities"
                            :key="municipality"
                            :class="[
                              'grid grid-flow-col auto-cols-fr',
                              index % 2 === 0 ? 'bg-subtle' : 'bg-white',
                            ]"
                          >
                            <span
                              v-for="key in [
                                'administrative_district',
                                'county',
                                'name',
                              ]"
                              :key="key"
                              class="p-2.5 body-3 text-core-dark"
                            >
                              {{ municipality[key] }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </ToolTip>
                </div>
                <div v-else>Keine Gemeinden</div>
              </template>
            </VuetifyDataTable>
          </div>
          <VuetifyDataTable
            ref="thirdTable"
            :total-items="countItems"
            :headers="thirdTableHeader"
            :item-data="clusterListData.results"
            :show-expand="false"
            :fixed-header-height="80"
            :fixed-row-height="68"
            class="rounded-l-none w-min third-table shadow-none"
            :class="{ 'min-w-[200px]': !clusterListData?.results?.length }"
            @update:options="fetchClusterList($event)"
          >
            <template #utility_value="{ item }">
              <div
                class="bg-[#968F89] flex items-center justify-center rounded-xs px-1.5 pt-[3px] pb-1 w-fit mx-auto"
              >
                <h4 class="text-white">
                  {{ toLocaleStr(item.utility_value, 1, 1) }}
                </h4>
              </div>
            </template>
            <template #review_state="{ item }">
              <ToolTip
                v-if="item.review_state"
                :tooltip-text="getReviewStateStyle(item.review_state).label"
                class="mx-auto"
              >
                <IconWrapper
                  type="round"
                  :fill="getReviewStateStyle(item.review_state).color"
                  :icon="getReviewStateStyle(item.review_state).icon"
                />
              </ToolTip>
            </template>
            <template #is_favorite="{ item }">
              <ToolTip
                v-if="item.is_favorite"
                tooltip-text="Prioritätsfläche"
                class="mx-auto"
              >
                <IconWrapper type="round" fill="text-color1" icon="stars" />
              </ToolTip>
              <div v-else></div>
            </template>
            <template #functions="{ item }">
              <div class="flex justify-end items-center gap-4 w-fit">
                <IconWrapper
                  type="round"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  :icon="IconGoToMap"
                  @click="navigate(item)"
                />
                <IconWrapper
                  type="round"
                  fill="text-core-color1"
                  hover="hover:text-button-primary-hover-color1"
                  class="cursor-pointer"
                  icon="arrow_forward"
                  @click="navigateToDetail(item)"
                />
              </div>
            </template>
          </VuetifyDataTable>
        </div>
        <div class="flex justify-end text-sm">
          * Werte beziehen sich auf den Anteil von PV-Projekten der EnBW
          innerhalb des Clusters.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import SlideContainer from '@/components/SlideContainer.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import { ref, computed, markRaw } from 'vue';
import { toLocaleStr } from '@/utils/formatUtils';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import FilterCluster from './components/FilterCluster.vue';
import { useSyncGeoMapMunicipalitySelection } from '@/composables/syncGeoMapMunicipalitySelection';
import { useRoute, useRouter } from 'vue-router';
import IconEnBWLogoSmall from '@/assets/icons/custom/misc/IconEnBWLogoSmall.vue';
import { navigateToMap } from '@/apps/usecase-2/utils';
import IconGoToMap from '@/assets/icons/custom/misc/IconGoToMap.vue';
import {
  configTopField,
  thirdTableHeader,
  secondTableHeader,
  firstTableHeader,
} from '@/apps/usecase-2/result-evaluation/configs/cluster-data-config';
import axios from '@/utils/axiosHelper';
import { getReviewStateStyle } from '@/apps/usecase-2/utils';
import { isEmpty } from 'lodash';
import { ClusterFiltersetDto } from '@/apps/usecase-2/result-evaluation/cluster-filterset-dto';
import { useToastStore } from '@/stores/toast-store';

const { showToast } = useToastStore();
const { municipalities } = useSyncGeoMapMunicipalitySelection();
const router = useRouter();
const route = useRoute();

const filterClusterEl = ref(null);
const clusterSlideContainer = ref(null);

// -------- TABLE CLUSTER AND AGGREGATION
const page = ref(1);
const itemsPerPage = ref(10);
const filter = ref({});
const aggregationData = ref({});
const clusterListData = ref([]);

if (route.query.analysis) {
  const filterDto = new ClusterFiltersetDto();
  filterDto.analysis = route.query.analysis;
  await applyClusterFilter(filterDto);
} else {
  await fetchClusterList(null, false);
  await fetchAggregatedData();
}
const topFieldData = computed(() => configTopField(aggregationData));
const countItems = computed(() => clusterListData.value?.count || 0);
function countProjectsTagConfig(item) {
  return [
    {
      label: 'EnBW-Projekte',
      icon: markRaw(IconEnBWLogoSmall),
      value: item.count_projects_enbw,
    },
    {
      label: 'Externe Projekte',
      icon: 'device_hub',
      value: item.count_projects_external,
    },
  ];
}
// ---------- UI Logic
function navigateToDetail(item) {
  router.push({
    name: 'UseCase2ClusterOverview',
    params: { clusterId: item.id },
  });
}

function navigate(item) {
  const cluster = clusterListData.value.results.find((e) => e.id === item.id);
  navigateToMap(router, cluster.analysis_id, null, cluster.id);
}

// ------------ API FUNCTIONS
async function fetchAggregatedData() {
  const response = await axios({
    method: 'GET',
    url: '/api/usecase-2/cluster-aggregated/',
    params: { ...filter.value },
  });
  aggregationData.value = response.data;
}

const firstTable = ref(null);
const secondTable = ref(null);
const thirdTable = ref(null);

async function fetchClusterList(
  newPaginationOptions = null,
  showLoadingToast = true,
) {
  if (newPaginationOptions) {
    page.value = newPaginationOptions.page;
    itemsPerPage.value = newPaginationOptions.itemsPerPage;
    // Update all table instances
    [firstTable.value, secondTable.value, thirdTable.value].forEach((table) => {
      if (table) {
        table.setItemsPerPage(itemsPerPage.value);
        table.setPage(page.value);
      }
    });
  }
  try {
    const response = await axios({
      url: `/api/usecase-2/cluster/`,
      method: 'GET',
      params: {
        ...filter.value,
        page: page.value,
        page_size: itemsPerPage.value,
      },
    });

    if (filterClusterEl.value) {
      filterClusterEl.value.isLoading = false;
    }

    if (showLoadingToast) {
      showToast({
        color: 'success',
        message: 'Ergebnisse erfolgreich geladen',
      });
    }
    clusterListData.value = response.data;
  } catch (error) {
    console.error(error);
    showToast({
      message: 'Beim Laden der Ergebnisse ist ein Fehler aufgetreten',
      type: 'error',
    });
  }
}

// ------------- FILTER
const filterApplied = computed(() => !isEmpty(filter.value));

async function applyClusterFilter(filterDto) {
  const isReset = Object.keys(filterDto).length === 0;

  if (isReset) {
    filterClusterEl.value.resetFilterSet(true);
  }

  filter.value = filterDto;
  // Update all table instances to page 1
  if (page.value !== 1) {
    [firstTable.value, secondTable.value, thirdTable.value].forEach((table) => {
      if (table) table.setPage(1);
    });
  }
  await fetchAggregatedData();
  await fetchClusterList(null, !isReset);

  if (!isReset && clusterSlideContainer.value !== null) {
    clusterSlideContainer.value.closeModal();
  }
}

const createDeleteModalIsOpen = ref(false);
</script>

<style scoped lang="scss">
@use '@/assets/styles';

:deep(.third-table) {
  position: unset;
  .vuetify-data-table-footer {
    position: absolute;
    background-color: white;
    right: 0;
    bottom: -68px;
    width: 100%;
    @apply rounded-b-lg;
    @extend .standard-elevation-0-dark;
  }
}

.second-table-custom {
  position: relative;

  .left-inner-shadow {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background: linear-gradient(
      to right,
      rgba(126, 117, 112, 0.2),
      transparent
    );
    z-index: 1;
  }

  .right-inner-shadow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background: linear-gradient(to left, rgba(126, 117, 112, 0.2), transparent);
    z-index: 1;
  }
}
</style>
