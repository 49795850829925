<template>
  <ToolTip
    :tooltip-text="`${toLocaleStr(props.percentage || 0)}% an Gesamtbestand`"
    class="pt-0.5"
  >
    <div
      class="circle-chart border-line-disabled-neutral border-2"
      :style="chartStyle"
    ></div>
  </ToolTip>
</template>
<script setup>
import { computed } from 'vue';
import { DEEP_BLUE_MEDIUM, WARM_GREY_00 } from '@/configs/color-constants';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import { toLocaleStr } from '@/utils/formatUtils';

const props = defineProps({
  percentage: Number,
});

const chartStyle = computed(() => {
  return {
    background: `conic-gradient(
        ${DEEP_BLUE_MEDIUM} 0% ${props.percentage}%,
        ${WARM_GREY_00} ${props.percentage}% 100%
    )`,
  };
});
</script>

<style scoped>
.circle-chart {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 1;
}
</style>
