<template>
  <TopHeaderBar>
    <InputSearch
      class="w-[565px]"
      placeholder="Nach Vertriebsgebiet oder Ersteller:in suchen"
      :search-keys="['title', 'created_by']"
      :items="salesRegions"
      @update:search="filteredSalesRegions = $event"
    />

    <ButtonEl
      v-if="userStore.uc4CanAddSalesRegion"
      icon="add"
      text="Neues Vertriebsgebiet"
      @click="router.push({ name: 'CreateSalesRegions' })"
    />
  </TopHeaderBar>

  <VuetifyDataTable
    :item-data="filteredSalesRegions"
    :headers="SalesRegionsListHeaders"
    :show-expand="false"
    style="min-height: calc(100vh - 290px)"
  >
    <template #title="{ item }">
      <div class="flex align-center">
        <div class="flex flex-col gap-3 pr-5 pl-0">
          <h5>{{ item.title }}</h5>
          <span class="body-3">
            {{ formatTableItemsText(item, 'postalCodes') }}
          </span>
        </div>
      </div>
    </template>
    <template #progress="{ item }">
      <div class="flex justify-center items-center pr-4">
        <ToolTip
          v-if="['success', 'in_progress'].includes(item.progress)"
          :tooltip-text="
            item.progress === 'success'
              ? 'Erfolgreich abgeschlossen'
              : 'Geodaten werden erstellt...'
          "
        >
          <IconWrapper
            :icon="
              item.progress === 'success' ? 'task_alt' : IconTaskProgressSharp
            "
            :fill="
              item.progress === 'success'
                ? 'text-spot-success'
                : 'text-spot-warning'
            "
          />
        </ToolTip>
        <ToolTip v-else tooltip-text="Ein Fehler ist aufgetreten.">
          <IconWrapper
            class="cursor-pointer"
            icon="error_outline"
            fill="text-red-600"
          />
        </ToolTip>
      </div>
    </template>
    <template #status="{ item }">
      <div class="flex justify-center items-center pr-4">
        <ToolTip>
          <IconWrapper
            :icon="colorProjectStatus(item.status, '').icon"
            :fill="colorProjectStatus(item.status, '').color"
          />
          <template #text>
            {{ getStatusText(item.status) }}
          </template>
        </ToolTip>
      </div>
    </template>
    <template #functions="{ item }">
      <div class="flex justify-start items-center gap-4">
        <DeleteDialog
          :id="item.id"
          :name-deleted-item="`Vertreibsgebiet ${item.title}`"
          :dto="SalesRegionDto"
          :disabled="false"
          @instance-removed="salesRegionStore.fetchSalesRegions"
        >
          <IconWrapper
            v-if="userStore.uc4CanDeleteSalesRegion"
            :size="32"
            fill="text-core-color1"
            hover="hover:text-button-primary-hover-color1"
            icon="delete_forever"
            class="cursor-pointer"
            type="round"
          />
        </DeleteDialog>
        <IconWrapper
          :size="32"
          fill="text-core-color1"
          hover="hover:text-button-primary-hover-color1"
          icon="map"
          class="cursor-pointer"
          type="round"
          :disabled="item.progress !== 'success'"
          @click="navigateToMap(router, item.id)"
        />
        <IconWrapper
          :size="32"
          type="round"
          fill="text-core-color1"
          hover="hover:text-button-primary-hover-color1"
          icon="arrow_forward"
          class="cursor-pointer"
          @click="navigateToDetail(item.id)"
        />
      </div>
    </template>
  </VuetifyDataTable>
</template>

<script setup>
import { useUsers } from '@/apps/auth/users-store';
import { headers as SalesRegionsListHeaders } from '@/apps/usecase-4/sales-regions/sales-regions-list-headers';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';
import { useSalesRegionsStore } from '@/apps/usecase-4/sales-regions/sales-regions-store';
import IconTaskProgressSharp from '@/assets/icons/custom/misc/IconTaskProgressSharp.vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import InputSearch from '@/components/inputSearch/InputSearch.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import { navigateToMap, colorProjectStatus } from '@/apps/usecase-4/utils';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const salesRegionStore = useSalesRegionsStore();
const userStore = useUsers();

await salesRegionStore.fetchSalesRegions();

const salesRegions = computed(() => salesRegionStore.salesRegions);
const filteredSalesRegions = ref(salesRegions.value);

const getStatusText = computed(() => (status) => {
  const statusMap = {
    created: 'Projekt angelegt',
    in_progress: 'Projekt in Bearbeitung',
    complete: 'Projekt abgeschlossen',
    termination: 'Abbruch',
  };
  return statusMap[status] || status;
});

watch(
  salesRegions,
  (newSalesRegions) => {
    filteredSalesRegions.value = newSalesRegions;
  },
  { immediate: true, deep: true },
);

function navigateToDetail(salesRegionId) {
  router.push({
    name: 'Usecase4SalesRegionEvaluationOverview',
    params: { salesRegionId },
  });
}

function formatTableItemsText(item, type) {
  const data = {
    postalCodes: {
      count_id: 'count_postal_codes',
      label: 'PLZ',
      pluralLabel: 'PLZ',
    },
  };
  const { count_id, label, pluralLabel } = data[type];
  const count = item[count_id];
  return `${count.toLocaleString('de-DE')} ${count > 1 ? pluralLabel : label}`;
}
// --------- intervall for fetching
onMounted(() => {
  startFetchingAnalyses();
});

const interval = ref();

function startFetchingAnalyses() {
  if (!interval.value) {
    interval.value = setInterval(() => {
      salesRegionStore.fetchSalesRegions();
    }, 10000);
  }
}

function stopFetchingScenarios() {
  if (interval.value) {
    clearInterval(interval.value);
    interval.value = null;
  }
}

onBeforeUnmount(() => {
  stopFetchingScenarios();
});
</script>
