<template>
  <slot name="activator" :open-modal="openModal" :modal-is-open="modalIsOpen" />
  <Teleport to="body">
    <Transition name="slide">
      <div
        v-show="modalIsOpen"
        ref="containerRef"
        class="slide-container standard-elevation-0-dark"
        :style="{ zIndex: zIndex }"
        :data-position="position"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
      >
        <div class="slide-header">
          <h4 v-if="title" class="text-title-color1">{{ title }}</h4>
          <slot name="header-action" />
          <IconWrapper
            icon="close"
            fill="text-color1"
            class="cursor-pointer"
            @click="closeModalFromButton"
          />
        </div>
        <div class="slide-content hide-scrollbar">
          <slot />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { ref, onBeforeUnmount, watchEffect } from 'vue';
import { onClickOutside } from '@vueuse/core';
import IconWrapper from './IconWrapper/IconWrapper.vue';
import { useBaseStore } from '@/apps/basemodule/base-store';

const baseStore = useBaseStore();

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  preventCloseFromOutside: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: 'right',
    validator: (value) => ['left', 'right'].includes(value),
  },
});

defineExpose({
  closeModal,
  closeModalFromButton,
});

const modalIsOpen = ref(false);
const containerRef = ref(null);
const zIndex = ref(1000);

const emit = defineEmits(['update:slide-container-is-open']);

watchEffect(() => {
  emit('update:slide-container-is-open', modalIsOpen.value);
});

onClickOutside(
  containerRef,
  props.preventCloseFromOutside ? () => {} : closeModal,
);

const mainElement = ref(document.querySelector('html'));

function handleMouseEnter() {
  mainElement.value.style.overflow = 'hidden'; // Lock scroll
}

function openModal() {
  zIndex.value = baseStore.increaseSliderContainerZIndex();
  modalIsOpen.value = true;
}
function closeModal() {
  if (props.preventCloseFromOutside) {
    return;
  }
  handleMouseLeave();
  modalIsOpen.value = false;
}

function closeModalFromButton() {
  handleMouseLeave();
  modalIsOpen.value = false;
}

function handleMouseLeave() {
  mainElement.value.style.overflow = ''; // Unlock scroll
}

onBeforeUnmount(() => {
  // Ensure scroll is unlocked on unmount
  handleMouseLeave();
});
</script>

<style scoped>
.slide-container {
  position: fixed;
  top: 0;
  width: 440px;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
}

.slide-container[data-position='right'] {
  right: 0;
}

.slide-container[data-position='left'] {
  left: 0;
}

.slide-header {
  padding: 20px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.slide-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Transition animations */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from[data-position='right'],
.slide-leave-to[data-position='right'] {
  transform: translateX(100%);
}

.slide-enter-from[data-position='left'],
.slide-leave-to[data-position='left'] {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style>
