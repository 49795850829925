<template>
  <VuetifyDataTable
    :headers="tableHeaders"
    :item-data="sortedInstances"
    :show-expand="false"
  >
    <template #isFinished="{ item }">
      <div class="flex justify-center">
        <img
          :src="isReleased(item) ? CheckGreenIcon : ProgressIcon"
          alt="progress"
        />
      </div>
    </template>
    <template #functions="{ item }">
      <div class="flex gap-2 justify-end">
        <DeleteDialog
          v-if="enableDelete(item)"
          :id="item.id"
          :name-deleted-item="item.title"
          :url
          @instance-removed="
            emit('update-instances');
            emit('abort');
          "
        >
          <IconWrapper
            icon="delete_forever"
            fill="text-title-color1"
            type="filled"
            class="cursor-pointer opacity-80 hover:opacity-100"
          />
        </DeleteDialog>
        <IconWrapper
          :icon="isReadOnly(item) ? 'segment' : 'edit'"
          fill="text-title-color1"
          :type="isReadOnly(item) ? 'filled' : null"
          class="cursor-pointer opacity-80 hover:opacity-100"
          @click="editInstance(item.id)"
        />
      </div>
    </template>
  </VuetifyDataTable>
</template>

<script setup>
import CheckGreenIcon from '@/assets/svg/check-green.svg';
import ProgressIcon from '@/assets/svg/progress-icon.svg';
import VuetifyDataTable from '@/components/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import DeleteDialog from '@/components/deleteDialog/DeleteDialog.vue';
import { computed } from 'vue';

const emit = defineEmits(['edit-instance', 'update-instances', 'abort']);
const props = defineProps({
  instances: {
    type: Array,
    default: () => [],
  },
  url: {
    type: String,
    required: true,
  },
  identifier: {
    type: String,
    default: 'id',
  },
  isReadOnly: {
    type: Function,
    default: () => false,
  },
  isReleased: {
    type: Function,
    default: () => true,
  },
  enableDelete: {
    type: Function,
    default: () => false,
  },
  enableRelease: {
    type: Boolean,
    default: false,
  },
  tableHeaders: {
    type: Array,
    default: () => [
      {
        title: 'Name',
        key: 'title',
        width: '150px',
      },
      {
        title: 'Erstellt am',
        key: 'created_at',
        width: '125px',
      },
      {
        title: 'Aktionen',
        key: 'functions',
        align: 'end',
        sortable: false,
        width: '125px',
      },
    ],
  },
});

const sortedInstances = computed(() => {
  const instances = props.instances;

  return instances.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });
});

function editInstance(editId) {
  emit('edit-instance', editId);
}
</script>
