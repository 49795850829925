export class ExternalProjectDto {
  static BASE_URL = '/api/usecase-2/external-project/';
  static NAMES_URL = '/api/usecase-2/external-project/names/';

  id = null;
  name = null;
  project_developer = null;
  type = 'PV';
  power_kw = null;
  power_connection_kw = null;
  geometry = null;
}
